<template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 345px; padding-top: 72px;` : `padding-bottom: 610px; padding-top: 64px;`">
    <v-dialog
      v-model="dialog.video"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="pa-0 rounded-0" style="background-color: rgb(33, 33, 33); border-color: rgb(33, 33, 33);">
        <v-toolbar
          style="background-color: transparent; box-shadow: none"
        >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="selected.video = null; dialog.video = false"
            >
              <v-icon size="40">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div v-if="selected.video !== null" class="text-center" style="padding-top: calc(100vh - 96vh);">
          <iframe
            :src="`https://www.youtube.com/embed/${selected.video.id.videoId}`"
            frameborder="0" allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? `height: 300px; width: 90%;` : `height: calc(100vh - 200px); width: calc(100vw - 200px);`">
          </iframe>
          <!-- ?autoplay=true -->
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.survey"
      width="700"
      persistent
      scrollable>
      <v-card 
        :class="dialog.fullscreen ? 'non-border-radius' : 'border-radius'">
        <v-card-title 
          class="d-flex align-center pa-4 text-right popup-header">
            Pilih Survey

          <v-spacer/>

          <!-- <v-icon 
            @click="dialog.fullscreen = !dialog.fullscreen ? true : false"
            class="cursor-pointer mr-1"
            size="24"
            :color="set_color">
            {{ !dialog.fullscreen ? 'mdi-fullscreen' : 'mdi-fullscreen-exit' }}
          </v-icon> -->

          <v-icon 
            @click="dialog.survey = false;"
            class="cursor-pointer"
            size="24"
            color="red">
            mdi-close-circle-outline
          </v-icon>
        </v-card-title>
        
        <v-divider 
          class="mb-2"
          style="border-top: 2px dashed #e0e0e0;"/>

        <v-card-text 
          class="body-1 font-weight-light text-first line-text-second mt-4">
          <v-row>
            <v-col cols="12" md="6">
              <v-card style="border-radius: 15px;" class="pa-4 text-center" @click="reset(); dialog.review = true; dialog.survey = false;">
                <v-img
                  :src="require('@/assets/icon/mobile.png')"
                  width="200"
                  class="mx-auto"
                  contain>
                </v-img>
                <div class="text-center mt-2">
                  Kepuasan Website
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card style="border-radius: 15px;" class="pa-4 text-center" @click="dialog.survey = false;" :href="`https://skm.klaten.go.id/data`" target="_blank">
                <v-img
                  :src="require('@/assets/icon/enterprise.png')"
                  width="200"
                  class="mx-auto"
                  contain>
                </v-img>
                <div class="text-center mt-2">
                  Kepuasan Instansi Bagian Hukum
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      scrollable
      v-if="dialog.review"
      v-model="dialog.review"
      width="800">
      <v-card class="box-shadow" style="border-radius: 6px;">
        <div class="d-flex align-center body-2 px-5 py-5">
          <div style="font-size: 18px; line-height: 21px; color: #505050; mix-blend-mode: normal; opacity: 0.7; font-weight: 900;">
            <span>Buat/Tambah</span>
             Ulasan
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="dialog.review = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <v-card-text class="pb-5 pt-0" style="min-height: 200px;">
          <div class="mt-0">
            <!-- <div class="fs-16 text-center mb-1">
              <span>Buat</span>
              Ulasan Anda untuk JDIH Setwan Kabupaten Klaten
            </div> -->
            <ValidationObserver 
              ref="form">
              <v-form>
                <ValidationProvider 
                  name="Umur" 
                  rules="required" 
                  v-slot="{ errors, valid }">
                  <div class="body-2 mb-2">
                    <span class="red--text">*</span>

                    Umur
                  </div>

                  <v-text-field
                    v-on:keyup.enter="save()"
                    
                    filled
                    rounded
                    dense

                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    type="number"
                    min="0"

                    :color="set_color"
                    placeholder="Contoh. 20"

                    hide-details

                    v-model="form.age"
                    :success="valid"
                    :error-messages="errors"
                    required
                    class="mb-6"
                    style="border-radius: 10px;">
                    <template v-slot:append-outer>
                      <div v-if="errors.length > 0">
                        <v-tooltip top color="#ff5252">
                          <template v-slot:activator="{ on }">
                            <v-icon color="error" v-on="on">mdi-alert-circle-outline</v-icon>
                          </template>
                          
                          {{ errors[0] }}
                        </v-tooltip>
                      </div>
                      
                      <div v-if="form.age !== '' && valid">
                        <v-icon color="success">mdi-check-circle-outline</v-icon>
                      </div>
                    </template>
                  </v-text-field>
                </ValidationProvider>
                <div class="body-2 mb-3">
                  <span class="red--text">*</span>
                  
                  Jenis Kelamin
                </div>
                
                <ValidationProvider 
                  name="Jenis Kelamin" 
                  rules="required" 
                  v-slot="{ errors }">
                
                  <v-radio-group
                    v-model="form.gender"
                    class="mt-0"
                    hide-details
                    :error-messages="errors" 
                    column
                    >
                    <v-radio
                    v-for="(item, index) in gender" :key="index" :label="item" :value="item"
                      :color="set_color"
                    ></v-radio>
                    
                    <div class="pb-3 caption">
                      {{ errors[0] }}
                    </div>
                  </v-radio-group>
                </ValidationProvider>

                <ValidationProvider 
                  name="Pendidikan" 
                  rules="required" 
                  v-slot="{ errors }">
                  <div class="body-2 mb-0">
                    <span class="red--text">*</span>

                    Pendidikan
                  </div>

                  <v-radio-group
                    v-model="form.pendidikan"
                    class="mt-0"
                    hide-details
                    :error-messages="errors" 
                    column>
                    <v-row>
                      <v-col>
                        <v-radio
                          label="SD/MI"
                          value="SD/MI"
                          :color="set_color"
                        ></v-radio>
                        <v-radio
                          label="SMP/MTs"
                          value="SMP/MTs"
                          :color="set_color"
                        ></v-radio>
                        <v-radio
                          label="SMA/MA"
                          value="SMA/MA"
                          :color="set_color"
                        ></v-radio>
                      </v-col>
                      <v-col>
                        <v-radio
                          label="D1"
                          value="D1"
                          :color="set_color"
                        ></v-radio>
                        <v-radio
                          label="D2"
                          value="D2"
                          :color="set_color"
                        ></v-radio>
                        <v-radio
                          label="D3"
                          value="D3"
                          :color="set_color"
                        ></v-radio>
                      </v-col>
                      <v-col>
                        <v-radio
                          label="D4"
                          value="D4"
                          :color="set_color"
                        ></v-radio>
                        <v-radio
                          label="S1"
                          value="S1"
                          :color="set_color"
                        ></v-radio>
                        <v-radio
                          label="S2 Ke atas"
                          value="S2 Ke atas"
                          :color="set_color"
                        ></v-radio>
                      </v-col>
                    </v-row>
                    <div class="pb-3 caption">
                      {{ errors[0] }}
                    </div>
                  </v-radio-group>
                </ValidationProvider>


                <ValidationProvider 
                  name="Pekerjaan" 
                  rules="required" 
                  v-slot="{ errors }">
                  <div class="body-2 mb-0">
                    <span class="red--text">*</span>

                    Pekerjaan
                  </div>

                  <v-radio-group
                    v-model="form.job"
                    class="mt-0"
                    hide-details
                    :error-messages="errors" 
                    column>
                    <v-row>
                      <v-col>
                        <v-radio
                          label="PNS/TNI/POLRI"
                          value="PNS/TNI/POLRI"
                          :color="set_color"
                        ></v-radio>
                        <v-radio
                          label="Pegawai Swasta"
                          value="Pegawai Swasta"
                          :color="set_color"
                        ></v-radio>
                      </v-col>
                      <v-col>
                        <v-radio
                          label="Wiraswasta"
                          value="Wiraswasta"
                          :color="set_color"
                        ></v-radio>
                        <v-radio
                          label="Pelajar/Mahasiswa"
                          value="Pelajar/Mahasiswa"
                          :color="set_color"
                        ></v-radio>
                      </v-col>
                      <v-col>
                        <v-radio
                          label="Lainnya"
                          value="Lainnya"
                          :color="set_color"
                        ></v-radio>
                      </v-col>
                    </v-row>
                    <div class="pb-3 caption">
                      {{ errors[0] }}
                    </div>
                  </v-radio-group>
                </ValidationProvider>

                

                <div class="mb-5">
                  <div class="body-1">
                    <span class="red--text">*</span>
                    Apakah tampilan website JDIH Setwan Kabupaten Klaten menarik?
                  </div>
                  <ValidationProvider 
                    name="Pertanyaan Tampilan Website" 
                    rules="required" 
                    v-slot="{ errors }">
                    <v-radio-group
                      v-model="form.tampilan"
                      class="mt-0"
                      hide-details
                      :error-messages="errors" 
                      column
                      >
                      <v-radio
                        label="Tidak Menarik"
                        value="1"
                        :color="set_color"
                      ></v-radio>

                      <v-radio
                        label="Kurang Menarik"
                        value="2"
                        :color="set_color"
                      ></v-radio>

                      <v-radio
                        label="cukup Menarik"
                        value="3"
                        :color="set_color"
                      ></v-radio>

                      <v-radio
                        label="Menarik"
                        value="4"
                        :color="set_color"
                      ></v-radio>

                      <v-radio
                        label="sangat Menarik"
                        value="5"
                        :color="set_color"
                      ></v-radio>
                      
                    </v-radio-group>
                    <div class="pb-3 caption red--text">
                      {{ errors[0] }}
                    </div>
                  </ValidationProvider>

                  <div class="mt-3">
                    <div class="body-1">
                      <span class="red--text">*</span>
                      Apakah konten atau isi website JDIH Setwan Kabupaten Klaten mudah dimengerti?
                    </div>
                    <ValidationProvider 
                      name="Pertanyaan Konten atau Isi Website" 
                      rules="required" 
                      v-slot="{ errors }">
                      <v-radio-group
                        v-model="form.content"
                        class="mt-0"
                        hide-details
                        :error-messages="errors" 
                        column
                        >
                        <v-radio
                          label="Tidak Mudah"
                          value="1"
                          :color="set_color"
                        ></v-radio>

                        <v-radio
                          label="Kurang Mudah"
                          value="2"
                          :color="set_color"
                        ></v-radio>

                        <v-radio
                          label="cukup Mudah"
                          value="3"
                          :color="set_color"
                        ></v-radio>

                        <v-radio
                          label="Mudah"
                          value="4"
                          :color="set_color"
                        ></v-radio>

                        <v-radio
                          label="sangat Mudah"
                          value="5"
                          :color="set_color"
                        ></v-radio>
                        
                      </v-radio-group>
                      <div class="pb-3 caption red--text">
                        {{ errors[0] }}
                      </div>
                    </ValidationProvider>
                  </div>

                  <div class="mt-3">
                    <div class="body-1">
                      <span class="red--text">*</span>
                      Apakah website JDIH Setwan Kabupaten Klaten mudah untuk diakses?
                    </div>
                    <ValidationProvider 
                      name="Pertanyaan Akses Website" 
                      rules="required" 
                      v-slot="{ errors }">
                      <v-radio-group
                        v-model="form.kemudahan"
                        class="mt-0"
                        hide-details
                        :error-messages="errors" 
                        column
                        >
                        <v-radio
                          label="Tidak Mudah"
                          value="1"
                          :color="set_color"
                        ></v-radio>

                        <v-radio
                          label="Kurang Mudah"
                          value="2"
                          :color="set_color"
                        ></v-radio>

                        <v-radio
                          label="cukup Mudah"
                          value="3"
                          :color="set_color"
                        ></v-radio>

                        <v-radio
                          label="Mudah"
                          value="4"
                          :color="set_color"
                        ></v-radio>

                        <v-radio
                          label="sangat Mudah"
                          value="5"
                          :color="set_color"
                        ></v-radio>
                        
                      </v-radio-group>
                      <div class="pb-3 caption red--text">
                        {{ errors[0] }}
                      </div>
                    </ValidationProvider>
                  </div>

                  <div class="mt-3">
                    <div class="body-1">
                      <span class="red--text">*</span>
                      Rating Secara Keseluruhan Website
                    </div>
                    <ValidationProvider 
                      name="Rating Website" 
                      rules="required" 
                      v-slot="{ errors }">
                      <v-rating
                        v-model="form.rating"
                        hide-details
                        :error-messages="errors" 
                        :background-color="set_color"
                        :color="set_color"
                        size="64"
                      ></v-rating>
                      <div class="pb-3 caption red--text">
                          {{ errors[0] }}
                        </div>
                    </ValidationProvider>
                  </div>
                </div>

                <!-- <ValidationProvider 
                  name="Saran" 
                  rules="required"
                  v-slot="{ errors, valid }"> -->
                  <div class="body-2 mb-2">
                    <!-- <span class="red--text">*</span> -->
                      Saran / Masukkan
                    <!-- <span :class="item.required ? 'error--text' : ''">
                      ({{ item.required ? 'wajib diisi' : 'boleh dikosongi' }})
                    </span> -->
                  </div>

                  <v-textarea
                    vv-uppercase
                    v-on:keyup.enter="save()"
                    
                    filled
                    rounded
                    dense

                    :color="set_color"
                    placeholder="Tulis Disini"

                    hide-details

                    v-model="form.saran"
                    class="mb-6"
                    style="border-radius: 10px;">
                    <!-- <template v-slot:append-outer>
                      <div v-if="errors.length > 0">
                        <v-tooltip top color="#ff5252">
                          <template v-slot:activator="{ on }">
                            <v-icon color="error" v-on="on">mdi-alert-circle-outline</v-icon>
                          </template>
                          
                          {{ errors[0] }}
                        </v-tooltip>
                      </div>

                      <div v-if="form.saran !== '' && valid">
                        <v-icon color="success">mdi-check-circle-outline</v-icon>
                      </div>
                    </template> -->
                  </v-textarea>
                <!-- </ValidationProvider> -->
            <!-- <v-radio-group v-model="form.id">
              <v-radio
                v-for="(item, index) in list.rating"
                :key="index"
                :color="set_color"
                :label="item.label"
                :value="item.id"
              ></v-radio>
            </v-radio-group> -->
              </v-form>
            </ValidationObserver>

            <div v-if="message.error.length > 0"
              class="text-center mt-6">
              <div
                class="error--text d-flex align-start">
                <v-icon size="18" color="red" style="margin-top: 3px;">mdi-alert-circle-outline</v-icon>
                
                <span
                  class="ml-1">
                  {{ message.error }}
                </span>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-divider 
              class="mt-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-actions
              class="pa-4">
              <v-spacer/>

              <v-btn
                @click="save()"
                :loading="process.form"
                :disabled="process.form"
                width="120"
                elevation="0"
                color="green"
                rounded
                class="body-2 font-weight-bold text-capitalize white--text">
                Simpan
              </v-btn>

              <v-btn
                @click="dialog.review = false; reset();"
                :disabled="process.form"
                width="120"
                outlined
                elevation="0"
                color="red"
                rounded
                class="body-2 font-weight-bold text-capitalize white--text">
                Batal
              </v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.success"
      width="350">
      <v-card 
        class="border-radius pa-2">
        <v-card-text>
          <v-img 
            width="125"
            height="125"
            alt="JDIH Setwan Kabupaten Klaten" 
            :src="require('@/assets/icon/info_berhasil.png')"
            class="ma-auto my-6">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  size="20"
                  indeterminate 
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <div
            class="body-1 text-center line-text-second">
            <div>
              Terima kasih, pengisian survey Anda
            </div>

            <div>
              telah berhasil ditambahkan!
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-layout wrap>
      <v-flex v-if="process.load" style="width: 100vw;">
        <section>
          <!-- <v-img
            width="100%"
            height="100%"
            min-height="100vh"
            alt="jdih"
            :src="require('@/assets/background/bg_basic.png')"> -->
            <v-card
              flat
              height="100%"
              :min-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '60vh' : '40vh'"
              :max-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '60vh' : '40vh'"
              color="#00000040">
              <v-skeleton-loader
                v-if="process.load"
                :loading="process.load"
                transition="scale-transition"
                :tile="false"
                class="ma-auto load_card_x_large"
                type="image"
                style="width: 100% !important; height: 100% !important; min-height: 100% !important;">
              </v-skeleton-loader>
            </v-card>
          <!-- </v-img> -->
        </section>
      </v-flex>
      <v-flex v-if="!process.load && list.banner.length > 0" style="width: 100vw;">
        <section>
          
          <v-carousel
            v-model="selected.banner" 
            hide-delimiters 
            cycle
            :show-arrows="true" 
            :show-arrows-on-hover="true"
            width="100%"
            :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '60vh' : '40vh'">
            <v-carousel-item
              v-for="(item, index) in process.load ? 4 : list.banner" 
              :key="index">
              <v-img
                width="100%"
                v-if="item.mode === 'Image'"
                height="100%"
                :max-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '60vh' : '40vh'"
                alt="JDIH Setwan Kabupaten Klaten"
                :src="item.image_url"
                :lazy-src="item.image_url">
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </v-img>
              <v-card
                width="100%"
                :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '60vh' : '40vh'"
                class="rounded-0 elevation-0"
                :style="`background-color: ${set_color}`"
                v-if="item.mode !== 'Image'">
                <v-container style="height: 100%;" class="px-10">

                  <v-row 
                    justify="center" 
                    style="height: 100%;"
                    align="center">
                    <v-col
                      cols="12"
                      md="5"
                      class="text-center"
                      style="height: 100%;"
                      v-if="(item.mode === 'ImageText' || item.mode === 'ImageTextLink') && $vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                      <img
                        width="450"
                        height="100%"
                        class="mx-auto"
                        alt="JDIH Setwan Kabupaten Klaten"
                        :src="item.image_url">
                        <!-- <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row> -->
                      <!-- </img> -->
                    </v-col>
                    <v-col
                      cols="12"
                      md="7">

                      <div 
                        class="white--text mb-4"
                        :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-20' : 'body-2'"
                        style="font-weight: 500;">
                        {{ item.title }}
                      </div>

                      <div 
                        class="white--text" 
                        :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'subtitle-1' : 'caption'"
                        style="font-weight: 500; opacity: 80%;">
                        <span v-html="item.desc"></span>
                      </div>

                      <div v-if="item.link_cta !== ''">
                        <v-btn
                          :href="item.link_cta"
                          target="_blank"
                          :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '300' : ''"
                          rounded
                          outlined
                          :medium="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? true : false"
                          :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                          color="#FF4949"
                          class="text-subtitle-2 text-capitalize font-weight-bold white--text mt-5">
                          
                          Selengkapnya
                          <v-icon size="20" class="mr-2">mdi-arrow-right</v-icon>
                        </v-btn>
                      </div>

                    </v-col>

                    <v-col
                      cols="12"
                      md="5"
                      class="text-center"
                      style="height: 100%;"
                      v-if="(item.mode === 'TextImage' || item.mode === 'TextImageLink') && $vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                      <!-- <v-img
                        width="475"
                        height="100%"
                        class="mx-auto"
                        alt="JDIH Setwan Kabupaten Klaten"
                        :src="item.image_url"
                        :lazy-src="item.image_url">
                      </v-img> -->
                      <img
                        width="450"
                        height="100%"
                        class="mx-auto"
                        alt="JDIH Setwan Kabupaten Klaten"
                        :src="item.image_url">
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </section>
      </v-flex>

      <v-flex>
        <div class="mt-4" v-if="!process.load">
          <v-container>
            <section>
              <v-card style="border-radius: 6px;" class="box-shadow">
                  <v-card-text>
                    <div class="text-center text-h6 mb-4 black--text">
                      Pencarian Produk Hukum
                      <!-- <div class="body-2">
                        Daftar kelompok Bidang sesuai keterkaitan dengan produk hukum
                      </div> -->
                    </div>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          solo
                          hide-details
                          clearable
                          v-model="filter.search"
                          v-on:keyup.enter="changeLinkPage()"
                          style="border-radius: 6px 0px 0px 6px !important;"
                          :color="`${set_color}`"
                          :placeholder="`Cari peraturan yang Anda butuhkan . . .`">
                          <template v-slot:prepend-inner>
                            <v-icon class="mr-2">mdi-magnify</v-icon>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col>
  
                        <v-autocomplete
                          :items="category"
                          :color="`${set_color}`"
                          :item-color="`${set_color}`"
                          hide-details
                          class="mb-4"
                          clearable
                          v-model="filter.jenis_bidang"
                          item-text="name"
                          item-value="slug"
                          placeholder="Kategori / Jenis Dokumen"
                          solo
                        ></v-autocomplete>
                      </v-col>
  
                      <v-col>
                        <v-autocomplete
                          :items="list.category"
                          :color="`${set_color}`"
                          :item-color="`${set_color}`"
                          hide-details
                          class="mb-4"
                          clearable
                          v-model="filter.category_bidang"
                          item-text="title"
                          item-value="slug"
                          placeholder="Bidang"
                          solo
                        ></v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field
                          solo
                          hide-details
                          clearable
                          class="mb-4"
                          v-model="filter.nomor"
                          @click:clear="filter.nomor = '';"
                          :color="`${set_color}`"
                          :placeholder="`Nomor`">
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-autocomplete
                          :items="years"
                          :color="`${set_color}`"
                          :item-color="`${set_color}`"
                          hide-details
                          v-model="filter.tahun"
                          clearable
                          placeholder="Tahun"
                          solo
                        ></v-autocomplete>
                      </v-col>
                      <v-col>
  
                        <v-text-field
                          solo
                          hide-details
                          clearable
                          v-model="filter.title"
                          :color="`${set_color}`"
                          :placeholder="`Nama`">
                        </v-text-field>
                      </v-col>
  
                      <v-col cols="12" class="text-center justify-center">
                        <v-btn
                          style="border-radius: 6px;"
                          elevation="0"
                          @click="changeLinkPage()"
                          @mouseover="voiceHover('Cari')"
                          height="48"
                          width="200"
                          :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                          :color="`${set_color}`"
                          class="text-subtitle-2 text-capitalize font-weight-bold white--text">
                          
                          <v-icon size="20" class="mr-2">mdi-magnify</v-icon>
                          Cari
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
            </section>
          </v-container>
        </div>

        <!-- <v-divider class="mt-5"></v-divider> -->

        <div class="pb-0">
          <v-container id="section-2">
            <section class="mb-6 mt-6">
              <div class="text-center text-h6 mb-4">
                Kelompok Bidang
                <div class="body-2">
                  Daftar kelompok Bidang sesuai keterkaitan dengan produk hukum
                </div>
              </div>

              <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                <v-row>
                  <v-col cols="6" md="2" sm="4"
                    v-for="(item, index) in process.load ? 6 : list.category" 
                    :key="index">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :to="`/page?jenis=semua-kategori&category=${item.slug}`"
                        width="100%"
                        class="rounded-xl box-shadow py-5"
                        :class="hover ? 'hover-card-news on-hover' : 'box-shadow'"
                        :style="hover ? `box-shadow: 0px 5px 15px -5px ${set_color} !important;` : ''"
                      >
                        <v-card-title class="justify-center pb-1 px-1">
                          <v-avatar size="65">
                            <v-img
                              width="65px"
                              alt="JDIH Setwan Kabupaten Klaten"
                              :src="item.icon_url === '' ? require('@/assets/image/no-photo.png') : item.icon_url">
                            </v-img>
                          </v-avatar>
                        </v-card-title>
                        <v-card-text class="text-center py-0 pt-0 pb-2 caption px-1" :class="hover ? 'font-weight-bold' : ''" :style="hover ? `color: ${set_color}` : ''">
                          {{ item.title }}
                        </v-card-text>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </div>

              <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
                <div class="mt-3">
                  <no-ssr>
                    <swiper 
                      class="swiper"
                      ref="swiper"
                      :options="swiperOption">
                      <swiper-slide 
                        v-for="(item, index) in process.load ? 5 : list.category" :key="index" 
                        class="py-3" 
                        :class="index < 1 ? 'px-4' : index < list.category.length ? 'pr-4' : 'px-4'"
                        style="width: auto;">
                        <v-skeleton-loader
                          class="ma-auto"
                          width="175"
                          :loading="process.load"
                          type="card, article">
                          <div>
                            <v-card v-if="!process.load && list.category.length > 0"
                              :to="`/page?jenis=semua-kategori&category=${item.slug}`"
                              class="box-shadow"
                              style="border-radius: 6px;"
                              width="140">
                              <v-card-title class="justify-center pb-1 px-1">
                                <v-avatar size="65">
                                  <v-img
                                    width="65px"
                                    alt="JDIH Setwan Kabupaten Klaten"
                                    :src="item.icon_url === '' ? require('@/assets/image/no-photo.png') : item.icon_url">
                                  </v-img>
                                </v-avatar>
                              </v-card-title>
                              <v-card-text class="text-center py-0 pt-0 pb-2 caption px-1" :class="hover ? 'font-weight-bold' : ''" :style="hover ? `color: ${set_color}` : ''">
                                {{ item.title }}
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-skeleton-loader>
                      </swiper-slide>
                    </swiper>
                  </no-ssr>
                </div>
              </div>
            </section>
          </v-container>
          
        </div>
      </v-flex>

      <v-container class="pt-0">
        <section class="mb-5">
          <v-row>
            <v-col cols="6" md="6" class="pb-0">
              <div class="font-weight-medium" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                <span :style="`border-bottom: 3px solid ${set_color}`">Peraturan Terbaru</span>
              </div>
            </v-col>
            <v-col cols="6" md="6" class="pb-0">
              <div class="text-right">
                <v-btn
                  text
                  :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                  :to="'/page?jenis=semua-kategori'"
                  :style="`color: ${set_color}`"
                  class="text-capitalize button-link-transparent">
                    Lihat Semua
                  <v-icon size="15">mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-card-subtitle :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1' : 'caption pb-0'" class="px-0 pt-1">
            Daftar Peraturan yang baru saja disahkan
          </v-card-subtitle>

          <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
            <v-row>
              <v-col cols="12" md="3" sm="6" v-for="(item, index) in process.load ? 4 : list.doc" 
                :key="index">
                <v-skeleton-loader
                  :loading="process.load"
                  transition="scale-transition"
                  :tile="false"
                  class="ma-auto"
                  type="iamge">
                  <div>
                    <div v-if="!process.load && list.doc.length > 0"
                      class="border-radius">
                      <v-hover v-slot="{ hover }">
                        <v-card
                          class="mx-auto my-2 button-link-transparent"
                          style="border-radius: 15px;"
                          @mouseover="voiceHover(item.title)"
                          @click="toDetail(item)"
                          :class="hover ? 'hover-card-news on-hover' : 'box-shadow'"
                          :style="hover ? `box-shadow: 0px 5px 15px -5px ${set_color} !important;` : ''">
                          <v-card-title class="justify-center pb-1 px-1">
                            <v-avatar size="60" tile>
                              <v-img
                                width="60px"
                                alt="JDIH Setwan Kabupaten Klaten"
                                :src="require('@/assets/icon/files.png')">
                              </v-img>
                            </v-avatar>
                          </v-card-title>
                          <v-card-text class="text-center pt-3 pb-1 body-1 font-weight-medium" :style="hover ? `color: ${set_color}` : ''">
                            <div class="body-2 font-weight-bold four-line text-center mb-2" style="min-height: 80px; height: 80px;">

                              {{ item.title }}
                            </div>

                          </v-card-text>
                          <div class="text-center pb-3">
                            <v-chip
                              class="ma-2"
                              text-color="white"
                              small
                              :color="item.status_option === 'Berlaku' ? '#66DA26' : item.status_option === 'Tidak Berlaku' ? '#E91E63' : item.status_option === 'Diubah' ? '#2E93fA' : '#FFEB3B'"
                              label
                            >
                              {{ item.status_option }}
                            </v-chip>
                          </div>
          
                          <v-divider class="mx-4 mb-4"></v-divider>
          
                          <v-card-text class="text-center pt-0 px-2 caption">
                            <v-row>
                              <v-col cols="12" md="6" sm="6" class="pa-1">
                                <v-icon
                                  size="12">
                                  mdi-account-circle-outline
                                </v-icon>
                                <span class="me-1 font-small">{{ item.doc_type_title }}</span>
                              </v-col>
                              <v-col cols="12" md="6" sm="6" class="text-right pa-1">
                                <v-icon
                                  size="12">
                                  mdi-calendar-range
                                </v-icon>
                                <span class="me-1 font-small"> {{ item.penetapan_date | date }} </span>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-hover>
                    </div>
                  </div>
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </div>

          <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
            <div class="mt-3">
              <no-ssr>
                <swiper 
                  class="swiper"
                  ref="swiper"
                  :options="swiperOption">
                  <swiper-slide 
                    v-for="(item, index) in process.load ? 5 : list.doc" :key="index" 
                    class="py-3" 
                    :class="index < 1 ? 'px-4' : index < list.doc.length ? 'pr-4' : 'px-4'"
                    style="width: auto;">
                    <v-skeleton-loader
                      class="ma-auto"
                      width="175"
                      :loading="process.load"
                      type="card, article">
                      <div>
                        <v-card v-if="!process.load && list.doc.length > 0"
                          @click="toDetail(item)"
                          @mouseover="voiceHover(item.title)"
                          style="border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;"
                          width="175">
                          <v-img
                            width="100"
                            contain
                            height="80"
                            alt="JDIH Setwan Kabupaten Klaten" 
                            :src="require('@/assets/icon/files.png')"
                            class="ma-auto">
                            <!-- <v-card flat
                              color="transparent"
                              class="d-flex align-center pa-3"
                              style="border-radius: 50px;">
                              <v-avatar size="20">
                                <v-img 
                                  alt="JDIH Setwan Kabupaten Klaten" 
                                  :src="item.post_extra.createdby.image"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        :color="set_color"
                                        size="15">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>

                              <div 
                                class="caption text-capitalize font-weight-bold one-line white--text pl-2">
                                {{ item.post_extra.createdby.name }}
                              </div>
                            </v-card> -->

                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <div class="pa-3">
                            <div class="body-2 font-weight-bold three-line text-center" style="height: 62px; min-height: 62px;">
                              {{ item.title }}
                            </div>

                            <div class="d-flex align-center mt-1">
                              <v-icon small color="#212121">mdi-file-document-outline</v-icon>

                              <span class="caption one-line ml-1">
                                {{ item.doc_type_title }}
                              </span>

                            </div>
                            <div class="text-center pb-3">
                              <v-chip
                                class="ma-2"
                                text-color="white"
                                small
                                :color="item.status_option === 'Berlaku' ? '#66DA26' : item.status_option === 'Tidak Berlaku' ? '#E91E63' : item.status_option === 'Diubah' ? '#2E93fA' : '#FFEB3B'"
                                label
                              >
                                {{ item.status_option }}
                              </v-chip>
                            </div>

                            <!-- <div class="">
                              <v-chip small class="ma-1 ml-0" style="font-size: 8px;" v-for="(tag, i) in item.tag" :key="i">{{ tag }}</v-chip>
                            </div> -->

                            <v-divider class="my-2" style="border: 2px solid #f9f9f9;" />

                            <div class="caption d-flex align-center" style="opacity: 60%;">
                              <div>
                                {{ item.penetapan_date | date }}
                              </div>

                              <!-- <v-spacer />

                              <div>
                                {{ item.post_create_datetime | time }}
                              </div> -->
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </v-skeleton-loader>
                  </swiper-slide>
                </swiper>
              </no-ssr>
            </div>
          </div>

        </section>
      </v-container>

      <v-container class="pt-0">
        <section class="mb-5">
          <v-row>
            <v-col cols="6" md="6" class="pb-0">
              <div class="font-weight-medium" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                <span :style="`border-bottom: 3px solid ${set_color}`">Berita</span>
              </div>
            </v-col>
            <v-col cols="6" md="6" class="pb-0">
              <div class="text-right">
                <v-btn
                  text
                  :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                  :to="'/berita'"
                  :style="`color: ${set_color}`"
                  class="text-capitalize button-link-transparent">
                    Lihat Semua
                  <v-icon size="15">mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <v-card-subtitle :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1' : 'caption pb-0'" class="px-0 pt-1">
            Berita - berita yang terdapat di kabupaten klaten
          </v-card-subtitle>

          <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
            <v-row>
              <v-col cols="12" md="3" sm="6" v-for="(item, index) in process.load ? 4 : list.news" 
                :key="index">
                <v-skeleton-loader
                  :loading="process.load"
                  transition="scale-transition"
                  :tile="false"
                  class="ma-auto"
                  type="card">
                  <div>
                    <div v-if="!process.load && list.news.length > 0"
                      class="border-radius">
                      <v-hover v-slot="{ hover }">
                        <v-card v-if="!process.load && list.news.length > 0"
                          @click="toSelectedDetail(item)"
                          @mouseover="voiceHover(item.title)"
                          :class="hover ? 'hover-card-news on-hover' : 'box-shadow'"
                          style="border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;"
                          width="100%">
                          <v-img
                            width="100%"
                            height="250"
                            contain
                            alt="JDIH Setwan Kabupaten Klaten" 
                            :src="item.image_url_json.length > 0 ? item.image_url_json[0] : item.image_url === '' ? require('@/assets/image/no-photo.png') : item.image_url"
                            class="ma-auto">
                            <!-- <v-card flat
                              color="transparent"
                              class="d-flex align-center pa-3"
                              style="border-radius: 50px;">
                              <v-avatar size="20">
                                <v-img 
                                  alt="JDIH Setwan Kabupaten Klaten" 
                                  :src="item.post_extra.createdby.image"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        :color="set_color"
                                        size="15">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>

                              <div 
                                class="caption text-capitalize font-weight-bold one-line white--text pl-2">
                                {{ item.post_extra.createdby.name }}
                              </div>
                            </v-card> -->

                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <div class="pa-3">
                            <div class="body-2 font-weight-bold one-line">
                              {{ item.title }}
                            </div>

                            <div class="d-flex align-center mt-1">
                              <v-icon small color="#212121">mdi-account-circle-outline</v-icon>

                              <span class="caption one-line ml-1">
                                <!-- {{ item.autor }} -->JDIH Setwan Kabupaten Klaten
                              </span>

                            </div>

                            <div class="caption font-weight-light three-line my-2" style="opacity: 60%; min-height: 60px; height: 60px; max-height: 60px;">
                              <span v-html="item.content.replace(/(<([^>]+)>)/ig, '')"></span>
                            </div>

                            <!-- <div class="">
                              <v-chip small class="ma-1 ml-0" style="font-size: 8px;" v-for="(tag, i) in item.tag" :key="i">{{ tag }}</v-chip>
                            </div> -->

                            <v-divider class="my-2" style="border: 2px solid #f9f9f9;" />

                            <div class="caption d-flex align-center" style="opacity: 60%;">
                              <div>
                                {{ item.publish_datetime | date }}
                              </div>

                              <!-- <v-spacer />

                              <div>
                                {{ item.post_create_datetime | time }}
                              </div> -->
                            </div>
                          </div>
                        </v-card>
                      </v-hover>
                    </div>
                  </div>
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </div>

          <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
            <div class="mt-3">
              <no-ssr>
                <swiper 
                  class="swiper"
                  ref="swiper"
                  :options="swiperOption">
                  <swiper-slide 
                    v-for="(item, index) in process.load ? 5 : list.news" :key="index" 
                    class="py-3" 
                    :class="index < 1 ? 'px-4' : index < list.news.length ? 'pr-4' : 'px-4'"
                    style="width: auto;">
                    <v-skeleton-loader
                      class="ma-auto"
                      width="175"
                      :loading="process.load"
                      type="card, article">
                      <div>
                        <v-card v-if="!process.load && list.news.length > 0"
                          @click="toSelectedDetail(item)"
                          @mouseover="voiceHover(item.title)"
                          style="border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;"
                          width="175">
                          <v-img
                            width="100%"
                            height="130"
                            alt="JDIH Setwan Kabupaten Klaten" 
                            contain
                            :src="item.image_url_json.length > 0 ? item.image_url_json[0] : item.image_url === '' ? require('@/assets/image/no-photo.png') : item.image_url"
                            class="ma-auto">
                            <!-- <v-card flat
                              color="transparent"
                              class="d-flex align-center pa-3"
                              style="border-radius: 50px;">
                              <v-avatar size="20">
                                <v-img 
                                  alt="JDIH Setwan Kabupaten Klaten" 
                                  :src="item.post_extra.createdby.image"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        :color="set_color"
                                        size="15">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>

                              <div 
                                class="caption text-capitalize font-weight-bold one-line white--text pl-2">
                                {{ item.post_extra.createdby.name }}
                              </div>
                            </v-card> -->

                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <div class="pa-3">
                            <div class="body-2 font-weight-bold one-line">
                              {{ item.title }}
                            </div>

                            <div class="d-flex align-center mt-1">
                              <v-icon small color="#212121">mdi-account-circle-outline</v-icon>

                              <span class="caption one-line ml-1">
                                <!-- {{ item.autor }} -->JDIH Setwan Kabupaten Klaten
                              </span>

                            </div>

                            <div class="caption font-weight-light two-line my-2" style="opacity: 60%; min-height: 41px; height: 41px; max-height: 41px;">
                              <span v-html="item.content.replace(/(<([^>]+)>)/ig, '')"></span>
                            </div>

                            <!-- <div class="">
                              <v-chip small class="ma-1 ml-0" style="font-size: 8px;" v-for="(tag, i) in item.tag" :key="i">{{ tag }}</v-chip>
                            </div> -->

                            <v-divider class="my-2" style="border: 2px solid #f9f9f9;" />

                            <div class="caption d-flex align-center" style="opacity: 60%;">
                              <div>
                                {{ item.publish_datetime | date }}
                              </div>

                              <!-- <v-spacer />

                              <div>
                                {{ item.post_create_datetime | time }}
                              </div> -->
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </v-skeleton-loader>
                  </swiper-slide>
                </swiper>
              </no-ssr>
            </div>
          </div>

        </section>
      </v-container>

      <!-- <v-container class="pt-0">
        <section class="mb-5">
          <v-row>
            <v-col cols="6" class="pt-0">
              <div class="font-weight-medium" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'title'">
                <span :style="`border-bottom: 3px solid ${set_color}`">Koleksi</span> Buku Digital
              </div>
              <div class="body-2 mt-4">
                Daftar Koleksi Buku Digital JDIH Kabupaten Batang
              </div>
            </v-col>
            <v-col cols="6" class="pt-0">
              <div class="text-right">
                <v-btn
                  text
                  :to="'/buku'"
                  :style="`color: ${set_color}`"
                  class="text-capitalize button-link-transparent">
                    Lihat Semua
                  <v-icon size="15">mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
            <v-row>
              <v-col cols="12" md="3" sm="6" v-for="(item, index) in process.load ? 4 : list.book" 
                :key="index">
                <v-skeleton-loader
                  :loading="process.load"
                  transition="scale-transition"
                  :tile="false"
                  class="ma-auto"
                  type="card">
                  <div>
                    <div v-if="!process.load && list.book.length > 0"
                      class="border-radius">
                      <v-hover v-slot="{ hover }">
                        <v-card
                          class="mx-auto my-2 button-link-transparent"
                          style="border-radius: 15px;"
                          :to="`/buku/${item.title}`"
                          :class="hover ? 'hover-card-news on-hover' : 'box-shadow'">
                          <v-img
                            contain
                            height="350"
                            :src="item.image_url"
                            :lazy-src="item.image_url">
                          </v-img>
              
                          <v-card-item>
                            <v-card-title class="text-subtitle-1 two-line" style="min-height: 88px; height: 88px; align-items: baseline;" :class="hover ? 'px-3' : 'px-3'">{{ item.title }}</v-card-title>
              
                            <v-card-subtitle class="pb-1" :class="hover ? 'px-3' : 'px-3'">
                              
                              <v-row>
                                <v-col cols="12" class="pr-0">
                                  <v-icon
                                    size="16">
                                    mdi-account-circle-outline
                                  </v-icon>
                                  <span class="me-1 caption">{{ item.author }}</span>
                                </v-col>
                              </v-row>
                            </v-card-subtitle>
                          </v-card-item>
                        </v-card>
                      </v-hover>
                    </div>
                  </div>
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </div>

          <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
            <v-carousel hide-delimiters height="100%" class="mt-3">
              <v-carousel-item
                v-for="(item, index) in process.load ? 4 : list.book" 
                :key="index">
                <v-card
                  class="mx-auto button-link-transparent"
                  :href="item.link"
                  target="_blank"
                  height="520"
                  max-width="350"
                  :class="'box-shadow'">
                  <v-img
                    cover
                    height="200"
                    :lazy-src="item.image_url"
                    :src="item.image_url">
                  </v-img>
      
                  <v-card-item>
                    <v-card-title class="text-subtitle-1">{{ item.title }}</v-card-title>
      
                    <v-card-subtitle class="pb-1">
                      <v-row>
                        <v-col cols="12" class="pb-0 pr-0">
                          <v-icon
                            size="12">
                            mdi-account-circle-outline
                          </v-icon>
                          <span class="me-1 font-small">{{ item.author }}</span>
                        </v-col>
                      </v-row>
                    </v-card-subtitle>
                  </v-card-item>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </div>

        </section>
      </v-container> -->

      <v-container class="pt-0">
        <section>
          <v-row>
            <v-col cols="6" md="6" class="pb-0 pt-0">
              <div class="font-weight-medium" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                <span :style="`border-bottom: 3px solid ${set_color}`">Channel</span> Utama
              </div>
            </v-col>
            <v-col cols="6" md="6" class="pb-0 pt-0">
              <div class="text-right">
                <v-btn
                  text
                  :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                  :to="'/video'"
                  :style="`color: ${set_color}`"
                  class="text-capitalize button-link-transparent">
                    Lihat Semua
                  <v-icon size="15">mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <v-card-subtitle :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1' : 'caption pb-0'" class="px-0 pt-1">
            Video - video yang terdapat di kabupaten klaten
          </v-card-subtitle>
          <!-- <carousel-3d :disable3d="true" :space="500" :clickable="false" :controls-visible="true">
            <slide v-for="(slide, i) in 7" :index="i">
              <span class="title">You know</span>
              <p>You know, being a test pilot isn't always the healthiest business in the world.</p>
            </slide>
          </carousel-3d> -->
        </section>
      </v-container>
      <v-container style="max-width: calc(100vw - 350px);" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">

        <v-sheet
          class="mx-auto mt-0"
          elevation="0"
          style="background-color: transparent;">
          <v-slide-group
            class="pa-0"
            prev-icon="mdi-arrow-left-drop-circle-outline"
            next-icon="mdi-arrow-right-drop-circle-outline"
            active-class="success"
            show-arrows>
            <v-slide-item
              v-for="(item, index) in process.video ? 3 : list.video" 
              :key="index">
              <v-skeleton-loader
                :loading="process.video"
                transition="scale-transition"
                :tile="false"
                class="ma-auto"
                width="400"
                type="card">
                <div>
                  <div v-if="!process.video && list.video.length > 0"
                    class="border-radius">
                    <v-card
                      class="mx-4 mb-4 mt-0"
                      elevation="0"
                      @mouseover="voiceHover(item.snippet.channelTitle)"
                      max-width="400"
                    >
                      <v-img
                        class="white--text align-center rounded-xl cursor-pointer"
                        height="250"
                        @click="openVideo(item)"
                        :src="`https://img.youtube.com/vi/${ item.id.videoId }/hqdefault.jpg`"
                      >
                        <!-- <v-card-title>{{ item.title }}</v-card-title> -->
                        <div class="d-flex align-center justify-center font-weight-black">
                        <div class="text-center">
                          <v-icon size="80" color="#FFFFFF">mdi-play-circle</v-icon> 
                          <!-- <div class="white--text text-h5">
                            Preview Kelas
                          </div> -->
                        </div>
                      </div>
                      </v-img>

                      <v-card-text class="text--primary pb-0">
                        <div>{{ item.snippet.channelTitle }}</div>
                      </v-card-text>
                      <v-card-subtitle class="" style="min-height: 90px; height: 90px;">
                        <!-- {{ item.description }} -->
                        <span class="line-text-second" style="white-space: pre-line; min-height: 90px;" v-html="item.snippet.description"> </span>
                      </v-card-subtitle>
                    </v-card>
                  </div>
                </div>
              </v-skeleton-loader>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-container>      

      <v-container v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
      <div>
        <no-ssr>
          <swiper 
            class="swiper"
            ref="swiper"
            :options="swiperOption">
            <swiper-slide 
              v-for="(item, index) in process.video ? 5 : list.video" :key="index" 
              class="py-3" 
              :class="index < 1 ? 'px-4' : index < list.video.length ? 'pr-4' : 'px-4'"
              style="width: auto;">
              <v-skeleton-loader
                class="ma-auto"
                width="200"
                :loading="process.video"
                type="card, article">
                <div>
                  <v-card v-if="!process.video && list.video.length > 0"
                    @click="openVideo(item)"
                    @mouseover="voiceHover(item.snippet.channelTitle)"
                    style="border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;"
                    width="200">
                    <v-img
                      width="100%"
                      height="130"
                      alt="JDIH Setwan Kabupaten Klaten" 
                      :src="`https://img.youtube.com/vi/${ item.id.videoId }/hqdefault.jpg`"
                      class="ma-auto align-center">
                      <div class="d-flex align-center justify-center font-weight-black" style="">
                        <div class="text-center">
                          <v-icon size="30" color="#FFFFFF">mdi-play-circle</v-icon> 
                          <!-- <div class="white--text text-h5">
                            Preview Kelas
                          </div> -->
                        </div>
                      </div>

                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div class="pa-3">
                      <div class="body-2 font-weight-bold one-line">
                        {{ item.snippet.channelTitle }}
                      </div>

                      <div class="caption font-weight-light two-line my-2" style="opacity: 60%;">
                        <span v-html="item.snippet.description.replace(/(<([^>]+)>)/ig, '')"></span>
                      </div>
                    </div>
                  </v-card>
                </div>
              </v-skeleton-loader>
            </swiper-slide>
          </swiper>
        </no-ssr>
      </div>
      </v-container>

      <div style="background: #FFF; width: 100%" class="mt-3 mb-10" v-if="!process.video && list.video.length < 1">
        <v-container>
          <section class="mb-5">
            <div class="text-center text-h6 mb-4">
              <v-img 
                class="mx-auto mb-3"
                contain
                alt="jdih"
                :width="100"
                :height="100"
                :src="require('@/assets/image/empty.png')">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              Video Belum Tersedia
            </div>
          </section>
        </v-container>
      </div>

      <div style="background: #FFF; width: 100%">
        <v-container :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'max-width: calc(100vw - 350px);'" class="">
          <section class="mb-5">
            <div class="text-center text-h6 mb-4">
              Link Terkait
              <div class="body-2">
                Daftar Website Terkait dengan JDIH Setwan Kabupaten Klaten
              </div>
            </div>

            <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
              <v-sheet
                class="mx-auto mt-0"
                elevation="0"
                style="background-color: transparent;">
                <v-slide-group
                  class="pa-0"
                  prev-icon="mdi-arrow-left-drop-circle-outline"
                  next-icon="mdi-arrow-right-drop-circle-outline"
                  active-class="success"
                  show-arrows>
                  <v-slide-item
                    v-for="(item, index) in process.load ? 3 : list.logo" 
                    :key="index">
                    <v-skeleton-loader
                      :loading="process.load"
                      transition="scale-transition"
                      :tile="false"
                      class="ma-auto"
                      width="175"
                      type="card">
                      <div>
                        <div v-if="!process.load && list.logo.length > 0"
                          class="border-radius">
                          <v-card
                            class="mx-4 mb-4 mt-0"
                            elevation="0"
                            max-width="175"
                            :href="item.link"
                            target="_blank"
                          >
                            <v-img
                              class="white--text align-center cursor-pointer"
                              width="175"
                              :src="item.image_url"
                            >
                            </v-img>
                          </v-card>
                        </div>
                      </div>
                    </v-skeleton-loader>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
            </div>

            <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
              <div class="mt-3">
                <no-ssr>
                  <swiper 
                    class="swiper"
                    ref="swiper"
                    :options="swiperOption">
                    <swiper-slide 
                      v-for="(item, index) in process.load ? 5 : list.logo" :key="index" 
                      class="py-3" 
                      :class="index < 1 ? 'px-4' : index < list.logo.length ? 'pr-4' : 'px-4'"
                      style="width: auto;">
                      <v-skeleton-loader
                        class="ma-auto"
                        width="175"
                        :loading="process.load"
                        type="card, article">
                        <div>
                          <v-card v-if="!process.load && list.logo.length > 0"
                            style="border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;"
                            width="175"
                            :href="item.link"
                            target="_blank">
                            <v-img
                              width="100%"
                              height="130"
                              alt="JDIH Setwan Kabupaten Klaten" 
                              contain
                              :src="item.image_url"
                              class="ma-auto">
                              <!-- <v-card flat
                                color="transparent"
                                class="d-flex align-center pa-3"
                                style="border-radius: 50px;">
                                <v-avatar size="20">
                                  <v-img 
                                    alt="JDIH Setwan Kabupaten Klaten" 
                                    :src="item.post_extra.createdby.image"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          indeterminate 
                                          :color="set_color"
                                          size="15">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>

                                <div 
                                  class="caption text-capitalize font-weight-bold one-line white--text pl-2">
                                  {{ item.post_extra.createdby.name }}
                                </div>
                              </v-card> -->

                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-card>
                        </div>
                      </v-skeleton-loader>
                    </swiper-slide>
                  </swiper>
                </no-ssr>
              </div>
            </div>

          </section>
        </v-container>
      </div>

      <v-container>
        <section>
          <v-row>
            <v-col cols="12">
              <v-card flat v-if="!process.load">
                <div class="font-weight-medium white--text pa-3" :style="`background-color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'title'">
                  Grafik JDIH Setwan Kabupaten Klaten
                </div>
                <div class="body-2 mt-4 mb-3">
                  Beberapa grafik statistik berikut menjelaskan beragam data dari dokumentasi dan informasi hukum, baik data status peraturan, jumlah peraturan dan jumlah dokumen hukum yang ada pada website JDIH Kab. Klaten.
                  <v-btn
                    style="border-radius: 6px;"
                    elevation="0"
                    height="38"
                    to="/statistik"
                    :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? true : true"
                    color="blue"
                    class="text-subtitle-2 text-capitalize font-weight-bold white--text mt-2">
                    
                    <v-icon size="15" class="mr-2">mdi-text-box-search-outline</v-icon>
                    Lihat Detail
                  </v-btn>
                </div>
                <div id="chart">
                  <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                </div>
              </v-card>
            </v-col>
            <!-- <v-col cols="12" md="6" sm="6">
              <v-card flat v-if="!process.load">
                <div class="font-weight-medium white--text pa-3 d-flex" :style="`background-color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'title'">
                  <div>
                    Survei Kepuasan
                  </div>
                  <v-spacer></v-spacer>
                  <div>
                    <v-btn
                      style="border-radius: 6px;"
                      elevation="0"
                      @click="dialog.survey = true; form.id = '';"
                      :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                      color="white"
                      :style="`color: ${set_color}`"
                      class="text-subtitle-2 text-capitalize font-weight-bold">
                      Isi Survey
                    </v-btn>
                  </div>
                </div>
                <div class="body-2 mt-4 mb-3">
                  Terima kasih atas penilaian yang telah anda berikan, masukan anda sangat bermanfaat untuk kemajuan unit kami agar terus memperbaiki dan meningkatkan kualitas pelayanan bagi masyarakat
                </div>
                <div id="chart">
                  <apexchart type="pie" height="350" :options="chartOptionsPie" :series="seriesPie"></apexchart>
                </div>
              </v-card>
            </v-col> -->
          </v-row>
        </section>
      </v-container>

      <div :style="`background: ${set_color}; width: 100%`">
        <v-container :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'max-width: calc(100vw - 350px);'" class="">
          <section class="my-7">
            <div class="text-center white--text text-h6 mb-4">
              Survei Kepuasan
              <div class="body-2">
                Terima kasih atas penilaian yang telah anda berikan, masukan anda sangat bermanfaat untuk kemajuan unit kami agar terus memperbaiki dan meningkatkan kualitas pelayanan bagi masyarakat
              </div>
              <div class="mt-3">
                <v-btn
                  style="border-radius: 6px;"
                  elevation="0"
                  @click="dialog.survey = true; form.id = '';"
                  :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                  color="white"
                  :style="`color: ${set_color}`"
                  class="text-subtitle-2 text-capitalize font-weight-bold">
                  Isi Survey
                </v-btn>
              </div>
            </div>
          </section>
        </v-container>
      </div>

    </v-layout> 
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        category: [
          {
            name: "Semua Kategori",
            slug: "semua-kategori",
          }
        ],
        category_bidang: [],
        filter: {
          category_bidang: '',
          title: '',
          tahun: '',
          nomor: '',
          jenis_bidang: '',
          search: '',
        },
        gender: [
          'Laki-laki', 'Perempuan'
        ],
          series: [
            {
              name: 'Berlaku',
              data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
            },
            {
              name: 'Diubah',
              data: [5, 10, 1, 4, 20, 19, 20, 55, 12]
            },
            {
              name: 'Mencabut Sebagian',
              data: [1, 2, 1, 10, 20, 1, 10, 1, 0]
            },
            {
              name: 'Tidak Berlaku',
              data: [0, 0, 8, 0, 15, 0, 10, 5, 2]
            }
          ],
          chartOptions: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            colors: ['#66DA26', '#2E93fA', '#FFEB3B', '#E91E63', '#FF9800', '#9C27B0', '#03A9F4', '#00BCD4', '#FF9800', '#009688', '#3F51B5', '#CDDC39', '#FFC107', '#FF5722'],
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
            },
            yaxis: {
              title: {
                text: '(Produk Hukum)'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val 
                }
              }
            }
          },
          seriesPie: [44, 55, 13, 5],

          chartOptionsPie: {
            chart: {
              width: 380,
              type: 'pie',
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            labels: ['Sangat Puas', 'Puas', 'Cukup Puas', 'Kurang Puas'],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }],
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val
                }
              }
            }
          },
        selected: {
          banner: 0,
          data: 0,
          video: null,
          layanan: null,
          category: null
        },
        // filter: {
        //   category: ''
        // },
        dialog: {
          video: false,
          layanan: false,
          review: false,
          success: false,
          survey: false
        },
        form: {
          rating: '',
          age: '',
          gender: '',
          job: '',
          pendidikan: '',
          tampilan: '',
          content: '',
          kemudahan: '',
          saran: ''
        },
        list: {
          logo: [
            {
              image_url: require('@/assets/image/jdihn.jpeg'),
              link: "https://jdihn.go.id/"
            },
            {
              image_url: require('@/assets/image/jdihjawatengah.png'),
              link: "https://jdih.jatengprov.go.id/"
            },
            {
              image_url: require('@/assets/image/bphn.jpeg'),
              link: "https://www.bphn.go.id/"
            },
            {
              image_url: require('@/assets/image/bagianhukumklaten.jpeg'),
              link: "https://hukum.klaten.go.id/"
            },
            {
              image_url: require('@/assets/image/ppidklaten.jpeg'),
              link: "https://ppid.klaten.go.id/"
            },
            {
              image_url: require('@/assets/image/klatenkab.png'),
              link: "https://klatenkab.go.id/"
            }
          ],
          banner: [],
          category: [
            {
              title:"SAKURA",
              icon:require('@/assets/icon/sakura.png'),
              link:"https://sakura.dukcapil.klaten.go.id/",
              description: "kategori Layanan Masyarakat",
            },
            {
              title:"Pena Mas",
              icon:require('@/assets/icon/pena-mas.png'),
              link:"https://pena-mas.klaten.go.id/",
              description: "kategori Layanan Masyarakat",
            },
            {
              title:"Matur Dokter",
              icon:require('@/assets/icon/matur-dokter.png'),
              link:"https://matur-dokter.klaten.go.id/",
              description: "kategori Layanan Masyarakat",
            },
            {
              title:"MPP",
              icon:require('@/assets/icon/mpp.png'),
              link:"http://mpp.klaten.go.id/",
              description: "kategori Layanan Masyarakat",
            },
            {
              title:"Tiket Pariwisata",
              icon:require('@/assets/icon/tiket.png'),
              link:"",
              description: "kategori Layanan Masyarakat",
            },
            {
              title:"Pertanian",
              icon:require('@/assets/icon/pertanian.png'),
              link:"",
              description: "kategori Layanan Masyarakat",
            },
            {
              title:"PPID",
              icon:require('@/assets/icon/favicon.png'),
              link:"https://ppid.klaten.go.id/",
              description: "kategori Layanan Masyarakat",
            },
            // {
            //   title:"Pena Mas",
            //   icon:require('@/assets/icon/pena-mas.png'),
            //   link:""
            // },
            // {
            //   title:"Matur Dokter",
            //   icon:require('@/assets/icon/matur-dokter.png'),
            //   link:""
            // },
            // {
            //   title:"MPP",
            //   icon:require('@/assets/icon/mpp.png'),
            //   link:""
            // },
            // {
            //   title:"Tiket Pariwisata",
            //   icon:require('@/assets/icon/tiket.png'),
            //   link:""
            // },
            // {
            //   title:"Pertanian",
            //   icon:require('@/assets/icon/pertanian.png'),
            //   link:""
            // }
          ],
          news: [],
          video: [
            {
              link: "https://www.youtube.com/embed/gaLZKRBbWn0?rel=0;iv_load_policy=3",
              id: "gaLZKRBbWn0",
              title: "HKB 2023 BPBD Klaten",
              description: "Dalam rangka memperingati Hari Kesiapsiagaan Bencana (HKB) 2023, BPBD Klaten turut berpartisipasi dengan membunyikan kentongan"
            },
            {
              link: "https://www.youtube.com/embed/YZr3FNj-9Io?rel=0;iv_load_policy=3",
              id: "YZr3FNj-9Io",
              title: "Evakuasi Bencana Berbasis Aplikasi SIVABEL",
              description: "BPBD Klaten bekerjasama dengan Universitas Muhammadiyah Surakarta (UMS), mengembangkan Aplikasi untuk memudahkan Disabilitas dalam meningkatkan pengetahuan dalam Mitigasi Bencana, dan model Evakuasi bagi Disabilitas melalui Aplikasi.(Jum'at, 16/12/2022) BPBD Klaten launching Aplikasi Sistem Pembelajaran Kebencanaan dengan E-Learning untuk Penyandang Disabilitas (SIPAKDEDIFA) dan Sistem Informasi Evakuasi Bencana Ramah Difabel (SIVABEL)."
            },
            {
              link: "https://www.youtube.com/embed/ZgG7Qrn445s?rel=0;iv_load_policy=3",
              id: "ZgG7Qrn445s",
              title: "KLATEN THE SHINE OF JAVA",
              description: ""
            },
            {
              link: "https://www.youtube.com/embed/gaLZKRBbWn0?rel=0;iv_load_policy=3",
              id: "gaLZKRBbWn0",
              title: "HKB 2023 BPBD Klaten",
              description: "Dalam rangka memperingati Hari Kesiapsiagaan Bencana (HKB) 2023, BPBD Klaten turut berpartisipasi dengan membunyikan kentongan"
            },
            {
              link: "https://www.youtube.com/embed/YZr3FNj-9Io?rel=0;iv_load_policy=3",
              id: "YZr3FNj-9Io",
              title: "Evakuasi Bencana Berbasis Aplikasi SIVABEL",
              description: "BPBD Klaten bekerjasama dengan Universitas Muhammadiyah Surakarta (UMS), mengembangkan Aplikasi untuk memudahkan Disabilitas dalam meningkatkan pengetahuan dalam Mitigasi Bencana, dan model Evakuasi bagi Disabilitas melalui Aplikasi.(Jum'at, 16/12/2022) BPBD Klaten launching Aplikasi Sistem Pembelajaran Kebencanaan dengan E-Learning untuk Penyandang Disabilitas (SIPAKDEDIFA) dan Sistem Informasi Evakuasi Bencana Ramah Difabel (SIVABEL)."
            },
          ],
          book: [
            {
              title: "Penggolongan Dan Kodefikasi Barang Milik Daerah",
              author: "Pemerintah",
              image_url: "https://jdih.batangkab.go.id/src/web/buku/penggolongan-dan-kodefikasi-barang-milik-daerah.jpg"
            },
            {
              title: "Hak Uji Materiil",
              author: "Pemerintah",
              image_url: "https://jdih.batangkab.go.id/src/web/buku/hak-uji-materiil.jpg"
            },
            {
              title: "Hukum Acara Peradilan Agama di Indonesia",
              author: "Pemerintah",
              image_url: "https://jdih.batangkab.go.id/src/web/buku/hukum-acara-peradilan-agama-di-indonesia.jpg"
            },
            {
              title: "Hukum Acara Perdata",
              author: "Pemerintah",
              image_url: "https://jdih.batangkab.go.id/src/web/buku/hukum-acara-perdata.jpg"
            }
          ],
          doc: [],
          video: [],
          rating: [],
          grafik: []
        },
        pagination: {},
        process: {
          load: false,
          category: false,
          form: false,
          video: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/beranda',
          title: 'Beranda',
          description: 'Beranda JDIH Setwan Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      },
      years () {
        let max = new Date().getFullYear(), min = max - 200, years = []

        this.filter.year = this.$store.state.year

        for (let i = max; i >= min; i--) {
          years.push({
            text: i,
            value: i
          })
        }

        return years
      },
    },
    mounted () {
      this.fetchTpypeDoc()
      this.fetchCategory()

      this.fetchDataListYoutube()
    },
    methods: {
      voiceHover (item) {
        responsiveVoice.cancel(); // Cancel anything else that may currently be speaking
        responsiveVoice.speak(item, 'Indonesian Female');
      },
      changeLinkPage () {
        this.filter= {
          category_bidang: this.filter.category_bidang === null ? '' : this.filter.category_bidang,
          title: this.filter.title === null ? '' : this.filter.title,
          tahun: this.filter.tahun === null ? '' : this.filter.tahun,
          nomor: this.filter.nomor === null ? '' : this.filter.nomor,
          jenis_bidang: this.filter.jenis_bidang === null ? '' : this.filter.jenis_bidang,
          search: this.filter.search === null ? '' : this.filter.search
        }
        this.$router.replace({'path': '/page', 'query': {'jenis': this.filter.jenis_bidang === '' ? 'semua-dokumen' : this.filter.jenis_bidang, 'category': this.filter.category_bidang, 'title': this.filter.title, 'tahun': this.filter.tahun, 'nomor': this.filter.nomor, 'search': this.filter.search}})
      },
      async fetchTpypeDoc (id, type) {
        this.process.load = type === undefined ? true : false

        let params = {
          limit: 1000000
        }

        await this.$axios.$get(`${process.env.API}sites/doc/doc_type`, { params })
        .then(response => {
          let res = response
            
          if (res.status === 200) {
            this.category = [
              {
                name: "Semua Dokumen",
                slug: "semua-dokumen",
                id: ''
              }
            ]

            let selecCategory = []
            selecCategory = res.results.data.map(item => {
              const container = {};
              container.par_id = item.par_id;
              container.id = item.id
              container.name = item.title;
              container.slug = (item.title.split(' ').join('-')).toLowerCase();
              return container
            })

            for (let i in selecCategory) {
              if(selecCategory[i].par_id !== '0') {
                this.category.push(
                  {
                    name: selecCategory[i].name,
                    id: selecCategory[i].id,
                    slug: selecCategory[i].slug
                  }
                ) 
              }
              
            }
          }
        })
      },
      async fetchCategory () {
        this.process.category = true
        this.process.load = true

        // let params = {
        //   limit: 10000,

        //   sort: 'DESC',

        //   cat_type: 'produk'
        // }

        await this.$axios.$get(`${process.env.API}sites/home/category`)
        .then(response => {
          let res = response

          this.process.category = false

          if (res.status === 200) {
            this.list.category = res.results.data.map(item => {
              const container = {};
              container.title = item.title;
              container.id = item.id
              container.icon_url = item.icon_url;
              container.slug = (item.title.split(' ').join('-')).toLowerCase();
              return container
            })

            // this.filter.category = res.data.length > 0 ? res.data[0].id : ''

            this.fetchDataBanner()
          }
        })
      },
      async fetchDataBanner () {
        this.process.load = true

        await this.$axios.$get(`${process.env.API}sites/home/banner`)
        .then(response => {
          let res = response

          // this.process.load = false
            
          if (res.status === 200) {
            this.list.banner = res.results.data
            this.fetchDataNews()
            // this.process.load = false
          }
        })
      },
      async fetchDataNews () {
        this.process.load = true

        let params = {
          // category_id: '1'
          limit: 4,
          sort: '-news_publish_datetime'
        }

        await this.$axios.$get(`${process.env.API}sites/article/data`, { params })
        .then(response => {
          let res = response

          // this.process.load = false
            
          if (res.status === 200) {
            this.list.news = res.results.data
            for(let i in this.list.news) {
              this.list.news[i].image_url_json = this.list.news[i].image_url_json === '' || this.list.news[i].image_url_json === undefined ? '' : JSON.parse(this.list.news[i].image_url_json)
            }
            this.fetchDataDoc()

            // this.list.data = res.data
            // this.pagination = res.pagination
          }
        })
      },
      async fetchDataListYoutube () {
        this.process.video = true

        await this.$axios.$get(`${process.env.API}sites/home/youtube?max_result=10`)
        .then(response => {
          let res = response

          this.process.video = false
            
          if (res.status === 200) {
            // console.log(res.results.data.items)
            this.list.video = res.results.data !== null ? res.results.data.items : []
            // this.fetchDataDoc()

            // this.list.data = res.data
            // this.pagination = res.pagination
          }
        })
      },
      async fetchDataDoc () {
        this.process.load = true

        let params = {
          doc_type_par_id: '1',
          limit: 4,
          is_publish: 'Publish'
        }

        await this.$axios.$get(`${process.env.API}sites/home/doc`, { params })
        .then(response => {
          let res = response

          // this.process.load = false
            
          if (res.status === 200) {
            // console.log(res.results.data.items)
            this.list.doc = res.results.data
            this.fetchGarfik()

            // this.list.data = res.data
            // this.pagination = res.pagination
          }
        })
      },
      async fetchGarfik () {
        this.process.load = true

        // let params = {
        //   doc_type_par_id: '1'
        // }

        await this.$axios.$get(`${process.env.API}sites/grafik/data_tahunan`)
        .then(response => {
          let res = response

          // this.process.load = false
            
          if (res.status === 200) {
            // console.log(res.results.data.items)
            this.series = res.results.arr_grafik
            this.chartOptions.xaxis.categories = res.results.arr_year
            this.fetchDataRating()

            // this.list.data = res.data
            // this.pagination = res.pagination
          }
        })
      },
      async fetchDataRating (type) {
        this.process.load = type !== undefined ? false : true

        await this.$axios.$get(`${process.env.API}sites/survey/data`)
        .then(response => {
          let res = response

          // this.process.load = false
            
          if (res.status === 200) {
            // console.log(res.results.data.items)
            this.list.rating = res.results.data
            this.seriesPie = []
            this.chartOptionsPie.labels = []
            for(let i = 0; i < res.results.data.length; i++){
              this.seriesPie.push(parseInt(res.results.data[i].total))
              this.chartOptionsPie.labels.push(res.results.data[i].label)
            }
            this.process.load = false

            // this.list.data = res.data
            // this.pagination = res.pagination
          }
        })
      },

      reset (index) {
        if (this.$refs.form) this.$refs.form.reset()
        
        this.form = {
          rating: '',
          age: '',
          gender: '',
          job: '',
          pendidikan: '',
          tampilan: '',
          content: '',
          kemudahan: '',
          saran: ''
        }

        this.process.form = false

        this.message.error = ''

        // if (this.dialog.form) this.fetchUnit()
      },
      async save() {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = 'sites/survey/update'
          
          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {
              this.dialog.review = false
              this.dialog.success = true

              this.fetchDataRating('update')
            } else {
              this.message.error = response.message
            }

          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      toDetail(item) {
        this.$router.push(`/page/${(item.title.split('-').join('=').split(' ').join('-').split('/').join('>'))}?q=${item.id}`)
      },

      toSelectedDetail(item) {
        if (item.category_id == '2') {
          this.toDetailPengumuman(item)
        } else {
          this.toDetailBerita(item)
        }
      },

      toDetailPengumuman(item) {
        this.$router.push(`/pengumuman/${(item.title.split('-').join('=').split(' ').join('-').split('/').join('>'))}?q=${item.id}`)
      },

      toDetailBerita(item) {
        this.$router.push(`/berita/${(item.title.split('-').join('=').split(' ').join('-').split('/').join('>'))}?q=${item.id}`)
      },

      openListLayanan(item) {
        this.selected.layanan = item
        this.dialog.layanan = true
      },

      openVideo(data) {
        this.selected.video = null
        this.selected.video = data
        this.dialog.video = true
      },

      goToSlidePrev(index) {
        this.selected.data = index === 0 ? 2 : this.selected.data - 1

        this.$refs.carousel3d.goSlide(this.selected.data)
      },
      goToSlide(index) {
        this.selected.data = index

        this.$refs.carousel3d.goSlide(index)
      },
      goToSlideNext(index) {
        this.selected.data = index === 2 ? 0 : this.selected.data + 1

        this.$refs.carousel3d.goSlide(this.selected.data)
      },
      onAfterSlideChange(index) {
        this.selected.data = index

        console.log('@onAfterSlideChange Callback Triggered', 'Slide Index ' + index)
      },
      onBeforeSlideChange(index) {
        console.log('@onBeforeSlideChange Callback Triggered', 'Slide Index ' + index)
      },
      onLastSlide(index) {
        console.log('@onLastSlide Callback Triggered', 'Slide Index ' + index)
      }
    }
  }
</script>