 <template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 400px; padding-top: 72px;` : `padding-bottom: 610px; padding-top: 64px;`">
    <v-dialog
      persistent
      scrollable
      v-if="dialog.filter"
      v-model="dialog.filter"
      width="450">
      <v-card class="box-shadow" style="border-radius: 6px;">
        <div class="d-flex align-center body-2 px-5 py-5">
          <div style="font-size: 18px; line-height: 21px; color: #505050; mix-blend-mode: normal; opacity: 0.7; font-weight: 900;">
            Filter data
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="dialog.filter = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <v-card-text class="pb-5 pt-0" style="min-height: 150px;">
          <div class="mt-0">
            <v-text-field
              solo
              hide-details
              v-model="search"
              clearable
              @click:clear="search = ''"
              :color="`${set_color}`"
              :placeholder="`Judul`">
            </v-text-field>

            <v-select
              :items="category"
              :color="`${set_color}`"
              :item-color="`${set_color}`"
              hide-details
              class="my-4"
              clearable
              @click:clear="selected.category = ''"
              v-model="selected.category"
              item-text="title"
              item-value="id"
              placeholder="Kategori Berita"
              solo
            ></v-select>
          </div>
        </v-card-text>
        <v-card-actions class="pa-0">
          <!-- <v-spacer></v-spacer> -->
          <v-btn
            elevation="0"
            :color="set_color"
            block
            height="50"
            @click="fetch(); dialog.filter = false;"
            class="text-capitalize white--text">
            <div class="d-flex align-center justify-center" style="width: 100%;">
              <v-icon>mdi-check</v-icon>
              <div class="ml-2 fs-18">
                <span>Cari</span>
              </div>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout wrap>
      <v-flex>
        <section>
          <!-- <v-row>
            <v-col  
              cols="12" class="pt-0">
              <v-card
                flat
                cover
                class="background-default">
                <v-img :src="require('@/assets/image/Candi-Prambanan.jpg')" width="100%" height="250" style="opacity: 0.75;" :gradient="`to right, ${set_color_rgb_up}, #000000a6`" class="img_center align-center">

                  <div class="d-flex align-center justify-center font-weight-black">
                    <div class="text-center">
                      <div 
                        class="text-h4 white--text justify-center mb-2 pt-6"
                        style="font-weight: 500;">
                        Berita
                      </div>
        
                      <div 
                        class="body-1 white--text text-center mb-4" 
                        style="opacity: 50%; font-weight: 500;">
                        Mengenal Berita di kabupaten Klaten.
                      </div>
                    </div>
                  </div>

                </v-img>
              </v-card>
            </v-col>
          </v-row> -->
          <v-container>
            <div>
              <v-row>
                <v-col cols="12" md="9" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'pt-0'">
                  <div class="d-flex align-center mb-4" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'mt-2' : ''">
                    <div class="font-weight-medium" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                      Berita
                    </div>
                    <v-spacer />
                    <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
                      <v-btn
                        :color="`${set_color}`"
                        elevation="0"
                        icon
                        min-width="40"
                        @click="dialog.filter = true"
                        class="text-capitalize px-0"
                        style="border-radius: 6px;"
                        >
                        <v-icon size="20">mdi-filter</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <div>
                    <v-chip
                      class="ma-2"
                      close
                      v-if="process.filter && dialog.filter === false && search !== '' && search !== null"
                      text-color="white"
                      :color="set_color"
                      @click:close="search = ''; fetch()"
                      >
                      Search: {{ search }}
                    </v-chip>
                    <!-- <v-chip
                      class="ma-2"
                      close
                      v-if="process.filter && dialog.filter === false && selected.category !== '' && selected.category !== null"
                      text-color="white"
                      :color="set_color"
                      @click:close="selected.category_name = ''; selected.category = ''; fetch()"
                      >
                      Kategori: {{ selected.category_name }}
                    </v-chip> -->
                  </div>
                  <v-card flat
                    v-for="(item, index) in process.load ? 8 : list"
                    :key="index"
                    style="border-radius: 6px;"
                    class="mb-5">
                    <v-skeleton-loader
                      class="ma-auto"
                      :loading="process.load"
                      type="list-item-avatar-three-line">
                      <div>
                        <div v-if="!process.load && list.length > 0">
                          <v-list three-line>
                            <template>
                              <v-list-item>
                                <v-list-item-avatar tile style="width: 150px; height: 100px;">
                                  <v-img contain :src="item.image_url_json.length > 0 ? item.image_url_json[0] : item.image_url === '' ? require('@/assets/image/no-photo.png') : item.image_url" alt="JDIH Setwan Kabupaten Klaten"></v-img>
                                </v-list-item-avatar>
      
                                <v-list-item-content>
                                  <div @click="toDetail(item)" @mouseover="voiceHover(item.title)" style="cursor: pointer">{{ item.title }}</div>
                                  <v-list-item-subtitle>
                                    <div>
                                      <v-icon
                                        size="12">
                                        mdi-account-circle-outline
                                      </v-icon>
                                      <span class="me-1 font-small">JDIH Setwan Kabupaten Klaten</span>
                                      <span class="ml-2">
                                        <v-icon
                                          size="12">
                                          mdi-calendar-range
                                        </v-icon>
                                        <span class="me-1 font-small"> {{ item.publish_datetime | date }} </span>
                                      </span>
                                      <span class="ml-2">
                                        <v-icon
                                          size="12">
                                          mdi-eye
                                        </v-icon>
                                        <span class="me-1 font-small">Dilihat {{ item.view_count }} Kali </span>
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle>
                                    <div class="caption font-weight-light three-line my-2" style="opacity: 60%;">
                                      <span v-html="item.content.replace(/(<([^>]+)>)/ig, '')"></span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <!-- <div class="text-right mt-5">
                                    <v-btn
                                      style="border-radius: 6px;"
                                      height="40"
                                      elevation="0"
                                      :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? true : true"
                                      color="blue"
                                      class="body-2 text-capitalize font-weight-bold white--text">
                                      
                                      <v-icon size="20" class="mr-2">mdi-text-box-search-outline</v-icon>
                                      Lihat Detail
                                    </v-btn>
                                  </div> -->
                                </v-list-item-content>
                                <!-- <v-list-item-action>
                                  <v-chip
                                    class="pa-2"
                                    text-color="white"
                                    x-small
                                    color="green"
                                    label
                                  >
                                    Berlaku
                                  </v-chip>
                                </v-list-item-action> -->
                              </v-list-item>
                              <v-divider></v-divider>
                            <div class="text-right pa-5">
                              <!-- <v-btn
                                style="border-radius: 6px;"
                                elevation="0"
                                :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                                color="red"
                                class="text-subtitle-2 text-capitalize font-weight-bold white--text">
                                
                                <v-icon size="20" class="mr-2">mdi-file-document-outline</v-icon>
                                Abstrak
                              </v-btn>
                              <v-btn
                                style="border-radius: 6px;"
                                elevation="0"
                                :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                                color="green"
                                class="text-subtitle-2 text-capitalize font-weight-bold white--text">
                                
                                <v-icon size="20" class="mr-2">mdi-download</v-icon>
                                Download
                              </v-btn> -->
                              <v-btn
                                style="border-radius: 6px;"
                                elevation="0"
                                @click="toDetail(item)"
                                @mouseover="voiceHover('Lihat Detail')"
                                :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                                color="blue"
                                class="text-subtitle-2 text-capitalize font-weight-bold white--text">
                                
                                <v-icon size="20" class="mr-2">mdi-text-box-search-outline</v-icon>
                                Lihat Detail
                              </v-btn>
                            </div>
                            </template>
                          </v-list>
                        </div>
                        
                      </div>
                    </v-skeleton-loader>
                  </v-card>

                  <div v-if="!process.load && pagination.total_data > 20 && limit < pagination.total_data"
                    class="text-center mt-4 mb-2">
                    <v-btn
                      @click="limit += 20"
                      :loading="process.limit"
                      small
                      rounded
                      outlined
                      elevation="0"
                      @mouseover="voiceHover('Lihat Banyak')"
                      :color="set_color"
                      class="text-capitalize"
                      style="opacity: 60%;">
                      Lebih banyak
                    </v-btn>
                  </div>

                  <Empty v-if="!process.load && pagination.total_page < 1"
                    class="py-12"
                    margin="my-16"
                    size="125"
                    message="Berita" />
                </v-col>

                <v-col cols="12" md="3" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                  <v-card flat style="border-radius: 6px;">
                    <v-card-text>
                      <div class="font-weight-medium body-1" :style="`color: ${set_color}`">
                        Filter Data
                      </div>
                      <v-divider class="mb-4 mt-2"></v-divider>

                      <v-text-field
                        solo
                        hide-details
                        v-model="search"
                        clearable
                        @click:clear="search = ''; fetch();"
                        v-on:keydown.enter.prevent="fetch()"
                        :color="`${set_color}`"
                        :placeholder="`Judul`">
                      </v-text-field>

                      <!-- <v-autocomplete
                        :items="category"
                        :color="`${set_color}`"
                        :item-color="`${set_color}`"
                        hide-details
                        class="my-4"
                        clearable
                        v-on:keydown.enter.prevent="fetch()"
                        @click:clear="selected.category = ''; fetch();"
                        v-model="selected.category"
                        item-text="title"
                        item-value="id"
                        placeholder="Kategori Berita"
                        solo
                      ></v-autocomplete> -->


                      <v-btn
                        style="border-radius: 6px;"
                        elevation="0"
                        block
                        height="48"
                        @click="fetch()"
                        @mouseover="voiceHover('Cari')"
                        :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                        :color="`${set_color}`"
                        class="text-subtitle-2 text-capitalize font-weight-bold white--text mt-5">
                        
                        <v-icon size="20" class="mr-2">mdi-magnify</v-icon>
                        Cari
                      </v-btn>
                    </v-card-text>
                  </v-card>

                  <Sidebar />
                </v-col>
              </v-row>
            </div>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        search: '',

        selected: {
          category: '',
          category_name: ''
        },

        dialog: {
          filter: false
        },
        
        list: [],
        pagination: {},

        limit: 10,

        category: [],

        process: {
          load: false,

          limit: false,

          filter: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/berita',
          title: 'Berita',
          description: 'Berita JDIH Setwan Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty'),
      Sidebar: () => import(/* webpackPrefetch: true */ '@/components/sidebar/index')
    },
    watch: {
      'limit': function() {
        this.process.limit = true
        
        this.fetch('limit')
      },
    },

    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        console.log(this.$store.state.header.config.color)
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    beforeMount () {
    },
    mounted () {
      this.fetch()
      this.fetchCategory()
    },
    methods: {
      voiceHover (item) {
        responsiveVoice.cancel(); // Cancel anything else that may currently be speaking
        responsiveVoice.speak(item, 'Indonesian Female');
      },
      async fetchCategory (id, type) {
        // this.process.load = type === undefined ? true : false

        let params = {
          limit: 1000000
        }

        await this.$axios.$get(`${process.env.API}sites/article/category`, { params })
        .then(response => {
          let res = response
            
          if (res.status === 200) {
            this.category = res.results.data
          }
        })
      },
      async fetch(type) {
         this.process.load = type === undefined ? true : false
         this.process.limit = true
         this.process.filter = false

         let params = {
          search: this.search,
          limit: this.limit,
          sort: '-news_publish_datetime',

          category_id: this.selected.category
        }

        await this.$axios.$get(`${process.env.API}sites/article/data`, { params })
        .then(response => {
          let res = response
          
          if (res.status === 200) {
            this.list = res.results.data
            for(let i in this.list) {
              this.list[i].image_url_json = this.list[i].image_url_json === '' || this.list[i].image_url_json === undefined ? '' : JSON.parse(this.list[i].image_url_json)
            }
            this.pagination = res.results.pagination
            this.process.load = false
            this.process.limit = false

            if (this.selected.category !== '') {
              for(let i in this.category) {
                if (this.category[i].id == this.selected.category) {
                  this.selected.category_name = this.category[i].title
                }
              }

              this.process.filter = true
            }

            if (this.search !== '' || this.search === null) {
              this.process.filter = true
            }

          }
        })
      },

      changeLink(query) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        
        if (query !== undefined) {
          if (query === 'utama') {
              this.selected = {
                category: 0,
              }
          } else if (query === 'opd') {
            this.selected = {
              category: 1
            }
          } else if (query === 'wilayah') {
            this.selected = {
              category: 2
            }
          } 
        }
        if (this.selected.category === 0) {
          this.$router.push(`/berita?category=utama`)
        }
      },
      changeQuery(link) {
        this.$router.push(link)
      },
      toDetail(item) {
        this.$router.push(`/berita/${(item.title.split('-').join('=').split(' ').join('-').split('/').join('>'))}?q=${item.id}`)
      },
    }
    // mounted () {
    //   // this.fetch()
    // },
    // methods: {
    //   async fetch (type) {
    //     this.process.load = type === undefined ? true : false

    //     // let params = {
    //     //   search: this.search,
    //     //   limit: this.limit,

    //     //   post_type: 'berita',
    //     //   post_status: 'publish'
    //     // }

    //     // await this.$axios.$get(`${process.env.API}post`, { params })
    //     // .then(response => {
    //     //   let res = response

    //     //   this.process.load = false

    //     //   this.process.limit = false
            
    //     //   if (res.code === 200) {
    //     //     this.list = res.data
    //     //     this.pagination = res.pagination
    //     //   }
    //     // })
    //     setTimeout(() => {
    //       this.process.load = false
    //     }, 1000);
    //   }
    // }
  }
</script>