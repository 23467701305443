<template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 400px; padding-top: 72px;` : `padding-bottom: 610px; padding-top: 64px;`">
    <v-dialog v-model="dialog.success"
      width="350">
      <v-card 
        class="border-radius pa-2">
        <v-card-text>
          <v-img 
            width="125"
            height="125"
            alt="JDIH Setwan Kabupaten Klaten" 
            :src="require('@/assets/icon/info_berhasil.png')"
            class="ma-auto my-6">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  size="20"
                  indeterminate 
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <div
            class="body-1 text-center line-text-second">
            <div>
              Terima kasih, pengisian pengaduan Anda
            </div>

            <div>
              telah berhasil ditambahkan!
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row>
              <v-col cols="12" md="9">
                <div class="font-weight-medium mb-2 mt-2" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                  Form Pengaduan 
                </div>
                <v-card flat
                  style="border-radius: 6px;"
                  class="mb-5 pa-5">
                  <ValidationObserver 
                    ref="form">
                    <v-form>

                      <ValidationProvider 
                        name="NIK" 
                        rules="required|max:16" 
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>

                          NIK
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save()"
                          
                          filled
                          rounded
                          dense

                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          type="number"
                          min="0"

                          :color="set_color"
                          placeholder="Contoh. xxxxxxxxxxxxxxx"

                          hide-details

                          v-model="form.nik"
                          :success="valid"
                          :error-messages="errors"
                          required
                          class="mb-6"
                          style="border-radius: 10px;">
                          <template v-slot:append-outer>
                            <div v-if="errors.length > 0">
                              <v-tooltip top color="#ff5252">
                                <template v-slot:activator="{ on }">
                                  <v-icon color="error" v-on="on">mdi-alert-circle-outline</v-icon>
                                </template>
                                
                                {{ errors[0] }}
                              </v-tooltip>
                            </div>
                            
                            <div v-if="form.nik !== '' && valid">
                              <v-icon color="success">mdi-check-circle-outline</v-icon>
                            </div>
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                      <ValidationProvider 
                        name="Nama" 
                        rules="required" 
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>

                          Nama
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save()"
                          
                          filled
                          rounded
                          dense

                          type="string"

                          :color="set_color"
                          placeholder="Contoh. John Doe"

                          hide-details

                          v-model="form.name"
                          :success="valid"
                          :error-messages="errors"
                          required
                          class="mb-6"
                          style="border-radius: 10px;">
                          <template v-slot:append-outer>
                            <div v-if="errors.length > 0">
                              <v-tooltip top color="#ff5252">
                                <template v-slot:activator="{ on }">
                                  <v-icon color="error" v-on="on">mdi-alert-circle-outline</v-icon>
                                </template>
                                
                                {{ errors[0] }}
                              </v-tooltip>
                            </div>
                            
                            <div v-if="form.name !== '' && valid">
                              <v-icon color="success">mdi-check-circle-outline</v-icon>
                            </div>
                          </template>
                        </v-text-field>
                      </ValidationProvider>

                      <div class="body-2 mb-3">
                        <span class="red--text">*</span>
                        
                        Jenis Kelamin
                      </div>
                      
                      <ValidationProvider 
                        name="Jenis Kelamin" 
                        rules="required" 
                        v-slot="{ errors }">
                      
                        <v-radio-group
                          v-model="form.gender"
                          class="mt-0"
                          hide-details
                          :error-messages="errors" 
                          column
                          >
                          <v-radio
                          v-for="(item, index) in gender" :key="index" :label="item" :value="item"
                            :color="set_color"
                          ></v-radio>
                          
                          <div class="pb-3 caption">
                            {{ errors[0] }}
                          </div>
                        </v-radio-group>
                      </ValidationProvider>

                      <ValidationProvider 
                        name="Alamat" 
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                            Alamat
                          <!-- <span :class="item.required ? 'error--text' : ''">
                            ({{ item.required ? 'wajib diisi' : 'boleh dikosongi' }})
                          </span> -->
                        </div>

                        <v-textarea
                          vv-uppercase
                          v-on:keyup.enter="save()"
                          
                          filled
                          rounded
                          dense

                          :color="set_color"
                          placeholder="Tulis Disini"

                          hide-details

                          v-model="form.address"
                          class="mb-6"
                          style="border-radius: 10px;">
                          <template v-slot:append-outer>
                            <div v-if="errors.length > 0">
                              <v-tooltip top color="#ff5252">
                                <template v-slot:activator="{ on }">
                                  <v-icon color="error" v-on="on">mdi-alert-circle-outline</v-icon>
                                </template>
                                
                                {{ errors[0] }}
                              </v-tooltip>
                            </div>

                            <div v-if="form.address !== '' && valid">
                              <v-icon color="success">mdi-check-circle-outline</v-icon>
                            </div>
                          </template>
                        </v-textarea>
                      </ValidationProvider>


                      <ValidationProvider 
                        name="Umur" 
                        rules="required" 
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>

                          Umur
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save()"
                          
                          filled
                          rounded
                          dense

                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          type="number"
                          min="0"

                          :color="set_color"
                          placeholder="Contoh. 20"

                          hide-details

                          v-model="form.age"
                          :success="valid"
                          :error-messages="errors"
                          required
                          class="mb-6"
                          style="border-radius: 10px;">
                          <template v-slot:append-outer>
                            <div v-if="errors.length > 0">
                              <v-tooltip top color="#ff5252">
                                <template v-slot:activator="{ on }">
                                  <v-icon color="error" v-on="on">mdi-alert-circle-outline</v-icon>
                                </template>
                                
                                {{ errors[0] }}
                              </v-tooltip>
                            </div>
                            
                            <div v-if="form.age !== '' && valid">
                              <v-icon color="success">mdi-check-circle-outline</v-icon>
                            </div>
                          </template>
                        </v-text-field>
                      </ValidationProvider>

                      <!-- <ValidationProvider 
                        name="Pendidikan" 
                        rules="required" 
                        v-slot="{ errors }">
                        <div class="body-2 mb-0">
                          <span class="red--text">*</span>

                          Pendidikan
                        </div>

                        <v-radio-group
                          v-model="form.pendidikan"
                          class="mt-0"
                          hide-details
                          :error-messages="errors" 
                          column>
                          <v-row>
                            <v-col>
                              <v-radio
                                label="SD/MI"
                                value="SD/MI"
                                :color="set_color"
                              ></v-radio>
                              <v-radio
                                label="SMP/MTs"
                                value="SMP/MTs"
                                :color="set_color"
                              ></v-radio>
                              <v-radio
                                label="SMA/MA"
                                value="SMA/MA"
                                :color="set_color"
                              ></v-radio>
                            </v-col>
                            <v-col>
                              <v-radio
                                label="D1"
                                value="D1"
                                :color="set_color"
                              ></v-radio>
                              <v-radio
                                label="D2"
                                value="D2"
                                :color="set_color"
                              ></v-radio>
                              <v-radio
                                label="D3"
                                value="D3"
                                :color="set_color"
                              ></v-radio>
                            </v-col>
                            <v-col>
                              <v-radio
                                label="D4"
                                value="D4"
                                :color="set_color"
                              ></v-radio>
                              <v-radio
                                label="S1"
                                value="S1"
                                :color="set_color"
                              ></v-radio>
                              <v-radio
                                label="S2 Ke atas"
                                value="S2 Ke atas"
                                :color="set_color"
                              ></v-radio>
                            </v-col>
                          </v-row>
                          <div class="pb-3 caption">
                            {{ errors[0] }}
                          </div>
                        </v-radio-group>
                      </ValidationProvider> -->


                      <ValidationProvider 
                        name="Pekerjaan" 
                        rules="required" 
                        v-slot="{ errors }">
                        <div class="body-2 mb-0">
                          <span class="red--text">*</span>

                          Pekerjaan
                        </div>

                        <v-radio-group
                          v-model="form.job"
                          class="mt-0"
                          hide-details
                          :error-messages="errors" 
                          column>
                          <v-row>
                            <v-col>
                              <v-radio
                                label="PNS/TNI/POLRI"
                                value="PNS/TNI/POLRI"
                                :color="set_color"
                              ></v-radio>
                              <v-radio
                                label="Pegawai Swasta"
                                value="Pegawai Swasta"
                                :color="set_color"
                              ></v-radio>
                            </v-col>
                            <v-col>
                              <v-radio
                                label="Wiraswasta"
                                value="Wiraswasta"
                                :color="set_color"
                              ></v-radio>
                              <v-radio
                                label="Pelajar/Mahasiswa"
                                value="Pelajar/Mahasiswa"
                                :color="set_color"
                              ></v-radio>
                            </v-col>
                            <v-col>
                              <v-radio
                                label="Lainnya"
                                value="Lainnya"
                                :color="set_color"
                              ></v-radio>
                            </v-col>
                          </v-row>
                          <div class="pb-3 caption">
                            {{ errors[0] }}
                          </div>
                        </v-radio-group>
                      </ValidationProvider>
                      

                      <ValidationProvider 
                        name="Pengaduan" 
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                            Isi Pengaduan
                          <!-- <span :class="item.required ? 'error--text' : ''">
                            ({{ item.required ? 'wajib diisi' : 'boleh dikosongi' }})
                          </span> -->
                        </div>

                        <v-textarea
                          vv-uppercase
                          v-on:keyup.enter="save()"
                          
                          filled
                          rounded
                          dense

                          :color="set_color"
                          placeholder="Tulis Disini"

                          hide-details

                          v-model="form.content"
                          class="mb-6"
                          style="border-radius: 10px;">
                          <template v-slot:append-outer>
                            <div v-if="errors.length > 0">
                              <v-tooltip top color="#ff5252">
                                <template v-slot:activator="{ on }">
                                  <v-icon color="error" v-on="on">mdi-alert-circle-outline</v-icon>
                                </template>
                                
                                {{ errors[0] }}
                              </v-tooltip>
                            </div>

                            <div v-if="form.content !== '' && valid">
                              <v-icon color="success">mdi-check-circle-outline</v-icon>
                            </div>
                          </template>
                        </v-textarea>
                      </ValidationProvider>
                  <!-- <v-radio-group v-model="form.id">
                    <v-radio
                      v-for="(item, index) in list.rating"
                      :key="index"
                      :color="set_color"
                      :label="item.label"
                      :value="item.id"
                    ></v-radio>
                  </v-radio-group> -->
                    </v-form>
                  </ValidationObserver>

                  <v-card-actions
                  class="pa-4 pb-0">
                  <v-spacer/>

                  <v-btn
                    @click="save()"
                    :loading="process.form"
                    :disabled="process.form"
                    width="150"
                    elevation="0"
                    color="green"
                    rounded
                    class="body-2 font-weight-bold text-capitalize white--text">
                    Simpan
                  </v-btn>
                </v-card-actions>

                  <div v-if="message.error.length > 0"
                    class="text-center mt-6">
                    <div
                      class="error--text d-flex align-start">
                      <v-icon size="18" color="red" style="margin-top: 3px;">mdi-alert-circle-outline</v-icon>
                      
                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <Sidebar />
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          success: false
        },
        gender: [
          'Laki-laki', 'Perempuan'
        ],
        form: {
          name: '',
          gender: '',
          age: '',
          job: '',
          content: '',
          address: '',
          nik: ''
        },
        process: {
          load: false,
          form: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/pengaduan',
          title: 'Pengaduan JDIH',
          description: 'Pengaduan JDIH Setwan Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Sidebar: () => import(/* webpackPrefetch: true */ '@/components/sidebar/index')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      
    },
    methods: {
      reset (index) {
        if (this.$refs.form) this.$refs.form.reset()
        
        this.form = {
          name: '',
          age: '',
          gender: '',
          job: '',
          content: '',
          address: '',
          nik: ''
        }

        this.process.form = false

        this.message.error = ''

        // if (this.dialog.form) this.fetchUnit()
      },
      async save() {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = 'sites/aduan/add'
          
          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {
              this.reset()
              this.dialog.success = true

            } else {
              this.message.error = response.message
            }

          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },
    }
  }
</script>

<style>
  #table-desa {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #table-desa td, #table-desa th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #table-desa tr:nth-child(even){background-color: #f2f2f2;}

  #table-desa tr:hover {background-color: #ddd;}

  #table-desa th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    /* background-color: #04AA6D; */
    color: white;
}
</style>