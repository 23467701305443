<template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 400px; padding-top: 72px;` : `padding-bottom: 610px; padding-top: 64px;`">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <div class="font-weight-medium mb-2 mt-2 d-flex align-center" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
              <div>
                Statistik
              </div>
              <v-spacer />
              <!-- <div>
                <v-select
                  :items="years"
                  :color="`${set_color}`"
                  :item-color="`${set_color}`"
                  hide-details
                  v-model="filter.tahun"
                  v-on:change="fetchPengunjung()"
                  @click:clear="filter.tahun = ''"
                  class="my-4"
                  item-text="name"
                  item-value="id"
                  placeholder="Tahun"
                  solo
                ></v-select>
              </div> -->
            </div>
            <div class="body-2 mb-4">
              Beberapa grafik statistik berikut menjelaskan beragam data dari dokumentasi dan informasi hukum, baik data status peraturan, jumlah peraturan dan jumlah dokumen hukum yang ada pada website JDIH Kab. Klaten.
            </div>
            <v-row>
              <v-col cols="12" md="6">
                <v-card flat
                  style="border-radius: 6px;"
                  class="mb-5 pa-5">
                  <div class="text-center">
                    Grafik Produk Hukum
                  </div>
                   <div v-if="process.load">
                    <v-card
                      flat
                      height="100%"
                      v-if="process.load"
                      :min-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '400px' : '40vh'"
                      :max-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '400px' : '40vh'"
                      color="#00000040">
                      <v-skeleton-loader
                        v-if="process.load"
                        :loading="process.load"
                        transition="scale-transition"
                        :tile="false"
                        class="ma-auto load_card_x_large"
                        type="image"
                        style="width: 100% !important; height: 100% !important; min-height: 100% !important;">
                      </v-skeleton-loader>
                    </v-card>
                  </div>
                  <div id="chart" v-if="!process.load">
                    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card flat
                  style="border-radius: 6px;"
                  class="mb-5 pa-5">
                  <div class="text-center">
                    Grafik Status Produk Hukum
                  </div>
                  <div v-if="process.load">
                    <v-card
                      flat
                      height="100%"
                      v-if="process.load"
                      :min-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '400px' : '40vh'"
                      :max-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '400px' : '40vh'"
                      color="#00000040">
                      <v-skeleton-loader
                        v-if="process.load"
                        :loading="process.load"
                        transition="scale-transition"
                        :tile="false"
                        class="ma-auto load_card_x_large"
                        type="image"
                        style="width: 100% !important; height: 100% !important; min-height: 100% !important;">
                      </v-skeleton-loader>
                    </v-card>
                  </div>
                  <div v-if="!process.load && Object.keys(this.list_status).length > 0">
                    <apexchart type="bar" height="350" :options="list_status.chartOptions" :series="list_status.series"></apexchart>
                  </div>
                </v-card>
              </v-col>

              <v-col cols="12" md="12">
                <div class="font-weight-medium mb-2 mt-2 d-flex align-center" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                  <div>
                    <!-- Filter Tahun -->
                  </div>
                  <v-spacer />
                  <div>
                    <v-select
                      :items="years"
                      :color="`${set_color}`"
                      :item-color="`${set_color}`"
                      hide-details
                      v-model="filter.tahun"
                      v-on:change="process.filter = true; fetchPengunjung()"
                      @click:clear="filter.tahun = ''"
                      class="my-4"
                      item-text="name"
                      item-value="id"
                      placeholder="Tahun"
                      solo
                    ></v-select>
                  </div>
                </div>
                <v-card flat
                  style="border-radius: 6px;"
                  class="mb-5 pa-5">
                  <div class="text-center">
                    Grafik Rekap Pengunjung Website JDIH Setwan Kabupaten Klaten
                  </div>
                  <div v-if="!process.load && Object.keys(this.list_pengunjung).length > 0">
                    <apexchart type="area" height="350" :options="list_pengunjung.chartOptions" :series="list_pengunjung.series"></apexchart>
                  </div>
                </v-card>
              </v-col>

              <!-- <v-col cols="12" md="6">
                <v-card flat
                  style="border-radius: 6px;"
                  class="mb-5 pa-5">
                  <div class="text-center mb-3">
                    20 Desa dengan Produk Hukum Terbanyak
                  </div>
                  <table id="table-desa">
                    <tr>
                      <th :style="`background-color: ${set_color}`">No</th>
                      <th :style="`background-color: ${set_color}`">Desa</th>
                      <th :style="`background-color: ${set_color}`">Kecamatan</th>
                      <th :style="`background-color: ${set_color}`">Jumlah</th>
                    </tr>
                    <tr v-for="(item, index) in 20" :key="index">
                      <td>{{ index+1 }}</td>
                      <td>Desa {{ index+1 }}</td>
                      <td>Kecamatan</td>
                      <td>{{ 100+index }}</td>
                    </tr>
                  </table>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card flat
                  style="border-radius: 6px;"
                  class="mb-5 pa-5">
                  <div class="text-center">
                    Grafik Produk Hukum Desa
                  </div>
                  <div id="chart">
                    <apexchart type="bar" height="350" :options="grafik.desa.chartOptions" :series="grafik.desa.series"></apexchart>
                  </div>
                </v-card>
              </v-col> -->
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        series: [
          {
            name: 'Berlaku',
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
          },
          {
            name: 'Diubah',
            data: [5, 10, 1, 4, 20, 19, 20, 55, 12]
          },
          {
            name: 'Mencabut Sebagian',
            data: [1, 2, 1, 10, 20, 1, 10, 1, 0]
          },
          {
            name: 'Tidak Berlaku',
            data: [0, 0, 8, 0, 15, 0, 10, 5, 2]
          }
        ],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          colors: ['#66DA26', '#2E93fA', '#FFEB3B', '#E91E63', '#FF9800', '#9C27B0', '#03A9F4', '#00BCD4', '#FF9800', '#009688', '#3F51B5', '#CDDC39', '#FFC107', '#FF5722'],
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
          },
          yaxis: {
            title: {
              text: '(Produk Hukum)'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val 
              }
            }
          }
        },
        list_pengunjung: {},
        list_status: {},
        grafik: {
          category: {
            series: [
              {
                name: 'Peraturan Daerah',
                data: [76]
              },
              {
                name: 'Keputusan Bupati',
                data: [30]
              },
              {
                name: 'Rancangan Peraturan Daerah',
                data: [21]
              },
              {
                name: 'Abstrak Peraturan Daerah',
                data: [12]
              },
              {
                name: 'MOU dan PKS',
                data: [5]
              },
              {
                name: 'Surat Edaran Bupati',
                data: [9]
              },
              {
                name: 'Propemperda',
                data: [2]
              },

              {
                name: 'Peraturan Bupati',
                data: [15]
              },
              {
                name: 'Instruksi Bupati',
                data: [20]
              },
              {
                name: 'Rancangan Peraturan Bupati',
                data: [33]
              },
              {
                name: 'Naskah Akademis',
                data: [21]
              },
              {
                name: 'Staatsblad',
                data: [5]
              },
              {
                name: 'Surat Edaran Sekda',
                data: [2]
              },
              {
                name: 'Peraturan Terjemahan Resmi',
                data: [76]
              },
            ],
            chartOptions: {
              chart: {
                type: 'bar',
                height: 350
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '55%',
                  endingShape: 'rounded'
                },
              },
              dataLabels: {
                enabled: false
              },
              colors: ['#66DA26', '#2E93fA', '#FFEB3B', '#E91E63', '#FF9800', '#9C27B0', '#03A9F4', '#00BCD4', '#FF9800', '#009688', '#3F51B5', '#CDDC39', '#FFC107', '#FF5722'],
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
              },
              xaxis: {
                categories: [''],
              },
              yaxis: {
                title: {
                  text: '(Kategori Produk Hukum)'
                }
              },
              fill: {
                opacity: 1
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val 
                  }
                }
              }
            },
          },
          
          desa: {
            series: [
              {
                name: 'Desa A',
                data: [66]
              },
              {
                name: 'Desa B',
                data: [12]
              },
              {
                name: 'Desa C',
                data: [2]
              },
              {
                name: 'Desa D',
                data: [5]
              },
              {
                name: 'Desa E',
                data: [10]
              },
              {
                name: 'Desa F',
                data: [5]
              },
              {
                name: 'Desa G',
                data: [8]
              },
              {
                name: 'Desa H',
                data: [1]
              },
              {
                name: 'Desa I',
                data: [3]
              },
              {
                name: 'Desa J',
                data: [9]
              }
            ],
            chartOptions: {
              chart: {
                type: 'bar',
                height: 350
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '55%',
                  endingShape: 'rounded'
                },
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
              },
              xaxis: {
                categories: [''],
              },
              yaxis: {
                title: {
                  text: 'Produk Hukum'
                }
              },
              fill: {
                opacity: 1
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val
                  }
                }
              }
            }
          },
        },
        tampilan: {
          chartOptionsPie: {
            chart: {
              width: 380,
              type: 'pie',
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            labels: ['Sangat Puas', 'Puas', 'Cukup Puas', 'Kurang Puas'],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }],
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val
                }
              }
            }
          },
        },
        filter: {
          tahun: new Date().getFullYear()
        },
        process: {
          load: false,
          filter: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/statistik',
          title: 'Data Statistik',
          description: 'Data Statistik JDIH Setwan Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      },
      years () {
        let temp = []
        var maxYear = new Date().getFullYear() + 10;
        var minYear = 2000
        for (let i = minYear; i<=maxYear; i++) {
          temp.push(i)
        }

        return temp
      },
    },
    mounted () {
      this.fetch()
      // this.fetchPengunjung()
    },
    methods: {
      async fetch() {
         this.process.load = true

         let params = {
          // search: this.search,
          // limit: this.limit,
          // category_id: '2'
        }

        await this.$axios.$get(`${process.env.API}sites/grafik/data_tahunan`, { params })
        .then(response => {
          let res = response
          
          if (res.status === 200) {
            this.list = res.results.data
            this.series = res.results.arr_grafik
            this.chartOptions.xaxis.categories = res.results.arr_year
            this.fetchPengunjung()
          }
        })
      },
      async fetchPengunjung() {
         this.process.load = this.process.filter ? false : true

         let params = {
          year: this.filter.tahun,
          // limit: this.limit,
          // category_id: '2'
        }

        await this.$axios.$get(`${process.env.API}sites/grafik/visitor_tahunan`, { params })
        .then(response => {
          let res = response
          
          if (res.status === 200) {
            this.list_pengunjung = {
              // pengunjung: {
            series: [{
              name: 'Total Pengunjung',
              data: res.results.arr_visitor.length < 1 ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : res.results.arr_visitor[0].data
            }],
            chartOptions: {
              chart: {
                type: 'bar',
                height: 350
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '55%',
                  endingShape: 'rounded'
                },
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
              },
              xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Des'],
              },
              yaxis: {
                title: {
                  text: 'Total Pengunjung'
                }
              },
              fill: {
                opacity: 1
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val + " pengunjung"
                  }
                }
              }
            // }
          },
            }
            // this.list = res.results.data
            // this.grafik.pengunjung.series[0].data = res.results.arr_visitor[0].data
            if (!this.process.filter) {
              this.fetchStatus()
            }
            this.process.filter = false
            // this.chartOptions.xaxis.categories = res.results.arr_year
            // this.process.load = false
          }
        })
      },
      async fetchStatus() {
         this.process.load = true

         let params = {
          year: this.filter.tahun,
          // limit: this.limit,
          // category_id: '2'
        }

        await this.$axios.$get(`${process.env.API}sites/grafik/data_tahunan_status`, { params })
        .then(response => {
          let res = response
          
          if (res.status === 200) {
            this.list_status = {
              // pengunjung: {
            series: res.results.arr_grafik,
            chartOptions: {
              chart: {
                type: 'bar',
                height: 350
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '55%',
                  endingShape: 'rounded'
                },
              },
              dataLabels: {
                enabled: false
              },
              colors: ['#66DA26', '#2E93fA', '#FFEB3B', '#E91E63'],
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
              },
              xaxis: {
                categories: res.results.arr_year,
              },
              yaxis: {
                title: {
                  text: 'Produk Hukum'
                }
              },
              fill: {
                opacity: 1
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val + " produk hukum"
                  }
                }
              }
            // }
          },
            }
            // this.list = res.results.data
            // this.grafik.pengunjung.series[0].data = res.results.arr_visitor[0].data
            // console.log(this.grafik)
            // this.chartOptions.xaxis.categories = res.results.arr_year
            // this.fetchTampilan()
            this.process.load = false
          }
        })
      },
      async fetchTampilan() {
         this.process.load = true

         let params = {
          // year: this.filter.tahun,
          // limit: this.limit,
          // category_id: '2'
        }

        await this.$axios.$get(`${process.env.API}sites/survey/data_tampilan`, { params })
        .then(response => {
          let res = response
          
          if (res.status === 200) {
            this.tampilan = {
              // pengunjung: {
            seriesPie: res.results.data,
            chartOptionsPie: {
              chart: {
                width: 380,
                type: 'pie',
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '55%',
                  endingShape: 'rounded'
                },
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
              },
              labels: ['Sangat Puas', 'Puas', 'Cukup Puas', 'Kurang Puas'],
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }],
              fill: {
                opacity: 1
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val
                  }
                }
              }
            }
            }
            // this.list = res.results.data
            // this.grafik.pengunjung.series[0].data = res.results.arr_visitor[0].data
            // console.log(this.grafik)
            // this.chartOptions.xaxis.categories = res.results.arr_year
            this.process.load = false
          }
        })
      },
    }
  }
</script>

<style>
  #table-desa {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #table-desa td, #table-desa th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #table-desa tr:nth-child(even){background-color: #f2f2f2;}

  #table-desa tr:hover {background-color: #ddd;}

  #table-desa th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    /* background-color: #04AA6D; */
    color: white;
}
</style>