<template>
  <div
    class="fill-height"
    style="padding-bottom: 400px; padding-top: 72px;"
    >
    <v-layout wrap>
      <v-flex>
        <section>
          <!-- <v-container>
            <v-row>
              <v-col  
                cols="12">
                <v-card
                  flat
                  class="background-default">
                  <v-card-title 
                    class="text-h4 white--text justify-center mb-2 pt-6"
                    style="font-weight: 500;">
                    VISI dan MISI
                  </v-card-title>

                  <v-card-subtitle 
                    class="body-1 white--text text-center mb-4" 
                    style="opacity: 50%; font-weight: 500;">
                    Mengenal VISI dan MISI Kabupaten Klaten.
                  </v-card-subtitle>
                  
                </v-card>
              </v-col>
            </v-row>

          </v-container> -->
          <v-container>
            <v-row>
              <v-col  
                cols="12" class="py-0">
                <div class="font-weight-medium text-h5 mb-4 mt-2" :style="`color: ${set_color}`">
                  SOP
                </div>
                <v-card
                  flat>
                  <v-card-text
                    class="body-1 black--text mb-4">
                    <!-- https://cdn.klaten.go.id/files/4673364/24/06/SOP_PENGELOLAAN_JARINGAN_DOKUMENTASI_DAN_INFORMASI_HUKUM.doc -->
                    <a href="https://cdn.klaten.go.id/files/4673364/24/07/SOP_JDIH_SETWAN_KLATEN.pdf" download target="_blank">
                      <v-btn
                        style="border-radius: 6px;"
                        elevation="0"
                        :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                        color="green"
                        class="text-subtitle-2 text-capitalize font-weight-bold white--text">
                        
                        <v-icon size="20" class="mr-2">mdi-download</v-icon>
                        Download
                      </v-btn>
                    </a>
                    <div class="mt-4">
                      <iframe :src="`https://cdn.klaten.go.id/files/4673364/24/07/SOP_JDIH_SETWAN_KLATEN.pdf`" width="100%" height="900px" frameborder="0"></iframe>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/tugas-pokok-fungsi-dan-program-kerja',
          title: 'Tugas Pokok, Fungsi dan Program Kerja',
          description: 'Tugas Pokok, Fungsi dan Program Kerja JDIH Setwan Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>