<template>
  <div
    class="fill-height" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card flat style="border-radius: 6px;" class="mt-6">
            <v-card-text>
              <div class="font-weight-medium body-1" :style="`color: ${set_color}`">
                Jenis Dokumen
              </div>
              <v-divider class="mb-0 mt-2"></v-divider>
              <div v-for="(item, index) in process.load ? 8 : category" :key="index">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.load"
                  type="list-item-three-line">
                  <div>
                    <div v-if="!process.load && category.length > 0">
                      <v-list one-line>
                        <v-list-item class="pa-0" :to="`/page?jenis=${item.slug}`" @mouseover="voiceHover(item.name)">
                            <v-list-item-content class="py-0">
                              <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action class="my-0">
                              <v-chip
                                class="ma-2"
                                small
                                style="min-width: 40px; text-align: center; justify-content: center;"
                                text-color="white"
                                :color="`${set_color}`"
                              >
                                {{ item.count }}
                              </v-chip>
                            </v-list-item-action>
                        </v-list-item>

                      </v-list>
                      <v-divider
                        v-if="index < 10 - 1"
                      ></v-divider>
                    </div>
                  </div> 
                </v-skeleton-loader>
              </div>
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    props: {
      
    },
    data () {
      return {
        category: [],
        process: {
          load: false,
          category: false
        },
        message: {
          success: '',
          error: ''
        },
      }
    },
    head () {
      
      
    },
    components: {
      
    },
    watch: {
      
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        console.log(this.$store.state.header.config.color)
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      this.fetchCategory()
    },
    methods: {
      voiceHover (item) {
        responsiveVoice.cancel(); // Cancel anything else that may currently be speaking
        responsiveVoice.speak(item, 'Indonesian Female');
      },
      async fetchCategory () {
        this.process.load = true

        let params = {
          limit: 1000000
        }

        await this.$axios.$get(`${process.env.API}sites/doc/doc_type`, { params })
        .then(response => {
          let res = response
          this.process.load = false

          if (res.status === 200) {

            let selecCategory = []
            selecCategory = res.results.data.map(item => {
              const container = {};
              container.par_id = item.par_id;
              container.name = item.title;
              container.count = item.count;
              container.slug = (item.title.split(' ').join('-')).toLowerCase();
              return container
            })

            for (let i in selecCategory) {
              if(selecCategory[i].par_id !== '0') {
                this.category.push(
                  {
                    name: selecCategory[i].name,
                    slug: selecCategory[i].slug,
                    count: selecCategory[i].count
                  }
                ) 
              }
              
            }
          }
        })
      },
    }
  }
</script>