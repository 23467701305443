<template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 400px; padding-top: 72px;` : `padding-bottom: 610px; padding-top: 64px;`">
    <v-dialog
      persistent
      scrollable
      v-if="dialog.filter"
      v-model="dialog.filter"
      width="450">
      <v-card class="box-shadow" style="border-radius: 6px;">
        <div class="d-flex align-center body-2 px-5 py-5">
          <div style="font-size: 18px; line-height: 21px; color: #505050; mix-blend-mode: normal; opacity: 0.7; font-weight: 900;">
            Filter data
          </div>
          <div class="ml-auto">
            <v-btn
              width="25"
              height="25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="dialog.filter = false;">
              <v-icon size="25" color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div> 
        </div>
        <v-card-text class="pb-5 pt-0" style="min-height: 100px;">
          <div class="mt-0">
            <v-autocomplete
              :items="category"
              :color="`${set_color}`"
              :item-color="`${set_color}`"
              hide-details
              class="mb-4"
              v-model="filter.jenis_bidang"
              item-text="name"
              item-value="slug"
              placeholder="Kategori"
              solo
            ></v-autocomplete>
            <v-select
              :items="category_bidang"
              :color="`${set_color}`"
              :item-color="`${set_color}`"
              hide-details
              class="mb-4"
              clearable
              v-model="filter.category_bidang"
              @click:clear="filter.category_bidang = ''"
              item-text="title"
              item-value="slug"
              placeholder="Kategori"
              solo
            ></v-select>

            <v-text-field
              solo
              hide-details
              clearable
              v-model="filter.nomor"
              @click:clear="filter.nomor = ''"
              :color="`${set_color}`"
              :placeholder="`Nomor`">
            </v-text-field>

            <v-select
              :items="years"
              :color="`${set_color}`"
              :item-color="`${set_color}`"
              hide-details
              v-model="filter.tahun"
              clearable
              @click:clear="filter.tahun = ''"
              class="my-4"
              item-text="name"
              item-value="id"
              placeholder="Tahun"
              solo
            ></v-select>

            <v-text-field
              solo
              hide-details
              clearable
              v-model="filter.title"
              @click:clear="filter.title = ''"
              :color="`${set_color}`"
              :placeholder="`Nama`">
            </v-text-field>


            <div v-if="$route.query.jenis === 'peraturan-desa'">
              <v-select
                :items="kecamatan"
                :color="`${set_color}`"
                :item-color="`${set_color}`"
                hide-details
                clearable
                @click:clear="filter.kecamatan = ''"
                item-text="title"
                item-value="title"
                v-on:change="changeKecamatan()"
                v-model="filter.kecamatan"
                placeholder="Kecamatan"
                solo
              ></v-select>

              <v-select
                :items="desa"
                :color="`${set_color}`"
                :item-color="`${set_color}`"
                hide-details
                clearable
                @click:clear="filter.desa = ''"
                :disabled="filter.kecamatan === '' || filter.kecamatan === null"
                class="my-4"
                item-text="title"
                item-value="title"
                v-model="filter.desa"
                placeholder="Desa/Kelurahan"
                solo
              ></v-select>
            </div>

          </div>
        </v-card-text>
        <v-card-actions class="pa-0">
          <!-- <v-spacer></v-spacer> -->
          <v-btn
            elevation="0"
            :color="set_color"
            block
            height="50"
            @click="changeLinkPage(); dialog.filter = false;"
            class="text-capitalize white--text">
            <div class="d-flex align-center justify-center" style="width: 100%;">
              <v-icon>mdi-check</v-icon>
              <div class="ml-2 fs-18">
                <span>Cari</span>
              </div>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row>
              <v-col cols="12" md="9">
                <div class="d-flex align-center mb-4" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'mt-2' : ''">
                  <div class="font-weight-medium" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                    {{ $route.query.jenis == 'semua-kategori' ? 'SEMUA DOKUMEN' : ($route.query.jenis.split('-').join(' ')).toUpperCase() }}
                  </div>
                  <v-spacer />
                  <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
                    <v-btn
                      :color="`${set_color}`"
                      elevation="0"
                      icon
                      min-width="40"
                      @click="dialog.filter = true"
                      class="text-capitalize px-0"
                      style="border-radius: 6px;"
                      >
                      <v-icon size="20">mdi-filter</v-icon>
                    </v-btn>
                  </div>
                </div>
                <!-- <div>
                  <v-chip
                    class="ma-2"
                    close
                    v-if="dialog.filter === false && selected_filter.title === true && filter.title !== '' && filter.title !== null"
                    text-color="white"
                    :color="set_color"
                    @click:close="filter.title = ''; changeLinkPage()"
                    >
                    Judul: {{ filter.title }}
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    close
                    v-if="dialog.filter === false && selected_filter.tahun === true && filter.tahun !== '' && filter.tahun !== null"
                    text-color="white"
                    :color="set_color"
                    @click:close="filter.tahun = ''; changeLinkPage()"
                    >
                    Tahun: {{ filter.tahun }}
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    close
                    v-if="dialog.filter === false && selected_filter.nomor === true && filter.nomor !== '' && filter.nomor !== null"
                    text-color="white"
                    :color="set_color"
                    @click:close="filter.nomor = ''; changeLinkPage()"
                    >
                    Nomor: {{ filter.nomor }}
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    close
                    v-if="dialog.filter === false && selected_filter.kecamatan === true && filter.kecamatan !== '' && filter.kecamatan !== null"
                    text-color="white"
                    :color="set_color"
                    @click:close="filter.kecamatan = ''; changeLink()"
                    >
                    Kecamatan: {{ filter.kecamatan }}
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    close
                    v-if="dialog.filter === false && selected_filter.desa === true && filter.desa !== '' && filter.desa !== null"
                    text-color="white"
                    :color="set_color"
                    @click:close="filter.desa = ''; changeLink()"
                    >
                    Desa: {{ filter.desa }}
                  </v-chip>
                  <div class="mt-4 text-capitalize font-weight-medium" v-if="dialog.filter === false && selected_filter.category_bidang === true && filter.category_bidang !== '' && filter.category_bidang !== null">
                    <v-chip
                    class="ma-2"
                      close
                      text-color="white"
                      :color="set_color"
                      @click:close="filter.category_bidang = ''; changeLinkPage()"
                      >
                      Bidang: {{ (filter.category_bidang.split('-').join(' ')) }}
                    </v-chip>
                  </div>
                </div> -->
                <div class="font-weight-medium text-h5 mb-4 mt-2" :style="`color: ${set_color}`">
                  {{ title }}
                </div>
                <div class="mb-3">
                  <v-text-field
                    solo
                    hide-details
                    clearable
                    v-model="search"
                    class="bg-input-white"
                    v-on:keyup.enter="changeLinkPage()"
                    @click:clear="search = ''; changeLinkPage();"
                    style="border-radius: 6px 0px 0px 6px !important;"
                    :color="`${set_color}`"
                    :placeholder="`Cari peraturan yang Anda butuhkan . . .`">
                    <template v-slot:prepend-inner>
                      <v-icon class="mr-2">mdi-magnify</v-icon>
                    </template>
                  </v-text-field>
                </div>
                <v-card flat
                  v-for="(item, index) in process.load ? 8 : list"
                  :key="index"
                  style="border-radius: 6px;"
                  class="mb-5">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.load"
                    type="list-item-avatar-three-line">
                    <div>
                      
                      <v-list three-line>
                        <template>
                          <v-list-item>
                            <v-list-item-avatar tile :size="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 80 : 60">
                              <v-img :src="require('@/assets/icon/files.png')"></v-img>
                            </v-list-item-avatar>
  
                            <v-list-item-content>
                              <div @click="toDetail(item)" @mouseover="voiceHover(item.title)" style="cursor: pointer">{{ item.title }}</div>
                              <v-list-item-subtitle>
                                <div>
                                  <span>
                                    <v-icon
                                      size="12">
                                      mdi-file-document
                                    </v-icon>
                                    <span class="me-1 font-small">{{ item.doc_type_title }}</span>
                                  </span>
                                  <span>
                                    <v-icon size="12">mdi-map-marker</v-icon>
                                    <span class="me-1 font-small">{{ item.lokasi }}</span>
                                  </span>
                                  <span class="ml-2" v-if="item.tag !== ''">
                                    <v-icon
                                      size="12">
                                      mdi-tag
                                    </v-icon>
                                    <span class="me-1 font-small">{{ item.category_title }}</span>
                                  </span>
                                  <span class="ml-2">
                                    <v-icon
                                      size="12">
                                      mdi-calendar-range
                                    </v-icon>
                                    <span class="me-1 font-small">Ditetapkan : {{ item.penetapan_date | date }}</span>
                                  </span>
                                  <span class="ml-2" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                                    <v-icon
                                      size="12">
                                      mdi-eye
                                    </v-icon>
                                    <span class="me-1 font-small">Dilihat {{ item.view_count }} Kali </span>
                                  </span>
                                  <span class="ml-2" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                                    <v-icon
                                      size="12">
                                      mdi-download
                                    </v-icon>
                                    <span class="me-1 font-small">Didownload {{ item.download_count }} Kali </span>
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle class="caption" v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
                                <span class="ml-0">
                                  <v-icon
                                    size="12">
                                    mdi-eye
                                  </v-icon>
                                  <span class="me-1 font-small">Dilihat {{ item.view_count }} Kali </span>
                                </span>
                                <span class="ml-2">
                                  <v-icon
                                    size="12">
                                    mdi-download
                                  </v-icon>
                                  <span class="me-1 font-small">Didownload {{ item.download_count }} Kali </span>
                                </span>
                              </v-list-item-subtitle>
                              <div>
                                <v-chip
                                  class="pa-2 font-weight-bold mt-2"
                                  text-color="white"
                                  small
                                  :color="item.status_option === 'Berlaku' ? '#66DA26' : item.status_option === 'Tidak Berlaku' ? '#E91E63' : item.status_option === 'Diubah' ? '#2E93fA' : '#E65100'"
                                  label
                                >
                                  {{ item.status_option }}
                                </v-chip>
                              </div>

                            </v-list-item-content>
                            <!-- <v-list-item-action>
                              <v-chip
                                class="pa-2"
                                text-color="white"
                                x-small
                                color="green"
                                label
                              >
                                {{ item.status }}
                              </v-chip>
                            </v-list-item-action> -->
                          </v-list-item>
                          <v-divider></v-divider>
                          <div class="text-right pa-5">
                            <v-btn
                              style="border-radius: 6px;"
                              elevation="0"
                              @mouseover="voiceHover('Download Abstrak')"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="red"
                              v-if="item.abstrak !== '' || item.abstrak_file !== ''"
                              :loading="process.form"
                              :disabled="process.form"
                              @click="item.abstrak_file !== '' ? downloadFile(item.abstrak_file, getFile(item.abstrak_file).split('/')[5]) : download_abstrak(item)"
                              :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-subtitle-2' : ''"
                              class="text-subtitle-2 text-capitalize font-weight-bold white--text">
                              
                              <v-icon size="20" class="mr-2">mdi-file-document-outline</v-icon>
                              Download Abstrak
                            </v-btn>
                            <v-btn
                              style="border-radius: 6px;"
                              elevation="0"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="green"
                              @mouseover="voiceHover('Download')"
                              :disabled="item.file_url === ''"
                              @click="download(item.id); downloadFile(item.file_url, getFile(item.file_url).split('/')[5])"
                              :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-subtitle-2' : ''"
                              class="text-capitalize font-weight-bold white--text">
                              
                              <v-icon :size="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 20 : 10" class="mr-2">mdi-download</v-icon>
                              Download
                            </v-btn>
                            <v-btn
                              style="border-radius: 6px;"
                              elevation="0"
                              @click="toDetail(item)"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="blue"
                              @mouseover="voiceHover('Lihat Detail')"
                              :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-subtitle-2' : ''"
                              class="text-capitalize font-weight-bold white--text">
                              
                              <v-icon :size="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 20 : 10" class="mr-2">mdi-text-box-search-outline</v-icon>
                              Lihat Detail
                            </v-btn>
                          </div>
                        </template>
                      </v-list>
                    </div>
                  </v-skeleton-loader>

                </v-card>
                <div v-if="!process.load && pagination.total_data > 20 && limit < pagination.total_data"
                  class="text-center mt-4 mb-2">
                  <v-btn
                    @click="limit += 20"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    @mouseover="voiceHover('Lebih Banyak')"
                    :color="set_color"
                    class="text-capitalize"
                    style="opacity: 60%;">
                    Lebih banyak
                  </v-btn>
                </div>

                <Empty v-if="!process.load && list.length < 1"
                  class="py-12"
                  margin="my-16"
                  size="125"
                  message="" />
              </v-col>
              <v-col cols="12" md="3" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                <v-card flat style="border-radius: 6px;">
                  <v-card-text>
                    <div class="font-weight-medium body-1" :style="`color: ${set_color}`">
                      Filter Data
                    </div>
                    <v-divider class="mb-4 mt-2"></v-divider>
                    <v-autocomplete
                      :items="category"
                      :color="`${set_color}`"
                      :item-color="`${set_color}`"
                      hide-details
                      class="mb-4"
                      v-on:change="changeLinkPage()"
                      v-model="filter.jenis_bidang"
                      item-text="name"
                      item-value="slug"
                      placeholder="Kategori / Jenis Dokumen"
                      solo
                    ></v-autocomplete>

                    <v-autocomplete
                      :items="category_bidang"
                      :color="`${set_color}`"
                      :item-color="`${set_color}`"
                      hide-details
                      class="mb-4"
                      clearable
                      v-on:change="changeLinkPage()"
                      v-model="filter.category_bidang"
                      @click:clear="filter.category_bidang = ''; changeLinkPage()"
                      item-text="title"
                      item-value="slug"
                      placeholder="Bidang"
                      solo
                    ></v-autocomplete>

                    <v-text-field
                      solo
                      hide-details
                      clearable
                      class="mb-4"
                      v-on:keydown.enter.prevent="changeLinkPage()"
                      v-model="filter.nomor"
                      @click:clear="filter.nomor = ''; changeLinkPage()"
                      :color="`${set_color}`"
                      :placeholder="`Nomor`">
                    </v-text-field>

                    <v-autocomplete
                      :items="years"
                      :color="`${set_color}`"
                      :item-color="`${set_color}`"
                      hide-details
                      v-model="filter.tahun"
                      clearable
                      v-on:change="changeLinkPage()"
                      @click:clear="filter.tahun = ''; changeLinkPage()"
                      class="my-4"
                      placeholder="Tahun"
                      solo
                    ></v-autocomplete>

                    <!-- <v-text-field
                      solo
                      hide-details
                      clearable
                      type="number"
                      class="mb-4"
                      v-model="filter.tahun"
                      v-on:keydown.enter.prevent="changeLinkPage()"
                      @click:clear="filter.tahun = ''; changeLinkPage()"
                      :color="`${set_color}`"
                      :placeholder="`Tahun`">
                    </v-text-field> -->

                    <v-text-field
                      solo
                      hide-details
                      clearable
                      v-model="filter.title"
                      v-on:change="changeLinkPage()"
                      v-on:keydown.enter.prevent="changeLinkPage()"
                      @click:clear="filter.title = ''; changeLinkPage()"
                      :color="`${set_color}`"
                      :placeholder="`Nama`">
                    </v-text-field>


                    <v-btn
                      style="border-radius: 6px;"
                      elevation="0"
                      block
                      @click="changeLinkPage()"
                      @mouseover="voiceHover('Cari')"
                      height="48"
                      :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                      :color="`${set_color}`"
                      class="text-subtitle-2 text-capitalize font-weight-bold white--text mt-5">
                      
                      <v-icon size="20" class="mr-2">mdi-magnify</v-icon>
                      Cari
                    </v-btn>
                  </v-card-text>
                </v-card>

                <v-card flat style="border-radius: 6px;" v-if="$route.query.jenis === 'peraturan-desa'" class="mt-6">
                  <v-card-text>
                    <div class="font-weight-medium body-1" :style="`color: ${set_color}`">
                      Filter Data Desa
                    </div>
                    <v-divider class="mb-4 mt-2"></v-divider>
                    <v-autocomplete
                      :items="kecamatan"
                      :color="`${set_color}`"
                      :item-color="`${set_color}`"
                      hide-details
                      clearable
                      @click:clear="filter.kecamatan = ''; changeLinkPage()"
                      item-text="title"
                      item-value="title"
                      v-on:change="changeKecamatan(); changeLinkPage()"
                      v-model="filter.kecamatan"
                      placeholder="Kecamatan"
                      solo
                    ></v-autocomplete>

                    <v-autocomplete
                      :items="desa"
                      :color="`${set_color}`"
                      :item-color="`${set_color}`"
                      hide-details
                      clearable
                      @click:clear="filter.desa = ''; changeLinkPage()"
                      :disabled="filter.kecamatan === '' || filter.kecamatan === null"
                      class="my-4"
                      item-text="title"
                      item-value="title"
                      v-model="filter.desa"
                      placeholder="Desa/Kelurahan"
                      solo
                    ></v-autocomplete>


                    <v-btn
                      style="border-radius: 6px;"
                      elevation="0"
                      block
                      @click="changeLinkPage()"
                      height="48"
                      @mouseover="voiceHover('Cari')"
                      :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                      :color="`${set_color}`"
                      class="text-subtitle-2 text-capitalize font-weight-bold white--text mt-5">
                      
                      <v-icon size="20" class="mr-2">mdi-magnify</v-icon>
                      Cari
                    </v-btn>
                  </v-card-text>
                </v-card>

                <Sidebar />

              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import moment from "moment"
  import "moment/locale/id"
  import axios from "axios";
  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          filter: false
        },
        selected_filter: {
          category_bidang: false,
          title: false,
          tahun: false,
          nomor: false,
          jenis_bidang: false,
          kecamatan: false,
          desa: false
        },
        dateYear: false,
        list: [],
        unit: [],
        opd: [],
        kecamatan: [],
        desa: [],
        bidang: [
            {
              icon_url:require('@/assets/icon/education.png'),
              title: "Pendidikan",
              slug: "pendidikan"
            },
            {
              icon_url:require('@/assets/icon/budget.png'),
              title: "Keuangan",
              slug: "keuangan"
            },
            {
              icon_url:require('@/assets/icon/summer-holidays.png'),
              title: "Pariwisata",
              slug: "pariwisata"
            },
            {
              icon_url:require('@/assets/icon/folder (2).png'),
              title: "Kearsipan",
              slug: "kearsipan"
            },
            {
              icon_url:require('@/assets/icon/medical-result.png'),
              title: "Kesehatan",
              slug: "kesehatan"
            },
            {
              icon_url:require('@/assets/icon/infrastructure.png'),
              title: "Infrastruktur",
              slug: "infrastruktur"
            },
            {
              icon_url:require('@/assets/icon/education.png'),
              title: "Pendidikan",
              slug: "pendidikan"
            },
            {
              icon_url:require('@/assets/icon/budget.png'),
              title: "Keuangan",
              slug: "keuangan"
            },
            {
              icon_url:require('@/assets/icon/summer-holidays.png'),
              title: "Pariwisata",
              slug: "pariwisata"
            },
            {
              icon_url:require('@/assets/icon/folder (2).png'),
              title: "Kearsipan",
              slug: "kearsipan"
            },
            {
              icon_url:require('@/assets/icon/medical-result.png'),
              title: "Kesehatan",
              slug: "kesehatan"
            },
            {
              icon_url:require('@/assets/icon/infrastructure.png'),
              title: "Infrastruktur",
              slug: "infrastruktur"
            },
            {
              icon_url:require('@/assets/icon/education.png'),
              title: "Pendidikan",
              slug: "pendidikan"
            },
            {
              icon_url:require('@/assets/icon/budget.png'),
              title: "Keuangan",
              slug: "keuangan"
            },
            {
              icon_url:require('@/assets/icon/summer-holidays.png'),
              title: "Pariwisata",
              slug: "pariwisata"
            },
            {
              icon_url:require('@/assets/icon/folder (2).png'),
              title: "Kearsipan",
              slug: "kearsipan"
            },
            {
              icon_url:require('@/assets/icon/medical-result.png'),
              title: "Kesehatan",
              slug: "kesehatan"
            },
            {
              icon_url:require('@/assets/icon/infrastructure.png'),
              title: "Infrastruktur",
              slug: "infrastruktur"
            }
          ],
        category: [
          {
            name: "Semua Kategori",
            slug: "semua-kategori",
          }
        ],
        category_bidang: [],
        filter: {
          category_bidang: '',
          title: '',
          tahun: '',
          nomor: '',
          jenis_bidang: '',
          kecamatan: '',
          desa: ''
        },
        search: '',
        limit: 20,
        title: "",
        pagination: {
          total_data: 10,
          total_page: 5
        },
        process: {
          load: false,

          limit: false,
          form: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/page',
          title: 'Dokumen Hukum',
          description: 'Dokumen Hukum JDIH Setwan Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty'),
      Sidebar: () => import(/* webpackPrefetch: true */ '@/components/sidebar/index')
    },
    watch: {
      'limit': function() {
        this.process.limit = true
        
        this.fetch('limit')
      },
      '$route.query.jenis': function(val) {
        this.changeLink(val)
      },
      '$route.query.category': function(val) {
        this.changeLink(val)
      },
      '$route.query.search': function(val) {
        this.changeLink(val)
      }
    },

    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        console.log(this.$store.state.header.config.color)
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      },
      years () {
        let max = new Date().getFullYear(), min = max - 200, years = []

        this.filter.year = this.$store.state.year

        for (let i = max; i >= min; i--) {
          years.push({
            text: i,
            value: i
          })
        }

        return years
      },
    },
    beforeMount () {
      // this.changeLink(this.$route.query.jenis)
    },
    mounted () {
      this.fetchCategory()
      this.fetchUnit()
    },
    methods: {
      voiceHover (item) {
        responsiveVoice.cancel(); // Cancel anything else that may currently be speaking
        responsiveVoice.speak(item, 'Indonesian Female');
      },
//       downloadFile(url, fileName) {
//   fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
//     .then(res => res.blob())
//     .then(res => {
//       const aElement = document.createElement('a');
//       aElement.setAttribute('download', fileName);
//       const href = URL.createObjectURL(res);
//       aElement.href = href;
//       aElement.setAttribute('target', '_blank');
//       aElement.click();
//       URL.revokeObjectURL(href);
//     });
// },
      async download (id) {
        
        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}sites/doc/download`, {
          id: id
        })
        .then(response => {
          let res = response
          
          this.process.form = false

          if (res.status === 200) {
            this.fetch('download')
            // this.$emit('snackbar', res.message)
          } else {
            this.message.error = res.message
          }
        })

      },
      getFile(filePath) {
        return filePath.substr(filePath.lastIndexOf('\\') + 1).split('.')[3];
      },

      downloadFile(fileURL, fileName) {
        const link = document.createElement('a');
      link.href = fileURL;
      link.target = '_blank';
      link.download = fileName;

      // Simulate a click on the element <a>
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
        // fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
        //   .then(res => res.blob())
        //   .then(res => {
        //     // console.log(this.getFile(fileName).split('/')[5])
        //     const aElement = document.createElement('a');
        //     aElement.setAttribute('download', fileName);
        //     const href = URL.createObjectURL(res);
        //     aElement.href = href;
        //     aElement.setAttribute('target', '_blank');
        //     aElement.click();
        //     URL.revokeObjectURL(href);
        //   });
      },

      downloadFileAbstrak(fileURL, fileName) {
        const link = document.createElement('a');
      link.href = fileURL;
      link.target = '_blank';
      link.download = fileName;

      // Simulate a click on the element <a>
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
        // fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
        //   .then(res => res.blob())
        //   .then(res => {
        //     // console.log(this.getFile(fileName).split('/')[5])
        //     const aElement = document.createElement('a');
        //     aElement.setAttribute('download', fileName);
        //     const href = URL.createObjectURL(res);
        //     aElement.href = href;
        //     aElement.setAttribute('target', '_blank');
        //     aElement.click();
        //     URL.revokeObjectURL(href);
        //   });
      },

      async fetchCategory (id, type) {
        this.process.load = type === undefined ? true : false

        let params = {
          limit: 1000000
        }

        await this.$axios.$get(`${process.env.API}sites/doc/doc_type`, { params })
        .then(response => {
          let res = response
            
          if (res.status === 200) {
            this.category = [
              {
                name: "Semua Dokumen",
                slug: "semua-dokumen",
                id: ''
              }
            ]

            let selecCategory = []
            selecCategory = res.results.data.map(item => {
              const container = {};
              container.par_id = item.par_id;
              container.id = item.id
              container.name = item.title;
              container.slug = (item.title.split(' ').join('-')).toLowerCase();
              return container
            })

            for (let i in selecCategory) {
              if(selecCategory[i].par_id !== '0') {
                this.category.push(
                  {
                    name: selecCategory[i].name,
                    id: selecCategory[i].id,
                    slug: selecCategory[i].slug
                  }
                ) 
              }
              
            }

            this.fetchCategoryBidang()

            // this.process.load = false
            this.changeLink()
          }
        })
      },
      async fetchCategoryBidang () {
        // this.process.load = true

        // let params = {
        //   limit: 10000,

        //   sort: 'DESC',

        //   cat_type: 'produk'
        // }

        await this.$axios.$get(`${process.env.API}sites/home/category`)
        .then(response => {
          let res = response

          this.process.load = false

          if (res.status === 200) {
            this.category_bidang = res.results.data.map(item => {
              const container = {};
              container.title = item.title;
              container.id = item.id
              container.icon_url = item.icon_url;
              container.slug = (item.title.split(' ').join('-')).toLowerCase();
              return container
            })
          }
          this.filter.jenis_bidang = this.$route.query.jenis
          if (this.$route.query.category !== undefined) {
            this.filter.category_bidang = this.$route.query.category.split('-').join(' ')
            this.selected_filter= {
              category_bidang: this.filter.category_bidang === '' || this.filter.category_bidang === null ? false : true,
              title: this.filter.title === '' || this.filter.title === null ? false : true,
              tahun: this.filter.tahun === '' || this.filter.tahun === null ? false : true,
              nomor: this.filter.nomor === '' || this.filter.nomor === null ? false : true,
              jenis_bidang: this.filter.jenis_bidang === '' || this.filter.jenis_bidang === null ? false : true,
              kecamatan: this.filter.kecamatan === '' || this.filter.kecamatan === null ? false : true,
              desa: this.filter.desa === '' || this.filter.desa === null ? false : true
            }
          }

          // if () {

          // }
        })
      },
      async fetch (text) {
        this.process.load = this.process.limit || text !== undefined ? false : true

        let params = {
          search: this.search,
          limit: this.limit,
          is_publish: 'Publish',

          doc_type_title: this.filter.jenis_bidang === null || this.filter.jenis_bidang === '' || this.filter.jenis_bidang == 'semua-dokumen' ? '' : this.filter.jenis_bidang.split('-').join(' ') ,
          category_title: this.filter.category_bidang === '' || this.filter.category_bidang === null ? '' : this.filter.category_bidang.split('-').join(' '),
          'title[lse]': this.filter.title,
          rule_number: this.filter.nomor,
          tahun: this.filter.tahun === null ? '' : this.filter.tahun,
          kecamatan_name: this.filter.kecamatan === null ? '' : this.filter.kecamatan,
          desa_name: this.filter.desa === null ? '' : this.filter.desa
        }

        await this.$axios.$get(`${process.env.API}sites/doc/data`, { params })
        .then(response => {
          let res = response

          
          this.process.limit = false
          
          if (res.status === 200) {
            this.list = res.results.data
            this.pagination = res.results.pagination
            
          }
          this.process.list = false
          this.process.load = false
          
        })
      },
      async fetchUnit() {
        //  this.process.load = true

         let params = {
          limit: 10000000000000
        }

        await this.$axios.$get(`https://api.klaten.go.id/sites/profile/unit`, { params })
        .then(response => {
          let res = response
          
          if (res.status === 200) {
            this.unit = res.results.data
            this.fetchOPD()
          }
        })
      },
      async fetchOPD() {
        //  this.process.load = true

         let params = {
          limit: 10000000000000,
          type: "OPD"
        }

        await this.$axios.$get(`https://api.klaten.go.id/sites/profile/unit`, { params })
        .then(response => {
          let res = response
          
          if (res.status === 200) {
            this.opd = res.results.data
            this.fetchKecamatan()
          }
        })
      },
      async fetchKecamatan() {
        //  this.process.load = true

         let params = {
          limit: 10000000000000,
          type: "Kecamatan"
        }

        await this.$axios.$get(`https://api.klaten.go.id/sites/profile/unit`, { params })
        .then(response => {
          let res = response
          
          if (res.status === 200) {
            this.kecamatan = res.results.data
            // this.process.load = false
          }
        })
      },
      changeKecamatan () {
        let par_id = ''
        this.kecamatan.map(obj => {
          if (obj.title === this.filter.kecamatan) {
            par_id = obj.id
          }
        })

        this.desa = []

        for(let i in this.unit) {
          if (this.unit[i].par_id === par_id) {
            this.desa.push(this.unit[i])
          }
        }
      },
      async download_abstrak (item) {
        
        this.process.form = true

        await axios({
          url: `${process.env.API}sites/doc/download_abstrak`,
          method: 'POST',
          responseType: 'blob', // important,
          data: {
            id: item.id
          },
          // headers: {
          //   Authorization: process.env.API_SECRET,
          //   token: this.store.state.member.auth.token
          // }
        }).then((response) => {
          let res = response

          // console.log(response)

          // if (res.status === 200) {
            this.process.form = false
            
            // this.dialog.success = true
            // this.message.success = 'Berhasil mengeksport data'

            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            
            link.href = url
            link.setAttribute('download', `Abstrak-${(item.title.split(' ').join('-').split('/').join('-'))}-${moment().format('DD-MMMM-YYYY', 'id')}-${moment().format('HH:mm:ss', 'id')}.pdf`)
            document.body.appendChild(link)
            link.click()
          // } else {
          //   this.process.print = false
          //   this.message.error = res.message
          // }
        })

        // SAVE TO REST API
        // await this.$axios.$post(`${process.env.API}sites/doc/download_abstrak`, {
        //   id: item.id
        // })
        // .then(response => {
        //   let res = response
          
        //   this.process.form = false

        //   const url = window.URL.createObjectURL(new Blob([response]))
        //   const link = document.createElement('a')
        //   link.href = url
        //   link.setAttribute('download', `Abstrak-${(item.title.split(' ').join('-').split('/').join('-'))}-${moment().format('DD-MMMM-YYYY', 'id')}-${moment().format('HH:mm:ss', 'id')}.pdf`)
        //   document.body.appendChild(link)
        //   link.click()
        //   if (res.status === 200) {
        //     // this.$emit('snackbar', res.message)
        //     // this.fetchDetail()
        //   } else {
        //     this.message.error = res.message
        //   }
        // })

      },
      changeLink(query) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        this.category = this.category.map(item => {
          const container = {};

          container.name = item.name;
          container.slug = (item.name.split(' ').join('-')).toLowerCase();
          container.id = item.id

          // this.category = container;
          return container
        })

        this.filter.jenis_bidang = this.$route.query.jenis === undefined ? this.category[0].slug : this.$route.query.jenis
        this.filter.category_bidang = this.$route.query.category === null || this.$route.query.category === undefined ? '' : this.$route.query.category
        this.filter.title = this.$route.query.title === undefined ? '' : this.$route.query.title
        this.filter.tahun = this.$route.query.tahun === undefined ? '' : this.$route.query.tahun*1
        this.filter.nomor = this.$route.query.nomor === undefined ? '' : this.$route.query.nomor
        this.search = this.$route.query.search === undefined ? '' : this.$route.query.search

        this.selected_filter= {
          category_bidang: this.filter.category_bidang === '' || this.filter.category_bidang === null ? false : true,
          title: this.filter.title === '' || this.filter.title === null ? false : true,
          tahun: this.filter.tahun === '' || this.filter.tahun === null ? false : true,
          nomor: this.filter.nomor === '' || this.filter.nomor === null ? false : true,
          jenis_bidang: this.filter.jenis_bidang === '' || this.filter.jenis_bidang === null ? false : true,
          kecamatan: this.filter.kecamatan === '' || this.filter.kecamatan === null ? false : true,
          desa: this.filter.desa === '' || this.filter.desa === null ? false : true
        }

        let status = false
        let id = ''
        
        if (this.$route.query.jenis !== undefined) {
          for(let i = 0; i < this.category.length; i++){
            if (this.category[i].slug === this.$route.query.jenis) {
              this.filter.jenis_bidang = this.category[i].slug
              status = true
            } else {
              // status = false
            }
          }
          // this.filter.jenis_bidang = this.filter.jenis_bidang === '' ? this.$route.query.jenis : this.filter.jenis_bidang
          // if (query === 'wisata') {
          //     this.selected = {
          //       category: 0,
          //     }
          // } else if (query === 'kuliner') {
          //   this.selected = {
          //     category: 1
          //   }
          // }
          // this.fetch(id)
        }
        // console.log(status)
        if (this.$route.query.search === undefined || this.$route.query.jenis === undefined || this.$route.query.category === undefined || this.$route.query.tahun === undefined || this.$route.query.nomor === undefined || this.$route.query.title === undefined) {
          this.$router.replace({'path': '/page', 'query': {'jenis': this.filter.jenis_bidang, 'search': this.search, 'category': this.filter.category_bidang, 'tahun': this.filter.tahun, 'nomor': this.filter.nomor, 'title': this.filter.title}})
          // if(this.$route.query.search === undefined) {
          //   if (this.$route.query.category === undefined) {
          //     this.$router.replace({'path': '/page', 'query': {'jenis': this.category[0].slug}})
          //   } else {
          //     this.$router.replace({'path': '/page', 'query': {'jenis': this.category[0].slug, 'category': this.$route.query.category}})
          //   }
          // } else {
          //   if (this.$route.query.category === undefined) {
          //     this.$router.replace({'path': '/page', 'query': {'jenis': this.category[0].slug, 'search': this.$route.query.search }})
          //   } else {
          //     this.$router.replace({'path': '/page', 'query': {'jenis': this.category[0].slug, 'search': this.$route.query.search , 'category': this.$route.query.category}})
          //   }
          // }
          // this.$router.push(`/page?jenis=${this.category[0].slug}`)
        }
        // this.filter.jenis_bidang = this.$route.query.jenis === undefined ? this.category[0].slug : this.$route.query.jenis

        // if (this.$route.query.jenis !== this.filter.jenis_bidang) {
        //   if(this.$route.query.search === undefined) {
        //     if (this.$route.query.category === undefined) {
        //       this.$router.replace({'path': '/page', 'query': {'jenis': this.filter.jenis_bidang}})
        //     } else {
        //       this.$router.replace({'path': '/page', 'query': {'jenis': this.filter.jenis_bidang, 'category': this.$route.query.category}})
        //     }
        //   } else {
        //     if (this.$route.query.category === undefined) {
        //       this.$router.replace({'path': '/page', 'query': {'jenis': this.filter.jenis_bidang, 'search': this.$route.query.search }})
        //     } else {
        //       this.$router.replace({'path': '/page', 'query': {'jenis': this.filter.jenis_bidang, 'search': this.$route.query.search , 'category': this.$route.query.category}})
        //     }
        //   }
        // }

        // console.log(id)

        this.fetch()
      },
      changeLinkPage(query) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        console.log(this.filter.title)

        this.selected_filter= {
          category_bidang: this.filter.category_bidang === '' || this.filter.category_bidang === null ? false : true,
          title: this.filter.title === '' || this.filter.title === null ? false : true,
          tahun: this.filter.tahun === '' || this.filter.tahun === null ? false : true,
          nomor: this.filter.nomor === '' || this.filter.nomor === null ? false : true,
          jenis_bidang: this.filter.jenis_bidang === '' || this.filter.jenis_bidang === null ? false : true,
          kecamatan: this.filter.kecamatan === '' || this.filter.kecamatan === null ? false : true,
          desa: this.filter.desa === '' || this.filter.desa === null ? false : true
        }

        this.$router.replace({'path': '/page', 'query': {'jenis': this.filter.jenis_bidang, 'search': this.search, 'category': this.filter.category_bidang, 'tahun': this.filter.tahun, 'nomor': this.filter.nomor, 'title': this.filter.title}})


        // console.log(id)

        this.fetch()
      },
      toDetail(item) {
        this.$router.push(`/page/${(item.title.split('-').join('=').split(' ').join('-').split('/').join('>'))}?q=${item.id}`)
      },
      changeFilterLink() {
        // if (this.$route.query.search !== undefined) {
        //   this.$router.replace({'path': '/page', 'query': {'jenis': this.$route.query.jenis, 'search': this.$route.query.search }})
          
        // } else {
        //   this.$router.replace({'path': '/page', 'query': {'jenis': this.$route.query.jenis}})
          
        // }
        // this.filter.category_bidang = ''
        // this.changeLink()
      }
    }
  }
</script>

<style>
.v-list-item--link:before {
    background-color: transparent !important;
}
</style>