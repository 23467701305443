<template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 400px; padding-top: 72px;` : `padding-bottom: 610px; padding-top: 64px;`">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <div class="font-weight-medium mb-2 mt-2" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
              Anggota JDIH
            </div>
            <v-row>

              <v-col cols="12" md="6">
                <v-card flat
                  style="border-radius: 6px;"
                  class="mb-5 pa-5">
                  <div class="text-center mb-3">
                    Daftar Anggota JDIH dari Instansi/OPD
                  </div>
                  <v-skeleton-loader
                    class="ma-auto"
                    v-if="process.load"
                    :loading="process.load"
                    type="table">
                  </v-skeleton-loader>
                  <div v-if="!process.load && list.length > 0" style="overflow-x: scroll;">
                    <table id="table-desa">
                      <tr>
                        <th :style="`background-color: ${set_color}`">No</th>
                        <th :style="`background-color: ${set_color}`">Instansi/OPD</th>
                        <th :style="`background-color: ${set_color}`">Website</th>
                      </tr>
                      <tr v-for="(item, index) in opd" :key="index">
                        <td>{{ index+1 }}</td>
                        <td>{{ item.title }}</td>
                        <td><a :href="item.website" target="blank" :style="`color: ${set_color}`">{{item.website}}</a></td>
                      </tr>
                    </table>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card flat
                  style="border-radius: 6px;"
                  class="mb-5 pa-5">
                  <div class="text-center mb-3">
                    Daftar Anggota JDIH dari Desa
                  </div>
                  <v-skeleton-loader
                    class="ma-auto"
                    v-if="process.load"
                    :loading="process.load"
                    type="table">
                  </v-skeleton-loader>
                  <div v-if="!process.load && list.length > 0">
                    <div class="mb-3">
                      <v-autocomplete
                        :items="kecamatan"
                        :color="`${set_color}`"
                        :item-color="`${set_color}`"
                        hide-details
                        item-text="title"
                        item-value="id"
                        placeholder="Kecamatan"
                        solo
                        return-object
                        v-on:change="changeKecamatan()"
                        v-model="selected.kecamatan"
                      ></v-autocomplete>
                    </div>
                    <div style="overflow-x: scroll;">
                      <div v-if="selected.kecamatan !== '' && desa.length > 0" class="mb-3">
                        Website {{ selected.kecamatan.title }} : <a :href="selected.kecamatan.website" target="blank" :style="`color: ${set_color}`">{{selected.kecamatan.website}}</a>
                      </div>
                      <table id="table-desa" v-if="selected.kecamatan !== '' && desa.length > 0">
                        <tr>
                          <th :style="`background-color: ${set_color}`">No</th>
                          <th :style="`background-color: ${set_color}`">Desa</th>
                          <th :style="`background-color: ${set_color}`">Website</th>
                        </tr>
                        <tr v-for="(item, index) in desa" :key="index">
                          <td>{{ index+1 }}</td>
                            <td>{{ item.title }}</td>
                            <td><a :href="item.website" target="blank" :style="`color: ${set_color}`">{{item.website}}</a></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        selected: {
          kecamatan: ''
        },
        desa: [],
        list: [],
        kecamatan: [],
        opd: [],
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/anggota-jdih',
          title: 'Anggota JDIH',
          description: 'Anggota JDIH Setwan Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch() {
         this.process.load = true

         let params = {
          limit: 10000000000000
        }

        await this.$axios.$get(`https://api.klaten.go.id/sites/profile/unit`, { params })
        .then(response => {
          let res = response
          
          if (res.status === 200) {
            this.list = res.results.data
            this.fetchOPD()
          }
        })
      },
      async fetchOPD() {
         this.process.load = true

         let params = {
          limit: 10000000000000,
          par_id : '0',
          type: "OPD"
        }

        await this.$axios.$get(`https://api.klaten.go.id/sites/profile/unit`, { params })
        .then(response => {
          let res = response
          
          if (res.status === 200) {
            this.opd = res.results.data
            this.fetchKecamatan()
          }
        })
      },
      async fetchKecamatan() {
         this.process.load = true

         let params = {
          limit: 10000000000000,
          par_id : '0',
          type: "Kecamatan"
        }

        await this.$axios.$get(`https://api.klaten.go.id/sites/profile/unit`, { params })
        .then(response => {
          let res = response
          
          if (res.status === 200) {
            this.kecamatan = res.results.data
            this.process.load = false
          }
        })
      },
      changeKecamatan () {
        let par_id = ''

        this.desa = []

        for(let i in this.list) {
          if (this.list[i].par_id === this.selected.kecamatan.id) {
            this.desa.push(this.list[i])
          }
        }
      },
    }
  }
</script>

<style>
  #table-desa {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #table-desa td, #table-desa th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #table-desa tr:nth-child(even){background-color: #f2f2f2;}

  #table-desa tr:hover {background-color: #ddd;}

  #table-desa th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    /* background-color: #04AA6D; */
    color: white;
}
</style>