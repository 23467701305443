<template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 400px; padding-top: 72px;` : `padding-bottom: 610px; padding-top: 64px;`"
    >
    <v-layout wrap>
      <v-flex>
        <section>
          <!-- <v-container>
            <v-row>
              <v-col  
                cols="12">
                <v-card
                  flat
                  class="background-default">
                  <v-card-title 
                    class="text-h4 white--text justify-center mb-2 pt-6"
                    style="font-weight: 500;">
                    VISI dan MISI
                  </v-card-title>

                  <v-card-subtitle 
                    class="body-1 white--text text-center mb-4" 
                    style="opacity: 50%; font-weight: 500;">
                    Mengenal VISI dan MISI Kabupaten Klaten.
                  </v-card-subtitle>
                  
                </v-card>
              </v-col>
            </v-row>

          </v-container> -->
          <v-container>
            <v-row>
              <v-col  
                cols="12" class="py-0">
                <div class="font-weight-medium mb-4 mt-2" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                  Tim Pengelola / Struktur Organisasi JDIH Setwan Kabupaten Klaten
                </div>
                <v-card
                  flat>
                  <div v-if="process.load">
                    <v-card
                      flat
                      height="100%"
                      v-if="process.load"
                      :min-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '100vh' : '100vh'"
                      :max-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '100vh' : '100vh'"
                      color="#00000040">
                      <v-skeleton-loader
                        v-if="process.load"
                        :loading="process.load"
                        transition="scale-transition"
                        :tile="false"
                        class="ma-auto load_card_x_large"
                        type="image"
                        style="width: 100% !important; height: 100% !important; min-height: 100% !important;">
                      </v-skeleton-loader>
                    </v-card>
                  </div>
                  <v-card-text
                    class="body-1 black--text mb-4" v-if="!process.load && Object.keys(detail).length > 0">
                    <div 
                      class="title mb-2">
                      Struktur Tim Pengelola
                    </div>
                    <div>
                      <v-img
                        :src="detail.team_pengelola_url"
                        aspect-ratio="1.5"
                        contain
                        class="elevation-0"
                        style="border-radius: 8px;">
                      </v-img>
                    </div>
                    <div 
                      class="title mb-2 mt-6">
                      Struktur Bagian Hukum
                    </div>
                    <div>
                      <v-img
                        :src="detail.struktur_url"
                        aspect-ratio="1.5"
                        contain
                        class="elevation-0"
                        style="border-radius: 8px;">
                      </v-img>
                    </div>
                    <!-- <Struktur  /> -->
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    components: {
      Struktur: () => import(/* webpackPrefetch: true */ '@/components/profil/struktur.vue'),
    },
  name: "mind-map",
    middleware: false,
    data () {
      return {
        // detail: false,
        detail: {},
        mindMap: {
        id:"2",
          job_desc:"Direktur PT CDN Indonesia",
          par_id:"0",
          title:"Dinas Daerah",
          unit_id:"1",
          unit_name:"Head Office",
          children: [
            {
              id:"7",
              job_desc:"Dinas Kesehatan",
              par_id:"2",
          link: "https://dinkes.klaten.go.id/",
              title:"Dinas Kesehatan",
              unit_id:"1",
              unit_name:"Head Office"
              
            },
            {
              id:"7",
              job_desc:"Dinas Pendididkan",
              par_id:"2",
              title:"Dinas Pendididkan",
          link: "https://dinkes.klaten.go.id/",
              unit_id:"1",
              unit_name:"Head Office"
            },
            {
              id:"7",
              job_desc:"Dinas Kebudayaan",
              par_id:"2",
              title:"Dinas Kebudayaan",
          link: "https://dinkes.klaten.go.id/",
              unit_id:"1",
              unit_name:"Head Office"
            }
          ]
      },
      treeConfig: { nodeWidth: 300, nodeHeight: 80, levelHeight: 200 },
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/struktur-organisasi',
          title: 'Tim Pengelola',
          description: 'Tim Pengelola JDIH Setwan Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true
        await this.$axios.$get(`${process.env.API}sites/config`)
        .then(response => {
          let res = response

          this.detail = res.results.data[0]

          this.process.load = false
        })
      },
    }
  }
</script>

