<template>
  <div
    class="fill-height"
    style="padding-bottom: 400px; padding-top: 72px;">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row>
              <v-col cols="12" md="9">
                <div class="mb-4 d-flex align-center text-second body-1">
                  <v-btn text to="/buku" class="text-capitalize px-1 body-1" color="blue">
                    Koleksi Buku
                  </v-btn>
                  <div class="mr-3 ml-1">
                    .|.
                  </div>
                  <div>
                    Detail Koleksi Buku
                  </div>
                </div>
                <v-card flat class="pa-5">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-img :src="`https://jdih.batangkab.go.id/src/web/buku/penggolongan-dan-kodefikasi-barang-milik-daerah.jpg`"></v-img>
                      </v-col>
                      <v-col cols="12" md="9">
                        <div class="title mb-3">
                          Penggolongan Dan Kodefikasi Barang Milik Daerah
                        </div>
                        <table>
                          <tr>
                            <td class="pb-3">Kategori</td>
                            <td class="pb-3 pl-2">: Pemerintahan</td>
                          </tr>
                          <tr>
                            <td class="pb-3">Penerbit</td>
                            <td class="pb-3 pl-2">: Duta Nusindo</td>
                          </tr>
                          <tr>
                            <td class="pb-3">Pengarang</td>
                            <td class="pb-3 pl-2">: No Name</td>
                          </tr>
                          <tr>
                            <td class="pb-3">Lokasi & Tahun</td>
                            <td class="pb-3 pl-2">: Semarang - 2017</td>
                          </tr>
                        </table>
                        <div class="">
                          Deskripsi :
                        </div>
                        <div>
                          Penggolongan Dan Kodefikasi Barang Milik Daerah
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider class="my-5"></v-divider>
                  <v-avatar 
                    v-for="(item, index) in sosmed"
                    :key="index"
                    tile
                    width="50"
                    height="50">
                    <ShareNetwork
                      :network="item.network"
                      :url="`${host}${$route.path}`"
                      :title="`Kabupaten Klaten Raih Kembali Predikat “Kabupaten Peduli HAM`"
                      :description="`Kabupaten Klaten Raih Kembali Predikat “Kabupaten Peduli HAM`"
                      hashtags="JDIH,diskominfo">
                      <v-img
                        contain
                        width="40"
                        height="40"
                        alt="JDIH"
                        :src="item.icon">
                      </v-img>
                    </ShareNetwork>
                  </v-avatar>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <Sidebar />
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        dateNow: new Date(),
        detail: {},
        sosmed: [
          { network: 'facebook', icon: require('@/assets/sosmed/ic_share_fb.svg') },
          { network: 'instagram', icon: require('@/assets/sosmed/ic_share_ig.svg') },
          { network: 'twitter', icon: require('@/assets/sosmed/ic_share_tw.svg') },
          { network: 'telegram', icon: require('@/assets/sosmed/ic_share_tel.svg') },
          { network: 'whatsapp', icon: require('@/assets/sosmed/ic_share_wa.svg') }
        ],
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/buku/:slug',
          title: 'Detail Berita',
          description: 'Detail Berita JDIH Setwan Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
        Sidebar: () => import(/* webpackPrefetch: true */ '@/components/sidebar/index')

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      },
      host () {
        return process.env.HOST_NAME
      }
    },
    mounted () {
      // this.fetchDetail()
    },
    methods: {
      async fetchDetail () {
        this.process.load = true

        let params = {
          slug: this.$route.params.slug
        }

        await this.$axios.$get(`${process.env.API}post/detail`, { params })
        .then(response => {
          let res = response

          this.process.load = false
            
          if (res.code === 200) {
            this.detail = res.data
          }
        })
      }
    }
  }
</script>