<template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 400px; padding-top: 72px;` : `padding-bottom: 610px; padding-top: 64px;`">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row>
              <v-col cols="12" md="9">
                <div class="mb-4 d-flex align-center text-second" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1' : 'body-2'">
                  <v-btn text to="/page" class="text-capitalize px-1" color="blue">
                    Dokumen
                  </v-btn>
                  <div class="mr-3 ml-1">
                    .|.
                  </div>
                  <div>
                    Detail Dokumen
                  </div>
                </div>
                <div v-if="process.load">
                  <v-card
                    flat
                    height="100%"
                    v-if="process.load"
                    :min-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '100vh' : '100vh'"
                    :max-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '100vh' : '100vh'"
                    color="#00000040">
                    <v-skeleton-loader
                      v-if="process.load"
                      :loading="process.load"
                      transition="scale-transition"
                      :tile="false"
                      class="ma-auto load_card_x_large"
                      type="image"
                      style="width: 100% !important; height: 100% !important; min-height: 100% !important;">
                    </v-skeleton-loader>
                  </v-card>
                </div>
                <v-card flat v-if="!process.load && Object.keys(detail).length > 0" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pa-5' : 'pa-3'">
                  <v-tabs
                    v-model="tab"
                    background-color="transparent"
                    grow
                    style="border-bottom: 1px solid #ddd;"
                    slider-size="3"
                    :color="`${set_color}`"
                  >
                    <v-tabs-slider :color="`${set_color}`"></v-tabs-slider>
                    <v-tab
                      v-for="item in tabItem"
                      :key="item"
                      class="text-capitalize"
                    >
                      {{ item }}
                    </v-tab>
                  </v-tabs>

                  <v-card-text :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'pa-0'" v-if="tab === 0">
                    <div class="title black--text mb-3">
                      {{ detail.title }}
                    </div>
                    <div class="mb-3">
                      <span class="ml-0">
                        <v-icon
                          size="15">
                          mdi-eye
                        </v-icon>
                        <span class="me-1 text-caption">Dilihat {{ detail.view_count }} Kali </span>
                      </span>
                      <span class="ml-2">
                        <v-icon
                          size="15">
                          mdi-download
                        </v-icon>
                        <span class="me-1 text-caption">Didownload {{ detail.download_count }} Kali </span>
                      </span>
                    </div>
                    <!-- <div class="body-2 mt-2 mb-3">
                      Tentang PENDIRIAN PERPUSTAKAAN DESA
                    </div> -->
                    <!-- <v-divider class="my-3"></v-divider> -->
                    <table id="table-detail" v-if="type !== 'peraturan daerah'">
                      <tr>
                        <td>Tipe Dokumen</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.doc_type_title }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Judul</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.title }}
                          </div>
                        </td>
                      </tr>
                      <tr v-if="detail.doc_type_par_id !== '3'">
                        <td><span v-if="detail.doc_type_par_id === '4' || detail.doc_type_par_id === '1'">Nomor Peraturan</span><span v-if="detail.doc_type_par_id === '2'">Nomor Panggil</span></td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.rule_number }}
                          </div>
                        </td>
                      </tr>
                      <tr v-if="detail.doc_type_par_id !== '4'">
                        <td>Tahun Terbit	</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.penetapan_date | year }}
                          </div>
                        </td>
                      </tr>
                      <tr v-if="detail.doc_type_par_id === '4'">
                        <td>Tanggal Bulan Tahun dibacakan</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.penetapan_date | date }}
                          </div>
                        </td>
                      </tr>
                      <tr v-if="detail.doc_type_par_id !== '3' && detail.doc_type_par_id !== '2'"> 
                        <td>Singkatan Jenis/ Bentuk Peraturan	</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.singkatan_jenis }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Bidang</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.bidang_hukum_title }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Kategori Bidang</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.category_title }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Lokasi	</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            <div v-if="detail.lokasi_url === ''">
                              {{ detail.lokasi }}
                            </div>
                            <div v-if="detail.lokasi_url !== ''">
                              <a target="_blank" :href="detail.lokasi_url" :color="set_color">
                                <u>{{ detail.lokasi }}</u>
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>OPD Pemrakarsa	</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.opd_pemrakarsa === '' ? '-' : detail.opd_pemrakarsa }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Tanggal Penetapan	</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.penetapan_date | date }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Tanggal Pengundangan</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.penetapan_date | date }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>TEU Badan/ Pengarang	</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.teu }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Tempat Terbit</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.tempat_terbit }}
                          </div>
                        </td>
                      </tr>
                      <tr v-if="detail.doc_type_par_id !== '1'">
                        <td>Penerbit</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.penerbit }}
                          </div>
                        </td>
                      </tr>
                      <tr v-if="detail.doc_type_par_id === '2'">
                        <td>Deskripsi Fisik</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.deskripsi_fisik }}
                          </div>
                        </td>
                      </tr>
                      <!-- <tr>
                        <td>Penandatangan</td>
                        <td>
                          <span class="pr-3">:</span>
                          LANI DWI REJEKI
                        </td>
                      </tr> -->
                      <tr>
                        <td>Bahasa</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.bahasa }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Subjek</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.subjek }}
                          </div>
                        </td>
                      </tr>
                      <tr v-if="detail.doc_type_par_id !== '2'">
                        <td>Sumber</td>
                        <td>
                          <div class="d-flex">
                            <div>

                              <span class="pr-3">:</span>
                            </div>
                            <div>

                              {{ detail.sumber }}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="detail.doc_type_par_id === '2'">
                        <td>ISBN</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.isbn }}
                          </div>
                        </td>
                      </tr>
                      <tr v-if="detail.doc_type_par_id === '2'">
                        <td>Nomor Induk Buku</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.nomor_induk_buku }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Status Peraturan</td>
                        <td>
                          <span class="pr-3">:</span>
                          <v-chip
                            class="pa-2"
                            text-color="white"
                            small
                            :color="detail.status_option === 'Berlaku' ? '#66DA26' : detail.status_option === 'Tidak Berlaku' ? '#E91E63' : detail.status_option === 'Diubah' ? '#2E93fA' : '#E65100'"
                            label
                          >
                            {{ detail.status_option }}
                          </v-chip>
                        </td>
                      </tr>
                      <tr>
                        <td>Keterangan Status</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            <!-- {{ detail.status }} -->
                            <div v-html="detail.status"></div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="detail.abstrak!== '' || detail.abstrak_file !== ''">
                        <td>Abstrak</td>
                        <td>
                          <span class="pr-3">:</span>
                          <v-btn
                              style="border-radius: 6px;"
                              elevation="0"
                              :small="true"
                              color="green"
                              :loading="process.form"
                              :disabled="process.form"
                              @click="detail.abstrak_file !== '' ? downloadFile(detail.abstrak_file, getFile(detail.abstrak_file).split('/')[5]) : download_abstrak(detail)"
                              :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-subtitle-2' : ''"
                              class="text-subtitle-2 text-capitalize font-weight-bold white--text">
                              
                              <v-icon size="20" class="mr-2">mdi-file-document-outline</v-icon>
                              Download
                            </v-btn>
                        </td>
                      </tr>
                      <tr v-if="detail.doc_type_id === '11'">
                        <td>Kecamatan</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.kecamatan_name }}
                          </div>
                        </td>
                      </tr>
                      <tr v-if="detail.doc_type_id === '11'">
                        <td>Desa</td>
                        <td>
                          <div class="d-flex">
                            <div class="pr-3">:</div>
                            {{ detail.desa_name }}
                          </div>
                        </td>
                      </tr>
                    </table>
                    <!-- <a href="https://s3-link1.pdf" download>Download PDF</a> -->

                    <!-- <v-divider class="my-3"></v-divider> -->
                    <!-- <a href="https://cdn.klaten.go.id/files/4673364/23/09/J9kPlA7rfxNmmNjiAudkK0iHultxpTdiiK13XuWX8yi8HgRu40zglfbEEZZc.pdf" download> -->
                      <v-btn
                        style="border-radius: 6px;"
                        elevation="0"
                        :loading="process.form"
                        :disabled="process.form"
                        @mouseover="voiceHover('Download')"
                        v-if="detail.file_url !== ''"
                        :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                        color="green"
                        @click="download(); downloadFile(detail.file_url, getFile(detail.file_url).split('/')[5])"
                        class="text-subtitle-2 text-capitalize font-weight-bold white--text mt-3">
                        
                        <v-icon size="20" class="mr-2">mdi-download</v-icon>
                        Download
                      </v-btn>
                    <!-- </a> -->
                    <div class="mt-4">
                      <iframe :src="detail.file_url" width="100%" :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '900px' : '500px'" frameborder="0"></iframe>
                    </div>
                  </v-card-text>


                  <v-card-text :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'pa-0'" v-if="tab == 1">
                    <div v-if="detail.abstrak!== '' || detail.abstrak_file !== ''">
                      <v-btn
                        style="border-radius: 6px;"
                        elevation="0"
                        :loading="process.form"
                        :disabled="process.form"
                        @mouseover="voiceHover('Download')"
                        :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                        color="green"
                        @click="detail.abstrak_file !== '' ? downloadFile(detail.abstrak_file, getFile(detail.abstrak_file).split('/')[5]) : download_abstrak(detail)"
                        class="text-subtitle-2 text-capitalize font-weight-bold white--text mt-3">
                        
                        <v-icon size="20" class="mr-2">mdi-download</v-icon>
                        Download
                      </v-btn>
                      <!-- </a> -->
                      <div class="mt-4">
                        <iframe :src="detail.abstrak_file" width="100%" :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '900px' : '500px'" frameborder="0"></iframe>
                      </div>
                    </div>
                    <div v-if="detail.abstrak === '' && detail.abstrak_file === ''">
                      <v-row justify="center" align="center" class="my-15">
                        <v-col cols="12" class="text-center" :class="margin">
                          <v-img 
                            class="mx-auto"
                            contain
                            alt="jdih"
                            :width="150"
                            :height="150"
                            :src="require('@/assets/image/empty.png')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <div class="body-2 font-weight-light text-second my-6">
                            Maaf Dokumen Tidak Terdapat File Abstrak
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>

                  <v-card-text :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'pa-0'" v-if="tab == 2">
                    <div v-if="detail.file_url !== ''">
                      <v-btn
                        style="border-radius: 6px;"
                        elevation="0"
                        :loading="process.form"
                        :disabled="process.form"
                        @mouseover="voiceHover('Download')"
                        v-if="detail.file_url !== ''"
                        :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                        color="green"
                        @click="download(); downloadFile(detail.file_url, getFile(detail.file_url).split('/')[5])"
                        class="text-subtitle-2 text-capitalize font-weight-bold white--text mt-3">
                        
                        <v-icon size="20" class="mr-2">mdi-download</v-icon>
                        Download
                      </v-btn>
                      <!-- </a> -->
                      <div class="mt-4">
                        <iframe :src="detail.file_url" width="100%" :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '900px' : '500px'" frameborder="0"></iframe>
                      </div>
                    </div>

                    <div v-if="detail.file_url === ''">
                      <v-row justify="center" align="center" class="my-15">
                        <v-col cols="12" class="text-center" :class="margin">
                          <v-img 
                            class="mx-auto"
                            contain
                            alt="jdih"
                            :width="150"
                            :height="150"
                            :src="require('@/assets/image/empty.png')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>

                          <div class="body-2 font-weight-light text-second my-6">
                            Maaf Dokumen Tidak Terdapat File Peraturan
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                  <v-divider class="my-5"></v-divider>
                  <v-avatar 
                    v-for="(item, index) in sosmed"
                    :key="index"
                    tile
                    width="50"
                    height="50">
                    <ShareNetwork
                      :network="item.network"
                      :url="`${host}${$route.path}`"
                      :title="detail.title"
                      :description="detail.doc_type_title"
                      hashtags="JDIH,diskominfo, JDIH Setwan Kabupaten Klaten">
                      <v-img
                        contain
                        width="40"
                        height="40"
                        alt="JDIH"
                        :src="item.icon">
                      </v-img>
                    </ShareNetwork>
                  </v-avatar>
                  <v-divider class="my-5"></v-divider>
                  <div class="mt-5">
                    <div class="font-weight-medium mb-5" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'title' : 'body-1'">
                      <span :style="`border-bottom: 3px solid ${set_color}`">Dokumen Hukum Terkait</span>
                    </div>


                    <no-ssr>
                      <swiper 
                        class="swiper"
                        ref="swiper"
                        :options="swiperOption">
                        <swiper-slide 
                          v-for="(item, index) in process.load ? 5 : detail.terkait" :key="index" 
                          class="py-3" 
                          :class="index < 1 ? 'px-4' : index < detail.terkait.length ? 'pr-4' : 'px-4'"
                          style="width: auto;">
                          <v-skeleton-loader
                            class="ma-auto"
                            width="200"
                            :loading="process.load"
                            type="card, article">
                            <div>
                              <v-card v-if="!process.load && detail.terkait.length > 0"
                                style="border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;"
                                @click="toDetail(item)"
                                width="200">
                                <v-img
                                  width="100"
                                  contain
                                  height="80"
                                  alt="diskominfo" 
                                  :src="require('@/assets/icon/files.png')"
                                  class="ma-auto">
                                  <!-- <v-card flat
                                    color="transparent"
                                    class="d-flex align-center pa-3"
                                    style="border-radius: 50px;">
                                    <v-avatar size="20">
                                      <v-img 
                                        alt="diskominfo" 
                                        :src="item.post_extra.createdby.image"
                                        class="ma-auto">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular 
                                              indeterminate 
                                              :color="set_color"
                                              size="15">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-avatar>

                                    <div 
                                      class="caption text-capitalize font-weight-bold one-line white--text pl-2">
                                      {{ item.post_extra.createdby.name }}
                                    </div>
                                  </v-card> -->

                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>

                                <div class="pa-3">
                                  <div class="body-2 font-weight-bold four-line text-center mb-2" style="min-height: 80px; height: 80px;">
                                    {{ item.doc_title }}
                                  </div>

                                  <div class="d-flex align-center mt-1">
                                    <v-icon small color="#212121">mdi-file-document-outline</v-icon>

                                    <span class="caption one-line ml-1">
                                      {{ item.doc_doc_type_title }}
                                    </span>

                                  </div>
                                  <div class="text-center pb-3">
                                    <v-chip
                                      class="ma-2"
                                      text-color="white"
                                      small
                                      :color="item.doc_status_option === 'Berlaku' ? '#66DA26' : item.doc_status_option === 'Tidak Berlaku' ? '#E91E63' : item.doc_status_option === 'Diubah' ? '#2E93fA' : '#FFEB3B'"
                                      label
                                    >
                                      {{ item.doc_status_option }}
                                    </v-chip>
                                  </div>

                                  <!-- <div class="">
                                    <v-chip small class="ma-1 ml-0" style="font-size: 8px;" v-for="(tag, i) in item.tag" :key="i">{{ tag }}</v-chip>
                                  </div> -->

                                  <v-divider class="my-2" style="border: 2px solid #f9f9f9;" />

                                  <div class="caption d-flex align-center" style="opacity: 60%;">
                                    <div>
                                      {{ item.doc_penetapan_date | date }}
                                    </div>

                                    <!-- <v-spacer />

                                    <div>
                                      {{ item.post_create_datetime | time }}
                                    </div> -->
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </v-skeleton-loader>
                        </swiper-slide>
                      </swiper>
                    </no-ssr>

                    <Empty v-if="!process.load && detail.terkait.length < 1"
                      class="py-12"
                      margin="my-16"
                      size="125"
                      message="Dokumen Hukum Terkait" />
                  </div>
                </v-card>

                <v-card flat v-if="!process.load && Object.keys(detail).length < 1" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pa-5' : 'pa-3'">
                  <v-row justify="center" align="center" :class="margin">
                    <v-col cols="12" class="text-center" :class="margin">
                      <v-img 
                        class="mx-auto"
                        contain
                        alt="jdih"
                        :width="size"
                        :height="size"
                        :src="require('@/assets/image/empty.png')">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <div class="body-2 font-weight-light text-second my-6">
                        Maaf Halaman Detail Dokumen Belum Dapat Diakses
                      </div>
                    </v-col>
                  </v-row>
                </v-card>

              </v-col>
              <v-col cols="12" md="3">
                <Sidebar />
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import moment from "moment"
  import "moment/locale/id"
  import axios from "axios";
  export default {
    middleware: false,
    data () {
      return {
        dateNow: new Date(),
        detail: {},
        tab: null,
        tabItem: ['Metadata', 'Abstrak', 'File Peraturan'],
        sosmed: [
          { network: 'facebook', icon: require('@/assets/sosmed/ic_share_fb.svg') },
          { network: 'instagram', icon: require('@/assets/sosmed/ic_share_ig.svg') },
          { network: 'twitter', icon: require('@/assets/sosmed/ic_share_tw.svg') },
          { network: 'telegram', icon: require('@/assets/sosmed/ic_share_tel.svg') },
          { network: 'whatsapp', icon: require('@/assets/sosmed/ic_share_wa.svg') }
        ],
        type: "",
        process: {
          load: false,
          form: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/page/:slug',
          title: this.$route.params.slug.split('-').join(' ').split('=').join('-').split('>').join('/'),
          description: 'Detail Dokumen JDIH Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty'),
      Sidebar: () => import(/* webpackPrefetch: true */ '@/components/sidebar/index')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      },
      host () {
        return process.env.HOST_NAME
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      voiceHover (item) {
        responsiveVoice.cancel(); // Cancel anything else that may currently be speaking
        responsiveVoice.speak(item, 'Indonesian Female');
      },
      async fetch () {
        this.process.load = true

        let params = {
          title: (this.$route.params.slug.split('-').join(' ').split('=').join('-').split('>').join('/'))
        }

        await this.$axios.$get(`${process.env.API}sites/doc/data`, { params })
        .then(response => {
          let res = response

          // this.process.load = false
            
          if (res.status === 200) {
            this.detail = res.results.data[0]
            this.fetchDetail()
            if (res.results.data.length < 1 || res.results.data.length > 1) {
              this.fetchDetailbyID()
            }
            // if (this.detail.doc_type_par_id !== '4') {
            //   this.detail.penetapan_date = new Date(this.detail.penetapan_date).getFullYear()
            // }
          }
        })
      },
      async fetchDetailbyID () {
        this.process.load = true

        let params = {
          id: this.$route.query.q
        }

        await this.$axios.$get(`${process.env.API}sites/doc/data`, { params })
        .then(response => {
          let res = response

          // this.process.load = false
            
          if (res.status === 200) {
            this.detail = res.results.data[0]
            this.fetchDetail()
            if (res.results.data.length < 1 || res.results.data.length > 1) {
              this.process.load = false
            }
            // if (this.detail.doc_type_par_id !== '4') {
            //   this.detail.penetapan_date = new Date(this.detail.penetapan_date).getFullYear()
            // }
          }
        })
      },
      async fetchDetail () {
        this.process.load = true

        let params = {
          id: this.detail.id
        }

        await this.$axios.$get(`${process.env.API}sites/doc/data`, { params })
        .then(response => {
          let res = response

          this.process.load = false
            
          if (res.status === 200) {
            this.detail = res.results.data[0]
            // this.detail = res.results.data
            // if (this.detail.doc_type_par_id !== '4') {
            //   this.detail.penetapan_date = new Date(this.detail.penetapan_date).getFullYear()
            // }
          }
        })
      },
      toDetail(item) {
        this.$router.push(`/page/${(item.doc_title.split('-').join('=').split(' ').join('-').split('/').join('>'))}?q=${item.doc_id}`)
      },
      async download () {
        let form = {
          id: this.detail.id
        }
        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}sites/doc/download`, {
          id: this.detail.id
        })
        .then(response => {
          let res = response
          
          this.process.form = false

          if (res.status === 200) {
            // this.$emit('snackbar', res.message)
            this.fetchDetail()
          } else {
            this.message.error = res.message
          }
        })

      },
      getFile(filePath) {
        return filePath.substr(filePath.lastIndexOf('\\') + 1).split('.')[3];
    },

      downloadFile(fileURL, fileName) {
        const link = document.createElement('a');
      link.href = fileURL;
      link.target = '_blank';
      link.download = fileName;

      // Simulate a click on the element <a>
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
        // fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
        //   .then(res => res.blob())
        //   .then(res => {
        //     // console.log(this.getFile(fileName).split('/')[5])
        //     const aElement = document.createElement('a');
        //     aElement.setAttribute('download', fileName);
        //     const href = URL.createObjectURL(res);
        //     aElement.href = href;
        //     aElement.setAttribute('target', '_blank');
        //     aElement.click();
        //     URL.revokeObjectURL(href);
        //   });
      },
      async download_abstrak (item) {
        
        this.process.form = true

        await axios({
          url: `${process.env.API}sites/doc/download_abstrak`,
          method: 'POST',
          responseType: 'blob', // important,
          data: {
            id: item.id
          },
          // headers: {
          //   Authorization: process.env.API_SECRET,
          //   token: this.store.state.member.auth.token
          // }
        }).then((response) => {
          let res = response

          // console.log(response)

          // if (res.status === 200) {
            this.process.form = false
            
            // this.dialog.success = true
            // this.message.success = 'Berhasil mengeksport data'

            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            
            link.href = url
            link.setAttribute('download', `Abstrak-${(item.title.split(' ').join('-').split('/').join('-'))}-${moment().format('DD-MMMM-YYYY', 'id')}-${moment().format('HH:mm:ss', 'id')}.pdf`)
            document.body.appendChild(link)
            link.click()
          // } else {
          //   this.process.print = false
          //   this.message.error = res.message
          // }
        })

        // SAVE TO REST API
        // await this.$axios.$post(`${process.env.API}sites/doc/download_abstrak`, {
        //   id: item.id
        // })
        // .then(response => {
        //   let res = response
          
        //   this.process.form = false

        //   const url = window.URL.createObjectURL(new Blob([response]))
        //   const link = document.createElement('a')
        //   link.href = url
        //   link.setAttribute('download', `Abstrak-${(item.title.split(' ').join('-').split('/').join('-'))}-${moment().format('DD-MMMM-YYYY', 'id')}-${moment().format('HH:mm:ss', 'id')}.pdf`)
        //   document.body.appendChild(link)
        //   link.click()
        //   if (res.status === 200) {
        //     // this.$emit('snackbar', res.message)
        //     // this.fetchDetail()
        //   } else {
        //     this.message.error = res.message
        //   }
        // })

      },
    }
  }
</script>


<style>
  #table-detail {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #table-detail td, #table-detail th {
    border: 1px solid #ddd;
    padding: 8px;
    border-left: none;
    border-right: none;
  }

  #table-detail tr:nth-child(even){background-color: #f2f2f2;}

  #table-detail tr:hover {background-color: #ddd;}

  #table-detail th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    /* background-color: #04AA6D; */
    color: white;
}
</style>