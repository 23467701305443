<template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 400px; padding-top: 72px;` : `padding-bottom: 610px; padding-top: 64px;`">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <div class="font-weight-medium mb-2 mt-2" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
              Hubungi Kami
            </div>
            <v-card flat
              style="border-radius: 6px;"
              class="mb-5 pa-5">
              <v-row>

                <v-col cols="12" md="5">
                  <div class="mb-3 body-1 font-weight-medium">
                    Sekretariat DPRD Kabupaten Klaten
                  </div>
                  <div class="mb-3">
                    <div class="text-second font-weight-medium">
                      Alamat
                    </div>
                    <div class="black--text font-weight-medium body-1">
                      Jalan Pemuda Nomor 294 Klaten 5724
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="text-second font-weight-medium">
                      Telepon
                    </div>
                    <div class="black--text font-weight-medium body-1">
                      0272-321046
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="text-second font-weight-medium">
                      Email
                    </div>
                    <div class="black--text font-weight-medium body-1">
                      dprdklaten@gmail.com
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="text-second font-weight-medium">
                      Sosial Media
                    </div>
                    <div class="black--text font-weight-medium body-1">
                      <v-avatar 
                        v-for="(item, index) in sosmed"
                        :key="index"
                        tile
                        width="50"
                        height="50">
                        <a
                          :href="item.link"
                          target="blank">
                          <v-img
                            contain
                            width="40"
                            height="40"
                            alt="JDIH"
                            :src="item.icon">
                          </v-img>
                        </a>
                      </v-avatar>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="7">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15814.88063283428!2d110.5916147!3d-7.7131469!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a446d609b2cf7%3A0xeb254c209c69d8e4!2sDPRD%20Kabupaten%20Klaten!5e0!3m2!1sen!2sid!4v1720502363245!5m2!1sen!2sid" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        sosmed: [
          { network: 'website', link: 'https://setwan.klaten.go.id/', icon: require('@/assets/sosmed/world-wide-web.png') },
          { network: 'instagram', link: 'https://www.instagram.com/dprdklaten/', icon: require('@/assets/sosmed/ic_share_ig.svg') },
          { network: 'twitter', link: 'https://x.com/DPRDKABKLATEN', icon: require('@/assets/sosmed/ic_share_tw.svg') }
        ],
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/Hubungi-kami',
          title: 'Hubungi Kami',
          description: 'Kontak JDIH Setwan Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
    },
    methods: {
    }
  }
</script>

<style>
  #table-desa {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #table-desa td, #table-desa th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #table-desa tr:nth-child(even){background-color: #f2f2f2;}

  #table-desa tr:hover {background-color: #ddd;}

  #table-desa th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    /* background-color: #04AA6D; */
    color: white;
}
</style>