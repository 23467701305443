<template>
  <div
    class="fill-height">
    <section class="mind-map-layout">
      <div v-if="process.load">
                  <v-card
                    flat
                    height="100%"
                    v-if="process.load"
                    :min-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '100vh' : '40vh'"
                    :max-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '100vh' : '40vh'"
                    color="#00000040">
                    <v-skeleton-loader
                      v-if="process.load"
                      :loading="process.load"
                      transition="scale-transition"
                      :tile="false"
                      class="ma-auto load_card_x_large"
                      type="image"
                      style="width: 100% !important; height: 100% !important; min-height: 100% !important;">
                    </v-skeleton-loader>
                  </v-card>
                </div>
      <div  v-if="!process.load && list.length > 0">
        <vue-tree
          style="width: 100%;"
          :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? ' height: 150vw;' : ' height: 250vh'"
          :dataset="list"
          :collapse-enabled="false"
          :config="treeConfig"
          linkStyle="straight"
          direction="vertical"
        >
          <template v-slot:node="{ index, node }">
            <v-card
              min-width="250"
              height="300"
              style="box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; border-radius: 10px;"
              :style="`border: 2px solid ${set_color};`">
              <div
                class="py-1"
                style="height: 300px; display: grid; align-items: center;">
                {{ index }}
                <div class="text-center pa-5">
                  <div
                    class="body-2 font-weight-bold black--text three-line text-capitalize mb-1">
                    {{ node.title }}
                  </div>
                  <div
                    class="body-2 font-weight-medium black--text three-line text-capitalize mb-3" style="opacity: 80%;">
                    {{ node.kedudukan }}
                  </div>
                  <v-divider class="mb-3"></v-divider>
                  <v-avatar tile size="120">
                    <v-img
                        width="100%"
                        height="100%"
                        alt="JDIH Setwan Kabupaten Klaten"
                        :src="node.image_url === '' ? require('@/assets/image/profile/profile.png') : node.image_url">
                      </v-img>
                  </v-avatar>

                  <!-- <div
                    class="body-2 black--text"
                    style="opacity: 60%;">
                    {{ node.name }}
                  </div> -->

                  <div
                    class="body-2 black--text mt-2"
                    style="opacity: 60%;">
                    {{ node.user_name }}
                  </div>

                  <div
                    class="body-2 black--text"
                    style="opacity: 60%;">
                    {{ node.nip }}
                  </div>
                </div>
                <!-- <v-list
                  dense
                  style="background: transparent;">
                  <v-list-item
                    class="px-3">
                    <v-list-item-avatar 
                      class="mr-3 mt-2"
                      size="40"
                      color="#FFFFFF"
                      style="border: 2px solid #FFFFFF;"
                      :style="`box-shadow: rgba(${set_color_rgb_custom}, 0.17) 0px -23px 25px 0px inset, rgba(${set_color_rgb_custom}, 0.15) 0px -36px 30px 0px inset, rgba(${set_color_rgb_custom}, 0.1) 0px -79px 40px 0px inset, rgba(${set_color_rgb_custom}, 0.06) 0px 2px 1px, rgba(${set_color_rgb_custom}, 0.09) 0px 4px 2px, rgba(${set_color_rgb_custom}, 0.09) 0px 8px 4px, rgba(${set_color_rgb_custom}, 0.09) 0px 16px 8px, rgba(${set_color_rgb_custom}, 0.09) 0px 32px 16px;`">
                      <span
                        class="body-1 font-weight-bold white--text">
                        {{ node.title | initial }}
                      </span>
                    </v-list-item-avatar>
  
                    <v-list-item-content
                      style="max-width: 150px;">
                      <div
                        class="body-2 font-weight-bold black--text three-line text-capitalize mb-1">
                        {{ node.title }}
                      </div>
  
                      <a
                        class="body-2"
                        :href="node.link"
                        target="_blank"
                        :style="`color: ${set_color}`"
                        style="opacity: 60%;">
                        {{ node.link }}
                    </a>
                    </v-list-item-content>
                  </v-list-item>
                </v-list> -->
              </div>
            </v-card>
          </template>
        </vue-tree>
      </div>

    </section>
  </div>
</template>

<script>
import VueTree from "@ssthouse/vue-tree-chart";

export default {
  name: "mind-map",
  components: { VueTree },
  data() {
    return {
      detail : false,
      dialog: {
          detail: false
        },

        list: [],
      mindMap: {
        id:"2",
          job_desc:"",
          par_id:"0",
          title:"Penanggug Jawab Sekretaris Daerah",
          unit_id:"1",
          name:"Nama Penanggug Jawab Sekretaris Daerah",
          NIK: "xxxxxxxxxxxxxxxxxxx",
          children: [
            {
              id:"7",
              job_desc:"Pengarah Asisten Pemerintahan",
              par_id:"2",
              link: "https://dinkes.klaten.go.id/",
              title:"Pengarah Asisten Pemerintahan",
              unit_id:"1",
              name:"Nama Pengarah Asisten Pemerintahan",
              NIK: "xxxxxxxxxxxxxxxxx",
              children: [
                {
                  id:"3",
                  job_desc:"Ketua Kepala Bagian Hukum",
                  par_id:"7",
                  link: "https://dinkes.klaten.go.id/",
                  title:"Ketua Kepala Bagian Hukum",
                  unit_id:"1",
                  name:"Nama Ketua Kepala Bagian Hukum",
                  NIK: "xxxxxxxxxxxxxxxxx",
                  children: [
                    {
                      id:"8",
                      job_desc:"Sekretaris Kasubag Dokumentasi &amp; Sosialisasi Hukum",
                      par_id:"3",
                      link: "https://dinkes.klaten.go.id/",
                      title:"Sekretaris Kasubag Dokumentasi &amp; Sosialisasi Hukum",
                      unit_id:"1",
                      name:"Nama Sekretaris Kasubag Dokumentasi &amp; Sosialisasi Hukum",
                      NIK: "xxxxxxxxxxxxxxxxx",
                      children: [
                        {
                          id:"10",
                          job_desc:"Anggota",
                          par_id:"8",
                          link: "https://dinkes.klaten.go.id/",
                          title:"Anggota",
                          unit_id:"1",
                          name:"Nama Anggota",
                          NIK: "xxxxxxxxxxxxxxxxx"
                        },
                        {
                          id:"11",
                          job_desc:"Anggota",
                          par_id:"8",
                          link: "https://dinkes.klaten.go.id/",
                          title:"Anggota",
                          unit_id:"1",
                          name:"Nama Anggota",
                          NIK: "xxxxxxxxxxxxxxxxx"
                        },
                        {
                          id:"12",
                          job_desc:"Anggota",
                          par_id:"8",
                          link: "https://dinkes.klaten.go.id/",
                          title:"Anggota",
                          unit_id:"1",
                          name:"Nama Anggota",
                          NIK: "xxxxxxxxxxxxxxxxx"
                        },
                        // {
                        //   id:"13",
                        //   job_desc:"Anggota",
                        //   par_id:"8",
                        //   link: "https://dinkes.klaten.go.id/",
                        //   title:"Anggota",
                        //   unit_id:"1",
                        //   name:"Nama Anggota",
                        //   NIK: "xxxxxxxxxxxxxxxxx"
                        // },
                        // {
                        //   id:"14",
                        //   job_desc:"Anggota",
                        //   par_id:"8",
                        //   link: "https://dinkes.klaten.go.id/",
                        //   title:"Anggota",
                        //   unit_id:"1",
                        //   name:"Nama Anggota",
                        //   NIK: "xxxxxxxxxxxxxxxxx"
                        // }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
      },
      treeConfig: { nodeWidth: 300, nodeHeight: 80, levelHeight: 350 },
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/profil?menu=struktur',
          title: 'Struktur Organisasi',
          description: 'Struktur Organisasi Pejabat Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
    };
  },
  computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = true

         let params = {
          limit: 10000000
        }

        await this.$axios.$get(`${process.env.API}/sites/user_position/data`, { params })
        .then(response => {
          let res = response
          
          if (res.status === 200) {
            this.list = this.changeData(res.results.data)
            this.process.load = false
          }
        })
      },


      changeData (data_array) {
        function filter (array, condition) {
          let result = []

          for (let i = 0; i < array.length; i++) {
            if (condition(array[i])) {
              result.push(array[i])
            }
          }

          return result
        }

        function getChilds (parentKey, items) {
          let subItems = filter(items, function (n) {
            return n.par_id === parentKey
          })

          let result = []

          for (let i = 0; i < subItems.length; i++) {
            let subItem = subItems[i]

            let kids = getChilds(subItem.id, items)
            if (kids.length) {
              subItem.children = kids
            }

            result.push(subItem)
          }

          if (parentKey.length) {
            return result
          } else {
            return result[0]
          }
        }

        const data = data_array

        let richMediaData = getChilds('0', data)

        return richMediaData
      },
    }
};
</script>

<!-- <style>
.mind-map-layout {
  width: 100%;
  height: 100%;
  border: 1px solid #efefef;
}

.tree-container > svg[data-v-56c15873], .tree-container .dom-container[data-v-56c15873] {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50% !important;
    top: 0;
    overflow: visible;
    transform-origin: 0 50%;
}
.tree-container[data-v-56c15873] {
    position: relative;
    overflow: scroll !important;
}
</style> -->
