<template>
  <div>
    <v-app-bar
      app
      fixed
      class="px-2"
      :class="$route.name === '/' || $route.name === 'beranda' ? 'header' : ''"
      :prominent="false"
      :hide-on-scroll="false"
      scroll-target="#scrolling-techniques-4"
      :style="`background-color: #fff`"
      style="height: 111px; z-index: 3; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
      <v-container class="px-0">
        <v-row>
          <v-col 
            class="d-flex align-center pb-0">
            <div>
              <nuxt-link
                to="/beranda"
                class="d-flex align-center">
                <v-img 
                  contain
                  width="120"
                  height="auto"
                  alt="JDIH Setwan Kabupaten Klaten" 
                  :src="require('@/assets/logo/favicon.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </nuxt-link>
            </div>

            <v-spacer />

            <!-- <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">

            </div> -->

            <!-- <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'"> -->
              <div 
                  v-for="(item, index) in menu"
                  :key="index">
  
                  <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' && item.sub === undefined">
                    <v-btn
                      :to="item.link"
                      text
                      class="body-2 text-capitalize px-2"
                      :class="$route.path === '/' ? item.link.split('/')[1] === 'beranda' ? 'font-weight-bold' : '' : $route.path.split('/')[1] === item.link.split('/')[1] ? 'font-weight-bold' : ''"
                      :style="$route.path === '/' ? item.link.split('/')[1] === 'beranda' ?  `color: ${set_color}` : '' : $route.path.split('/')[1] === item.link.split('/')[1] ? `color: ${set_color}` : ''"
                      style="border-radius: 50px;">
                      {{ item.title }}
                    </v-btn>
                  </div>
      
                  <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' && item.sub !== undefined">
                    <v-menu v-if="item.title === 'Tentang Kami'" offset-y :close-on-content-click="true" :v-model="subMenu">
                   
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          v-bind="attrs"
                          v-on="on"
                          @mouseover="voiceHover(item.title)"
                          class="body-2 text-capitalize px-2"
                          :class="$route.path.split('/')[1] === item.link.split('/')[1] ? 'font-weight-bold' : ''"
                          :style="$route.path.split('/')[1] === item.link.split('/')[1] ? `color: ${set_color}` : ``"
                          style="border-radius: 50px;">
                          {{ item.title }}
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-card min-width="280">
                        <div
                          v-if="item.title === 'Dokumen Hukum'">
                          <div
                            v-for="(i, m) in item.sub"
                            :key="m"
                          >
                            <v-menu
                              :nudge-width="80"
                              offset-x open-on-hover
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  text
                                  v-bind="attrs"
                                  v-on="on"
                                  width="100%"
                                  height="48"
                                  class="body-2 text-capitalize px-2"
                                  :class="$route.path.split('/')[1] === item.link.split('/')[1] ? 'font-weight-bold' : ''"
                                  :style="$route.path.split('/')[1] === item.link.split('/')[1] ? `color: ${set_color}` : ``">
                                  <div class="d-flex align-center" style="width: 100%;">
                                    <div>
                                      {{ i.title }}
                                    </div>
                                    <v-spacer></v-spacer>
                                    <div>
                                      <v-icon>mdi-chevron-right</v-icon>
                                    </div>
                                  </div>
                                </v-btn>
                              </template>

                              <v-card>
                                <v-list>
                                  <v-list-item
                                    v-for="(s, k) in i.sub"
                                    :key="k"
                                  >
                                    <v-list-item-title>
                                      <v-hover v-slot="{ hover }">
                                        <div :class="hover ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : ''">
                                          <!-- {{ i.title }} -->
                                          <div
                                            style="color: rgba(0, 0, 0, 0.87)"
                                            @click="toList(s.link); subMenu3 = false"
                                            :class="hover ? 'font-weight-bold' : $route.fullPath === s.link ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : $route.fullPath === s.link ? `color: ${set_color}` : ''" >
                                            {{ s.title }}
                                          </div>
                                        </div>
                                      </v-hover>
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </v-menu>
                          </div>
                        </div>
                        <v-list v-if="item.title !== 'Dokumen Hukum'">
                          <v-list-item
                            v-for="(i, m) in item.sub"
                            :key="m"
                          >
                            <v-list-item-title>
                              <v-hover v-slot="{ hover }">
                                <div :class="hover ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : ''">
                                  <v-btn
                                    style="color: rgba(0, 0, 0, 0.87)"
                                    :to="i.link"
                                    text
                                    @mouseover="voiceHover(i.title)"
                                    block
                                    class="justify-start text-capitalize"
                                    :class="hover ? 'font-weight-bold' : $route.fullPath === i.link ? 'font-weight-bold' : ''" :style="hover ? `color: ${set_color}` : $route.fullPath === i.link ? `color: ${set_color}` : ''" >
                                    {{ i.title }}
                                  </v-btn>
                                </div>
                              </v-hover>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>


                  <v-menu v-if="item.title === 'Dokumen Hukum'" offset-y :close-on-content-click="true" v-model="subMenu3">
                   
                   <template v-slot:activator="{ on, attrs }">
                     <v-btn
                       text
                       v-bind="attrs"
                       v-on="on"
                       @mouseover="voiceHover(item.title)"
                       class="body-2 text-capitalize px-2"
                       :class="$route.path.split('/')[1] === item.link.split('/')[1] ? 'font-weight-bold' : ''"
                       :style="$route.path.split('/')[1] === item.link.split('/')[1] ? `color: ${set_color}` : ``"
                       style="border-radius: 50px;">
                       {{ item.title }}
                       <v-icon>mdi-chevron-down</v-icon>
                     </v-btn>
                   </template>
                   <v-card min-width="280">
                     <div
                       v-if="item.title === 'Dokumen Hukum'">
                       <div
                         v-for="(i, m) in item.sub"
                         :key="m"
                       >
                         <v-menu
                           :nudge-width="80"
                           offset-x open-on-hover
                         >
                           <template v-slot:activator="{ on, attrs }">
                             <v-btn
                               text
                               v-bind="attrs"
                               v-on="on"
                               @mouseover="voiceHover(i.title)"
                               width="100%"
                               height="48"
                               class="body-2 text-capitalize px-2">
                               <div class="d-flex align-center" style="width: 100%;">
                                 <div>
                                   {{ i.title }}
                                 </div>
                                 <v-spacer></v-spacer>
                                 <div>
                                   <v-icon>mdi-chevron-right</v-icon>
                                 </div>
                               </div>
                             </v-btn>
                           </template>

                           <v-card>
                             <v-list>
                               <v-list-item
                                 v-for="(s, k) in i.sub"
                                 :key="k"
                               >
                                 <v-list-item-title>
                                   <v-hover v-slot="{ hover }">
                                     <div :class="hover ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : ''">
                                       <!-- {{ i.title }} -->
                                       <!-- <div
                                         style="color: rgba(0, 0, 0, 0.87)"
                                         @click="toList(s.link); subMenu3 = false"
                                         :class="hover ? 'font-weight-bold' : $route.fullPath === s.link ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : $route.fullPath === s.link ? `color: ${set_color}` : ''" >
                                         {{ s.title }}
                                       </div> -->
                                       <v-btn
                                        style="color: rgba(0, 0, 0, 0.87)"
                                        :to="s.link"
                                        text
                                        @mouseover="voiceHover(s.title)"
                                        @click="subMenu3 = false"
                                        block
                                        class="justify-start text-capitalize"
                                        :class="hover ? 'font-weight-bold' : $route.fullPath === s.link ? 'font-weight-bold' : ''" :style="hover ? `color: ${set_color}` : $route.fullPath === s.link ? `color: ${set_color}` : ''" >
                                        {{ s.title }}
                                      </v-btn>
                                     </div>
                                   </v-hover>
                                 </v-list-item-title>
                               </v-list-item>
                             </v-list>
                           </v-card>
                         </v-menu>
                       </div>
                     </div>
                     <v-list v-if="item.title !== 'Dokumen Hukum'">
                       <v-list-item
                         v-for="(i, m) in item.sub"
                         :key="m"
                       >
                         <v-list-item-title>
                           <v-hover v-slot="{ hover }">
                             <div :class="hover ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : ''">
                               <div
                                 style="color: rgba(0, 0, 0, 0.87)"
                                 @click="toList(i.link)"
                                 :class="hover ? 'font-weight-bold' : $route.fullPath === i.link ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : $route.fullPath === i.link ? `color: ${set_color}` : ''" >
                                 {{ i.title }}
                               </div>
                             </div>
                           </v-hover>
                         </v-list-item-title>
                       </v-list-item>
                     </v-list>
                   </v-card>
                 </v-menu>



                 <v-menu v-if="item.title === 'Informasi'" offset-y :close-on-content-click="true" v-model="subMenu2">
                   
                   <template v-slot:activator="{ on, attrs }">
                     <v-btn
                       text
                       v-bind="attrs"
                       v-on="on"
                       @mouseover="voiceHover(item.title)"
                       class="body-2 text-capitalize px-2"
                       :class="$route.path.split('/')[1] === item.link.split('/')[1] ? 'font-weight-bold' : ''"
                       :style="$route.path.split('/')[1] === item.link.split('/')[1] ? `color: ${set_color}` : ``"
                       style="border-radius: 50px;">
                       {{ item.title }}
                       <v-icon>mdi-chevron-down</v-icon>
                     </v-btn>
                   </template>
                   <v-card min-width="280">
                     <div
                       v-if="item.title === 'Dokumen Hukum'">
                       <div
                         v-for="(i, m) in item.sub"
                         :key="m"
                       >
                         <v-menu
                           :nudge-width="80"
                           offset-x open-on-hover
                         >
                           <template v-slot:activator="{ on, attrs }">
                             <v-btn
                               text
                               v-bind="attrs"
                               v-on="on"
                               width="100%"
                               height="48"
                               class="body-2 text-capitalize px-2"
                               :class="$route.path.split('/')[1] === item.link.split('/')[1] ? 'font-weight-bold' : ''"
                               :style="$route.path.split('/')[1] === item.link.split('/')[1] ? `color: ${set_color}` : ``">
                               <div class="d-flex align-center" style="width: 100%;">
                                 <div>
                                   {{ i.title }}
                                 </div>
                                 <v-spacer></v-spacer>
                                 <div>
                                   <v-icon>mdi-chevron-right</v-icon>
                                 </div>
                               </div>
                             </v-btn>
                           </template>

                           <v-card>
                             <v-list>
                               <v-list-item
                                 v-for="(s, k) in i.sub"
                                 :key="k"
                               >
                                 <v-list-item-title>
                                   <v-hover v-slot="{ hover }">
                                     <div :class="hover ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : ''">
                                       <!-- {{ i.title }} -->
                                       <div
                                         style="color: rgba(0, 0, 0, 0.87)"
                                         @click="toList(s.link); subMenu3 = false"
                                         :class="hover ? 'font-weight-bold' : $route.fullPath === s.link ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : $route.fullPath === s.link ? `color: ${set_color}` : ''" >
                                         {{ s.title }}
                                       </div>
                                     </div>
                                   </v-hover>
                                 </v-list-item-title>
                               </v-list-item>
                             </v-list>
                           </v-card>
                         </v-menu>
                       </div>
                     </div>
                     <v-list v-if="item.title !== 'Dokumen Hukum'">
                       <v-list-item
                         v-for="(i, m) in item.sub"
                         :key="m"
                       >
                         <v-list-item-title>
                           <v-hover v-slot="{ hover }">
                             <div :class="hover ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : ''">
                              <v-btn
                                style="color: rgba(0, 0, 0, 0.87)"
                                :to="i.link"
                                text
                                @mouseover="voiceHover(i.title)"
                                block
                                class="justify-start text-capitalize"
                                :class="hover ? 'font-weight-bold' : $route.fullPath === i.link ? 'font-weight-bold' : ''" :style="hover ? `color: ${set_color}` : $route.fullPath === i.link ? `color: ${set_color}` : ''" >
                                {{ i.title }}
                              </v-btn>
                             </div>
                           </v-hover>
                         </v-list-item-title>
                       </v-list-item>
                     </v-list>
                   </v-card>
                 </v-menu>
                  </div>
              </div>
            <!-- </div> -->

            

            <!-- <script type="text/javascript" src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script> -->
            <v-menu
              transition="slide-x-transition"
              bottom
              right
              offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="body-2 text-capitalize pa-2"
                  style="background: #eee; border-radius: 8px; margin-left: 15px;">
                  Bahasa
                  <v-icon>mdi-chevron-down</v-icon>
              </div>
                <!-- <v-img
                  v-bind="attrs"
                  v-on="on"
                  contain
                  width="32"
                  height="32"
                  alt="kominfoklaten"
                  src="https://cdn-icons-png.flaticon.com/512/8361/8361117.png"
                  class="ma-auto cursor-pointer">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        color="grey">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img> -->
              </template>
    
              <v-list
                class="py-0">
                <v-list-item
                  v-for="country in countries"
                  :key="country.title"
    
                  @click="selected.language = country.title; doTranslation(country.code)" :title="country.title">
                  <v-list-item-content>
                    <div
                      class="d-flex align-center">
                      <div>
                        <v-img
                          :alt="country.altText ? country.altText : `${country.title} flag icon`"
                          :src="
                            country.code !== 'en|id'
                              ? `https://cdn.jsdelivr.net/gh/lewis-kori/vue-google-translate/src/assets/images/flags/__${country.title}.png`
                              : country.flagIconUrl"
                          class="flag">
                        </v-img>
                      </div>
    
                      <span
                        class="ml-1">
                        {{ country.title }}
                      </span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>

        <!-- <v-row v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
          <v-col :cols="3" class="d-flex align-center">
            <v-select
              :items="category"
              :color="`${set_color}`"
              v-model="selected.category"
              :item-color="`${set_color}`"
              hide-details
              item-text="name"
              item-value="slug"
              v-on:change="toFilterCategory()"
              placeholder="Jenis Dokumen"
              solo
            ></v-select>
          </v-col>
          <v-col cols="9">
            <div class="d-flex">
              <v-text-field
                solo
                hide-details
                clearable
                v-model="search"
                v-on:keyup.enter="changeQuery()"
                @click:clear="search = ''; changeQuery();"
                style="border-radius: 6px 0px 0px 6px !important;"
                :color="`${set_color}`"
                :placeholder="`Cari peraturan yang Anda butuhkan . . .`">
                <template v-slot:prepend-inner>
                  <v-icon class="mr-2">mdi-magnify</v-icon>
                </template>
              </v-text-field>
              <v-btn
                  :color="`${set_color}`"
                  height="48"
                  elevation="0"
                  dark
                  @click="changeQuery()"
                  class="text-capitalize"
                  style="border-radius: 0px 6px 6px 0px;"
                >
                <v-icon size="20" class="mr-2">mdi-magnify</v-icon>
                      Cari
                </v-btn>
            </div>
            <v-text-field
              dense
              hide-details
              clearable
              outlined
              :placeholder="`Cari kelas elearning semua Dokumen`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row> -->
      </v-container>
    </v-app-bar>
    <!-- <script type="text/javascript" src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>
    <div id="google_translate_element"></div> -->
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        clipped: false,
        drawer: true,
        subMenu: false,
        subMenu2: false,
        subMenu3: false,
        dialog: {
          auth: false,
          otp: false,
          info: false
        },
        countries: [
          {
            code: "en|id",
            title: "Indonesia",
            flagIconUrl: "https://flagicons.lipis.dev/flags/4x3/id.svg"
          },
          {
            code: "en|en",
            title: "English",
          },
          {
            code: 'en|af',
            title: 'Afrikaans',
          },
          {
            code: 'en|ar',
            title: 'Arabic',
          },
          {
            code: 'en|ko',
            title: 'Korean',
          },
          {
            code: 'en|lt',
            title: 'Lithuanian',
          }
        ],
        selected: {
          auth: 'login',
          category: '',
          language: 'Indonesia',
          title: 'Indonesia',
        },
        search: '',
        category: [
          {
            name: 'Peraturan Daerah',
            data: "76"
          },
          {
            name: 'Keputusan Bupati',
            data: "30"
          },
          {
            name: 'Rancangan Peraturan Daerah',
            data: "21"
          },
          {
            name: 'Produk Hukum Desa',
            data: "21"
          },
          {
            name: 'Abstrak Peraturan Daerah',
            data: "12"
          },
          {
            name: 'MOU dan PKS',
            data: "5"
          },
          {
            name: 'Surat Edaran Bupati',
            data: "9"
          },
          {
            name: 'Propemperda',
            data: "2"
          },

          {
            name: 'Peraturan Bupati',
            data: "15"
          },
          {
            name: 'Instruksi Bupati',
            data: "20"
          },
          {
            name: 'Rancangan Peraturan Bupati',
            data: "33"
          },
          {
            name: 'Naskah Akademis',
            data: "21"
          },
          {
            name: 'Staatsblad',
            data: "5"
          },
          {
            name: 'Surat Edaran Sekda',
            data: "2"
          },
          {
            name: 'Peraturan Terjemahan Resmi',
            data: "76"
          },
        ],
        produkHukum: [
          {
            name: 'Peraturan Daerah',
            data: "76"
          },
          {
            name: 'Keputusan Bupati',
            data: "30"
          },
          {
            name: 'Rancangan Peraturan Daerah',
            data: "21"
          },
          {
            name: 'Abstrak Peraturan Daerah',
            data: "12"
          },

          {
            name: 'Peraturan Bupati',
            data: "15"
          },
          {
            name: 'Instruksi Bupati',
            data: "20"
          },
          {
            name: 'Rancangan Peraturan Bupati',
            data: "33"
          },
          {
            name: 'Produk Hukum Desa',
            data: "12"
          },
          {
            name: 'Staatsblad',
            data: "5"
          },
          {
            name: 'Peraturan Terjemahan Resmi',
            data: "76"
          },
        ],
        menu: [
          {
            title: 'Beranda',
            link: '/beranda'
          },
          {
            title: 'Tentang Kami',
            link: '/visi-misi',
            sub: [
              {
                title: 'Visi dan Misi',
                link: '/visi-misi'
              },
              {
                title: 'Tugas Pokok, Fungsi dan Program Kerja',
                link: '/tugas-pokok-fungsi-dan-program-kerja'
              },
              {
                title: 'Hubungi Kami',
                link: '/hubungi-kami'
              },
              {
                title: 'Anggota JDIH',
                link: '/anggota-jdih'
              },
              {
                title: 'SOP',
                link: '/SOP'
              },
              {
                title: 'Tim Pengelola',
                link: '/struktur-organisasi'
              }
            ]
          },
          // {
          //   title: 'PPID',
          //   link: '/PPID'
          // },
          // {
          //   title: 'Produk Hukum Desa',
          //   link: '/page?category=produk-hukum-desa'
          // },
          {
            title: 'Dokumen Hukum',
            link: '/page',
            sub: []
          },
          // {
          //   title: 'Dokumen Lainnya',
          //   link: '',
          //   sub: [
          //     {
          //       title: 'Naskah Akademis',
          //       link: '/page?category=naskah-akademis'
          //     },
          //     {
          //       title: 'MOU dan PKS',
          //       link: '/page?category=mou-dan-pks'
          //     },
          //     {
          //       title: 'Surat Edaran Bupati',
          //       link: '/page?category=surat-edaran-bupati'
          //     },
          //     {
          //       title: 'Surat Edaran Sekda',
          //       link: '/page?category=surat-edaran-sekda'
          //     },
          //     {
          //       title: 'Propemperda',
          //       link: '/page?category=propemperda'
          //     }
          //   ]
          // },
          {
            title: 'Informasi',
            link: '/berita',
            sub: [
              {
                title: 'Berita',
                link: '/berita'
              },
              {
                title: 'Pengumuman',
                link: '/pengumuman'
              },
              {
                title: 'Video',
                link: '/video'
              },
              {
                title: 'Galeri',
                link: '/galeri'
              },
              {
                title: 'Statistik',
                link: '/statistik'
              }
            ]
          },
          {
            title: 'Pengaduan',
            link: '/pengaduan'
          },
          // {
          //   title: 'Download',
          //   link: '/pariwisata',
          //   sub: [
          //     {
          //       title: 'Dokumen',
          //       link: '/dokumen'
          //     },
          //     {
          //       title: 'Katalog Perda',
          //       link: '/katalog-perda'
          //     },
          //     {
          //       title: 'Katalog Perbup',
          //       link: '/katalog-perbup'
          //     }
          //   ]
          // },
          // {
          //   title: 'Koleksi Buku',
          //   link: '/buku'
          // },
        ]
      }
    },
    head () {
  return {
    script: [
      {
        src: '/bundle.js',
        type: 'text/javascript',
        defer: true,
        body: true
      },
      // {
      //   innerHTML:
      //     function googleTranslateElementInit() {
      //       new google.translate.TranslateElement(
      //         { pageLanguage: 'id', autoDisplay: false },
      //         'app'
      //       );
      //       // console.log(pageLanguage)
      //     }
      //   ,
      //   type: 'text/javascript',
      //   defer: true,
      //   body: true
      // }
    ]
  }
},
    watch: {
      '$route': function() {
        if (this.$route.path.split('/')[1] !== 'page') {
          this.search = ''
          this.selected.category = ''
        }
      },
      '$route.query.jenis': function(val) {
        for(let i = 0; i < this.category.length; i++){
          if (this.category[i].slug === val) {
            this.selected.category = this.category[i].slug
          } else {
            // status = false
          }
        }
      },
      '$route.query.search': function(val) {
        this.search = val
      },
      // 'selected.language': function(val) {
      //   this.selected.title = this.countries.find(country => country.code === val).title
      //   // this.selected.title = val
      // },
    },
    components: {
      
    },
    
    computed: {
      hasClickListener() {
        return Object.keys(this.$listeners).includes('on-country-click');
      },
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      token () {
        return this.$store.state.member.auth.token
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      },

      company () {
        return this.$store.state.header.config
      },
      // role_admin () {
      //   if (this.$route.name === null) {
      //     this.$router.push('/404')
      //   } else {
      //     return this.$route.name.includes('admin')
      //   }
      // }
    },
    mounted () {
      if (!process.server) {
        const script = document.createElement("script");
        script.onload = this.onScriptLoaded;
        script.type = "text/javascript";
        script.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        document.head.appendChild(script);
      }
      // console.log(window.doGTranslate())
      // console.log(this.googleTranslateElementInit())
      const debounce = (fn) => {
        let frame;
        return (...params) => {
          if (frame) { 
            cancelAnimationFrame(frame)
          }
          frame = requestAnimationFrame(() => {
            fn(...params)
          })
        } 
      }

      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY
      }

      document.addEventListener('scroll', debounce(storeScroll), { passive: true })

      storeScroll()

      // this.category = this.category.map(item => {
      //   const container = {};

      //   container.name = item.name;
      //   container.slug = (item.name.split(' ').join('-')).toLowerCase();
      //   container.data = item.data

      //   // this.category = container;
      //   return container
      // })

      this.fetchCategory()

      if(this.$route.query.search !== undefined){
        this.search = this.$route.query.search
      } else {
        this.search = ''
      }

      if(this.$route.path.split('/')[1] !== 'page'){
        this.selected.category = this.category[0].slug
      }

      // console.log(this.$route.path.split('/')[1])
    },
    beforeCreate() {
      
    },
    created() {
      
    },
    methods: {
      googleTranslateElementInit() {
            new google.translate.TranslateElement(
              { pageLanguage: 'id', autoDisplay: false },
              'app'
            );
            // console.log(pageLanguage)
          // }
        
      },
      doTranslation (code) {
        this.googleTranslateElementInit()
        this.selected.title = ''
        
        this.selected.title = this.countries.find(country => country.code === code).title
        
        setTimeout(() => {
          if (typeof window !== 'undefined') {
          
            window.doGTranslate(code)
            
            if (this.hasClickListener) {
              this.$emit('on-country-click')
            }
          }
        }, 1000)
      },
      voiceHover (item) {
        responsiveVoice.cancel(); // Cancel anything else that may currently be speaking
        responsiveVoice.speak(item, 'Indonesian Female');
      },
      async fetchCategory () {
        // this.process.load = true

        let params = {
          limit: 1000000
        }

        await this.$axios.$get(`${process.env.API}sites/doc/doc_type`, { params })
        .then(response => {
          let res = response
            
          if (res.status === 200) {
            this.category = [
              {
                name: "Semua Dokumen",
                slug: "semua-dokumen",
              }
            ]
            let category = []
            category = res.results.data.map(item => {
              const container = {};

              container.title = item.title;
              container.id = item.id;
              container.par_id = item.par_id;
              container.singkatan = item.singkatan
              container.link = `/page?jenis=${(item.title.split(' ').join('-')).toLowerCase()}`;
              return container
            })
            this.menu[2].sub = this.changeData(category)

            let selecCategory = []
            selecCategory = res.results.data.map(item => {
              const container = {};
              container.par_id = item.par_id;
              container.name = item.title;
              container.slug = (item.title.split(' ').join('-')).toLowerCase();
              return container
            })

            for (let i in selecCategory) {
              if(selecCategory[i].par_id !== '0') {
                this.category.push(
                  {
                    name: selecCategory[i].name,
                    slug: selecCategory[i].slug
                  }
                ) 
              }
              
            }




            for(let i = 0; i < this.category.length; i++){
              if (this.category[i].slug === this.$route.query.jenis) {
                this.selected.category = this.category[i].slug
              } else {
                // status = false
              }
            }
          }
        })
      },

      changeData (data_array) {
        function filter (array, condition) {
          let result = []

          for (let i = 0; i < array.length; i++) {
            if (condition(array[i])) {
              result.push(array[i])
            }
          }

          return result
        }

        function getChilds (parentKey, items) {
          let subItems = filter(items, function (n) {
            return n.par_id === parentKey
          })

          let result = []

          for (let i = 0; i < subItems.length; i++) {
            let subItem = subItems[i]

            let kids = getChilds(subItem.id, items)
            if (kids.length) {
              subItem.sub = kids
            }

            result.push(subItem)
          }

          if (parentKey.length) {
            return result
          } else {
            return result[0]
          }
        }

        const data = data_array

        let richMediaData = getChilds('0', data)

        return richMediaData
      },
      changeQuery() {
        this.$router.replace({'path': '/page', 'query': {'jenis': this.selected.category === '' ? 'semua-dokumen' : this.selected.category, 'search': this.search === null ? '' : this.search , 'category': this.$route.query.category === undefined ? '' : this.$route.query.category, 'tahun': this.$route.query.tahun === undefined ? '' : this.$route.query.tahun, 'nomor': this.$route.query.nomor === undefined ? '' : this.$route.query.nomor, 'title': this.$route.query.title === undefined ? '' : this.$route.query.title}})
      },
      toFilterCategory() {
        this.$router.replace({'path': '/page', 'query': {'jenis': this.selected.category === '' ? 'semua-dokumen' : this.selected.category, 'search': this.search === null ? '' : this.search , 'category': this.$route.query.category === undefined ? '' : this.$route.query.category, 'tahun': this.$route.query.tahun === undefined ? '' : this.$route.query.tahun, 'nomor': this.$route.query.nomor === undefined ? '' : this.$route.query.nomor, 'title': this.$route.query.title === undefined ? '' : this.$route.query.title}})
      },
      toList(link) {
        this.$router.push(`${link}`)
      },
      goTo (item) {
        if (item.type === 'logout') {
          Cookie.remove('token')
          Cookie.remove('user')
          Cookie.remove('fcm')
          Cookie.remove('notif')
          
          window.location = '/admin/auth/login'
        } else {
          this.$router.push('/admin/profil')
        }
      },
      goBack () {
        if (this.$route.name === 'reservasi-slug') {
          this.$router.push('/reservasi')
        } else if (this.$route.name === 'berita-slug') {
          this.$router.push('/berita')
        } else {
          this.$router.go(-1)
        }
      }
    }
  }
</script>

<style>
  .header {
    /* background-color: transparent !important; */
  }
  .header-default {
    background-color: #a20000 !important;
  }
  .header-detail {
    background-color: transparent !important;
  }
  html:not([data-scroll="0"]) .header {
    /* background: url(@/assets/background/bg_basic.png); */
    /* background-color: #00000040 !important; */
    /* background-color: #a20000 !important; */
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: cover;
    /* opacity: 80% !important; */
  }
  .header .menu {
    /* color: #FFFFFF !important; */
    background-color: transparent !important;
    /* opacity: 60%; */
  }
  
  .sub .v-list-item {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #ffffff !important;
  box-shadow: none !important;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  margin-bottom: 0px !important;
}

.theme--light.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #ffffff !important;
  /* border: 0.25px solid #4ab762; */
  border-width: 0.25px !important;
  border-radius: 6px;
  margin-bottom: 0px !important;
}

.theme--light.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #ffffff !important;
  /* border: 0.25px solid #4ab762; */
  border-width: 0.25px !important;
  border-radius: 6px;
  margin-bottom: 0px !important;
}

.error_input_border.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #ffffff !important;
  border: 0.25px solid red !important;
  border-radius: 6px;
  margin-bottom: 0px !important;
}

.input-border-right-none.v-input--is-focused > .v-input__control > .v-input__slot {
  border-radius: 6px 0px 0px 6px !important;
}

.border-input-none.v-input--is-focused > .v-input__control > .v-input__slot {
  border-width: none !important;
}

.border-input-none > .v-input__control > .v-input__slot {
  border: none !important;
}

.border-input-none > .v-input__control > .v-input__slot > .v-input__prepend-inner{
  opacity: 0.6;
}

.border-input-none > .v-input__control > .v-input__slot > .v-text-field__slot{
  opacity: 0.6;
}

.input-border-right-none > .v-input__control > .v-input__slot {
  border-radius: 6px 0px 0px 6px !important;
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
  background: #FFFFFF !important;
  box-shadow: none !important;
  border-radius: 6px !important;
  margin-bottom: 0px !important;
} 
</style>
