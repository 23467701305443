<template>
  <div
    class="fill-height"
    style="padding-bottom: 400px; padding-top: 72px;">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row>
              <v-col cols="12" md="9">
                <div class="font-weight-medium text-h5 mb-4 mt-2" :style="`color: ${set_color}`">
                  Koleksi Buku
                </div>
                <v-card flat
                  v-for="(item, index) in process.load ? 8 : list"
                  :key="index"
                  style="border-radius: 6px;"
                  class="mb-5">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.load"
                    type="list-item-avatar-three-line">
                    <div>
                      
                      <v-list three-line>
                        <template>
                          <v-list-item>
                            <v-list-item-avatar tile style="width: 150px; height: 180px;">
                              <v-img :src="item.image_url"></v-img>
                            </v-list-item-avatar>
  
                            <v-list-item-content>
                              <v-list-item-title>{{ item.title }}</v-list-item-title>
                              <v-list-item-subtitle>
                                <table>
                                  <tr>
                                    <td class="pb-3">Kategori</td>
                                    <td class="pb-3 pl-2">: Pemerintahan</td>
                                  </tr>
                                  <tr>
                                    <td class="pb-3">Penerbit</td>
                                    <td class="pb-3 pl-2">: Duta Nusindo</td>
                                  </tr>
                                  <tr>
                                    <td class="pb-3">Pengarang</td>
                                    <td class="pb-3 pl-2">: No Name</td>
                                  </tr>
                                  <tr>
                                    <td class="pb-3">Lokasi & Tahun</td>
                                    <td class="pb-3 pl-2">: Semarang - 2017</td>
                                  </tr>
                                </table>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-chip
                                class="pa-2"
                                text-color="white"
                                x-small
                                color="green"
                                label
                              >
                                Tersedia
                              </v-chip>
                            </v-list-item-action>
                          </v-list-item>
                          <v-divider></v-divider>
                          <div class="text-right pa-5">
                            <v-btn
                              style="border-radius: 6px;"
                              elevation="0"
                              :to="`/buku/${item.title}`"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="blue"
                              class="text-subtitle-2 text-capitalize font-weight-bold white--text">
                              
                              <v-icon size="20" class="mr-2">mdi-text-box-search-outline</v-icon>
                              Lihat Detail
                            </v-btn>
                          </div>
                        </template>
                      </v-list>
                    </div>
                  </v-skeleton-loader>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card flat style="border-radius: 6px;">
                  <v-card-text>
                    <div class="font-weight-medium body-1" :style="`color: ${set_color}`">
                      Filter Data
                    </div>
                    <v-divider class="mb-4 mt-2"></v-divider>

                    <v-text-field
                      solo
                      hide-details
                      clearable
                      :color="`${set_color}`"
                      :placeholder="`Judul`">
                    </v-text-field>


                    <v-btn
                      style="border-radius: 6px;"
                      elevation="0"
                      block
                      height="48"
                      :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                      :color="`${set_color}`"
                      class="text-subtitle-2 text-capitalize font-weight-bold white--text mt-5">
                      
                      <v-icon size="20" class="mr-2">mdi-magnify</v-icon>
                      Cari
                    </v-btn>
                  </v-card-text>
                </v-card>

                <Sidebar />
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        list: [
          {
            title: "Penggolongan Dan Kodefikasi Barang Milik Daerah",
            author: "Pemerintah",
            image_url: "https://jdih.batangkab.go.id/src/web/buku/penggolongan-dan-kodefikasi-barang-milik-daerah.jpg"
          },
          {
            title: "Hak Uji Materiil",
            author: "Pemerintah",
            image_url: "https://jdih.batangkab.go.id/src/web/buku/hak-uji-materiil.jpg"
          },
          {
            title: "Hukum Acara Peradilan Agama di Indonesia",
            author: "Pemerintah",
            image_url: "https://jdih.batangkab.go.id/src/web/buku/hukum-acara-peradilan-agama-di-indonesia.jpg"
          },
          {
            title: "Hukum Acara Perdata",
            author: "Pemerintah",
            image_url: "https://jdih.batangkab.go.id/src/web/buku/hukum-acara-perdata.jpg"
          }
        ],
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/buku',
          title: 'Koleksi Buku',
          description: 'Koleksi Buku JDIH Setwan Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
        Sidebar: () => import(/* webpackPrefetch: true */ '@/components/sidebar/index')

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
    },
    methods: {
    }
  }
</script>