<template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 400px; padding-top: 72px;` : `padding-bottom: 610px; padding-top: 64px;`">
    <v-dialog
      v-model="dialog.video"
      v-if="dialog.video"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="pa-0 rounded-0" style="background-color: rgb(33, 33, 33); border-color: rgb(33, 33, 33);">
        <v-toolbar
          style="background-color: transparent; box-shadow: none"
        >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="selected.video = {}; dialog.video = false"
            >
              <v-icon size="40">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="text-center" style="padding-top: calc(100vh - 96vh);">
          <iframe
            :src="`https://www.youtube.com/embed/${selected.video.id.videoId}`"
            frameborder="0" allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? `height: 300px; width: 90%;` : `height: calc(100vh - 200px); width: calc(100vw - 200px);`">
          </iframe>
        </div>
      </v-card>
    </v-dialog>
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row>
              <v-col cols="12" md="9">
                <div class="font-weight-medium mb-4 mt-2" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                  Video
                </div>
                <v-row>
                  <v-col cols="12" md="6"
                    v-for="(item, index) in process.load ? 8 : list" 
                    :key="index">
                    <v-skeleton-loader
                      :loading="process.load"
                      transition="scale-transition"
                      :tile="false"
                      class="ma-auto"
                      type="card">
                      <div>
                        <div v-if="!process.load && list.length > 0">
                          <v-card
                            class="mx-4 mb-4 mt-0"
                            elevation="0"
                            @mouseover="voiceHover(item.snippet.channelTitle)"
                            max-width="400"
                          >
                            <v-img
                              class="white--text align-center rounded-xl cursor-pointer"
                              height="250"
                              @click="openVideo(item)"
                              :src="`https://img.youtube.com/vi/${ item.id.videoId }/hqdefault.jpg`"
                            >
                              <!-- <v-card-title>{{ item.title }}</v-card-title> -->
                              <div class="d-flex align-center justify-center font-weight-black">
                              <div class="text-center">
                                <v-icon size="80" color="#FFFFFF">mdi-play-circle</v-icon> 
                                <!-- <div class="white--text text-h5">
                                  Preview Kelas
                                </div> -->
                              </div>
                            </div>
                            </v-img>

                            <v-card-text class="text--primary pb-0">
                              <div>{{ item.snippet.channelTitle }}</div>
                            </v-card-text>
                            <v-card-subtitle class="">
                              <!-- {{ item.description }} -->
                              <span class="line-text-second" style="white-space: pre-line;" v-html="item.snippet.description"> </span>
                            </v-card-subtitle>
                          </v-card>
                        </div>
                      </div>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>
                <div v-if="!process.load && pagination.total_data > 20 && limit < pagination.total_data"
                  class="text-center mt-4 mb-2">
                  <v-btn
                    @click="limit += 20"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    @mouseover="voiceHover('Lebih Banyak')"
                    :color="set_color"
                    class="text-capitalize"
                    style="opacity: 60%;">
                    Lebih banyak
                  </v-btn>
                </div>
    
                <Empty v-if="!process.load && list.length < 1"
                  class="py-12"
                  margin="my-16"
                  size="125"
                  message="Video" />
                  
              </v-col>
              <v-col cols="12" md="3">
                <Sidebar />
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    props: {
    },
    data () {
      return {
        search: '',
        dialog: {
          video: false
        },
        selected: {
          video: {}
        },
        list: [],
        pagination: {},

        limit: 20,

        process: {
          load: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/video',
          title: 'Video',
          description: 'Video kabupaten klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty'),
      Sidebar: () => import(/* webpackPrefetch: true */ '@/components/sidebar/index')
    },
    watch: {
      'limit': function() {
        this.process.limit = true
        
        this.fetch('limit')
      },
      'category': function() {
        this.process.load = true
        this.fetch()
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        console.log(this.$store.state.header.config.color)
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      voiceHover (item) {
        responsiveVoice.cancel(); // Cancel anything else that may currently be speaking
        responsiveVoice.speak(item, 'Indonesian Female');
      },
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        this.process.load = true

        await this.$axios.$get(`${process.env.API}sites/home/youtube?max_result=1000000000`)
        .then(response => {
          let res = response

          // this.process.load = false
            
          if (res.status === 200) {
            // console.log(res.results.data.items)
            this.list = res.results.data !== null ? res.results.data.items : []
            this.process.load = false

            // this.list.data = res.data
            // this.pagination = res.pagination
          }
        })
      },
      openVideo(data) {
        this.selected.video = {}
        this.selected.video = data
        this.dialog.video = true
      },
    }
  }
</script>