<template>
    <div
      class="fill-height"
      :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 400px; padding-top: 72px;` : `padding-bottom: 610px; padding-top: 64px;`">
      <v-layout wrap>
        <v-flex>
          <section>
            <v-container>
              <v-row>
                <v-col cols="12" md="9">
                  <div class="font-weight-medium mb-4 mt-2" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                    Galeri
                  </div>
  
                    <!-- <viewer
              ref="viewer"
              :options="options"
              :images="images"
              class="viewer"
              @inited="inited"
            >
              <template #default="scope">
                <figure class="images">
                  <div v-for="{source, thumbnail, alt} in scope.images" :key="source" class="image-wrapper">
                    <img
                      class="image"
                      :src="thumbnail"
                      :data-source="source"
                      :alt="alt"
                    >
                  </div>
                </figure>
                <p><strong>Options: </strong>{{ scope.options }}</p>
              </template>
            </viewer> -->
  
                  <div v-viewer="options" class="images clearfix">
                    <v-row>
                      <v-col cols="6" md="4"
                        v-for="(item, source) in list"
                        :key="source">

                        <v-skeleton-loader
                          :loading="process.load"
                          transition="scale-transition"
                          :tile="false"
                          class="ma-auto"
                          type="card">
                          <div>
                            <div v-if="!process.load && list.length > 0"
                              class="border-radius">
                              <v-card
                                style="border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;"
                                @mouseover="voiceHover(item.desc)"
                                width="100%">
                                <img
                                  :src="item.image_url"
                                  class="image"
                                  :data-source="item.image_url"
                                  :alt="item.desc"
                                  style="width: 100%; height: 200px;"
                                >
                                <!-- <v-img
                                  contain
                                  :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'  ? 130 : 250"
                                  alt="diskominfo" 
                                  :src="item.image_url"
                                  :data-source="item.image_url"
                                  class="ma-auto">

                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        indeterminate 
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img> -->

                                <div class="pa-3">
                                  <div class="body-2 font-weight-bold three-line" style="min-height: 60px; height: 60px; max-height: 60px;">
                                    {{ item.desc }}
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </v-skeleton-loader>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12" md="3">
                  <Sidebar />
                </v-col>
              </v-row>
            </v-container>
          </section>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
    import { createSEOMeta } from '@/utils/seo'
    export default {
      middleware: false,
      props: {
      },
      data () {
        return {
          options: {
            movable: false,
            zoomable: false,
            url: 'data-source',
          },
          list: [],
          show: false,
          search: '',
          dialog: {
            video: false
          },
          selected: {
            video: {}
          },
          pagination: {},
  
          limit: 20,
  
          process: {
            load: false,
  
            limit: false
          },
          message: {
            success: '',
            error: ''
          },
          // SEO
          content: {
            url: '/galeri',
            title: 'Galeri',
            description: 'Galeri JDIH Setwan Kabupaten Klaten',
            image: require('@/assets/logo/favicon.png')
          }
        }
      },
      head () {
        return {
          title: this.content.title,
          meta: createSEOMeta(this.content)
        }
      },
      components: {
        Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty'),
        Sidebar: () => import(/* webpackPrefetch: true */ '@/components/sidebar/index')
      },
      watch: {
        'limit': function() {
          this.process.limit = true
          
          this.fetch('limit')
        },
      },
      computed: {
        rps () {
          return this.$vuetify.breakpoint.name
        },
        fcm () {
          return this.$store.state.member.auth.fcm
        },
        isDay () {
          let hours = (new Date()).getHours(),
              day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
          return day
        },
        set_color () {
          console.log(this.$store.state.header.config.color)
          return this.$store.state.header.config.color
        },
        set_color_rgb () {
          let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))
  
          return `rgba(${r}, ${g}, ${b}, 0.97)`
        },
        set_color_rgb_up () {
          let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))
  
          return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
        },
        set_color_rgb_down () {
          let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))
  
          return `rgba(${r}, ${g}, ${b}, 0.25)`
        }
      },
      mounted () {
        this.fetch()
      },
      methods: {
        voiceHover (item) {
          responsiveVoice.cancel(); // Cancel anything else that may currently be speaking
          responsiveVoice.speak(item, 'Indonesian Female');
        },
        async fetch() {
          this.process.load = true

          let params = {
            // limit: 10000000
          }

          await this.$axios.$get(`${process.env.API}sites/galery/data`, { params })
          .then(response => {
            let res = response
            
            if (res.status === 200) {
              this.list = res.results.data
              this.process.load = false
            }
          })
        },
        openVideo(data) {
          this.selected.video = {}
          this.selected.video = data
          this.dialog.video = true
        },
      }
    }
  </script>