<template>
  <div
    class="fill-height"
    style="padding-bottom: 400px; padding-top: 72px;">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <div class="font-weight-medium text-h5 mb-2 mt-2" :style="`color: ${set_color}`">
              Katalog Perda
            </div>
            <v-row>

              <v-col cols="12" md="9">
                <v-card flat
                  style="border-radius: 6px;"
                  class="mb-5 pa-5">
                  <!-- <div class="text-center mb-3">
                    Daftar Anggota JDIH dari Instansi/OPD
                  </div> -->
                  <table id="table-desa">
                    <tr>
                      <th :style="`background-color: ${set_color}`">No</th>
                      <th :style="`background-color: ${set_color}`">judul</th>
                      <th :style="`background-color: ${set_color}`">Tahun</th>
                      <th :style="`background-color: ${set_color}; text-align: center;`">Download</th>
                    </tr>
                    <tr v-for="(item, index) in 20" :key="index">
                      <td>{{ index+1 }}</td>
                      <td>Katalog Perda {{ index+1 }}</td>
                      <td>2023</td>
                      <td class="text-center">
                        <v-btn
                          style="border-radius: 6px;"
                          elevation="0"
                          :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                          color="green"
                          class="text-subtitle-2 text-capitalize font-weight-bold white--text">
                          
                          <v-icon size="20" class="mr-2">mdi-download</v-icon>
                          Download
                        </v-btn>
                      </td>
                    </tr>
                  </table>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card flat style="border-radius: 6px;">
                  <v-card-text>
                    <div class="font-weight-medium body-1" :style="`color: ${set_color}`">
                      Filter Data
                    </div>
                    <v-divider class="mb-4 mt-2"></v-divider>

                    <v-text-field
                      solo
                      hide-details
                      clearable
                      :color="`${set_color}`"
                      :placeholder="`Nama`">
                    </v-text-field>


                    <v-btn
                      style="border-radius: 6px;"
                      elevation="0"
                      block
                      height="48"
                      :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                      :color="`${set_color}`"
                      class="text-subtitle-2 text-capitalize font-weight-bold white--text mt-5">
                      
                      <v-icon size="20" class="mr-2">mdi-magnify</v-icon>
                      Cari
                    </v-btn>
                  </v-card-text>
                </v-card>

                <Sidebar />
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        kecamatan: [
          {
            name: 'Kecamatan A',
            id: 'A'
          },
          {
            name: 'Kecamatan B',
            id: 'B'
          },
          {
            name: 'Kecamatan C',
            id: 'C'
          }
        ],
        selected: {
          kecamatan: ''
        },
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/katalog-perda',
          title: 'Katalog Perda JDIH',
          description: 'Katalog Perda JDIH Setwan Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Sidebar: () => import(/* webpackPrefetch: true */ '@/components/sidebar/index')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
    },
    methods: {
    }
  }
</script>

<style>
  #table-desa {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #table-desa td, #table-desa th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #table-desa tr:nth-child(even){background-color: #f2f2f2;}

  #table-desa tr:hover {background-color: #ddd;}

  #table-desa th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    /* background-color: #04AA6D; */
    color: white;
}
</style>