<template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 400px; padding-top: 72px;` : `padding-bottom: 610px; padding-top: 64px;`"
    >
    <v-layout wrap>
      <v-flex>
        <section>
          <!-- <v-container>
            <v-row>
              <v-col  
                cols="12">
                <v-card
                  flat
                  class="background-default">
                  <v-card-title 
                    class="text-h4 white--text justify-center mb-2 pt-6"
                    style="font-weight: 500;">
                    VISI dan MISI
                  </v-card-title>

                  <v-card-subtitle 
                    class="body-1 white--text text-center mb-4" 
                    style="opacity: 50%; font-weight: 500;">
                    Mengenal VISI dan MISI Kabupaten Klaten.
                  </v-card-subtitle>
                  
                </v-card>
              </v-col>
            </v-row>

          </v-container> -->
          <v-container>
            <v-row>
              <v-col  
                cols="12" class="py-0">
                <div class="font-weight-medium mb-4 mt-2" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                  Tugas Pokok, Fungsi dan Program Kerja
                </div>
                <v-card
                  flat>
                  <v-card-text
                    class="body-1 black--text mb-4">

                    <ol>
                      <li>Menyusun rencana subkegiatan pendokumentasian produk hukum dan pengeluaan informasi hukum</li>
                      <li>Melaksanakan inventarisasi dan dokumentasi produk hukum daerah dan peraturan perundang-undangan lainnya</li>
                      <li>Melaksanakan pendokumentasian produk hukum dan pengelolaan informasi hukum</li>
                      <li>Menghimpun serta mengolah data dan informasi sebagai bahan dalam rangka pembentukan kebijakan Daerah</li>
                      <li>Melaksanakan pengelolaan jaringan dokumentasi dan informasi hukum</li>
                      <li>Memberikan pelayanan administrasi informasi produk hukum</li>
                      <li>Melaksanakan sosialisasi, penyuluhan dan desiminasi produk hukum daerah maupun peraturan perundang-undangan lainnya</li>
                      <li>Melaksanakan evaluasi dan pelaporan terhadap dokumentasi dan informasi produk hukum daerah dan</li>
                      <li>Melaksanakan tugas kedinasan lain yang diberikan oleh Pimpinan.</li>
                    </ol>
                    <!-- <div
                      class="mb-6 mt-4">
                      <div class="font-weight-medium title mb-3">
                        I. Dasar Hukum
                      </div>
                    </div>

                    <div
                      class="mb-6 mt-4">
                      <div class="font-weight-medium title mb-3">
                        II. Tugas
                      </div>
                      <ol>
                        <li>Menyimpan hasil kegiatan pembangunan bidang hukum.</li>
                        <li>Melakukan pengumpulan, pengelolaan, penyimpanan dan penyebarluasan bahan hukum.</li>
                        <li>Menyiapkan bahan hukum untuk mengambil keputusan.</li>
                        <li>Menyediakan fasilitas untuk mendalami dan memanfaatkan pengetahuan hukum melalui perpustakaan hukum.</li>
                        <li>Menyiapkan bahan perencanaan hukum dan perancangan peraturan perundang-undangan, penelitian hukum, profesi hukum dan penyuluhan hukum.</li>
                        <li>Memberikan fasilitas teknis dalam rangka penyelenggaraan dokumentasi dan informasi hukum pada anggota jaringan.</li>
                        <li>Menyelenggarakan dan membina komunikasi dengan anggota jaringan, dan</li>
                        <li>Melayani masyarakat dalam memperoleh informasi hukum secara mudah, cepat,tepat dan akurat.</li>
                      </ol>
                    </div>


                    <div
                      class="mb-6 mt-4">
                      <div class="font-weight-medium title mb-3">
                        III. Fungsi
                      </div>
                      <ol>
                        <li>Pusat informasi hukum.</li>
                        <li>Pusat pengumpulan, penyimpanan, pengolahan, penyebarluasan dan pengelolaan dokumentasi hukum secara manual dan digital.</li>
                        <li>Pembinaan dan pendidikan pengelola JDIH.</li>
                        <li>Koordinasi dan konsultasi anggota jaringan.</li>
                      </ol>
                    </div>

                    <div
                      class="mb-6 mt-4">
                      <div class="font-weight-medium title mb-3">
                        IV. Program Kerja
                      </div>
                    </div> -->
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/tugas-pokok-fungsi-dan-program-kerja',
          title: 'Tugas Pokok, Fungsi dan Program Kerja',
          description: 'Tugas Pokok, Fungsi dan Program Kerja JDIH Setwan Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>