<template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 400px; padding-top: 72px;` : `padding-bottom: 610px; padding-top: 64px;`"
    >
    <v-layout wrap>
      <v-flex>
        <section>
          <!-- <v-container>
            <v-row>
              <v-col  
                cols="12">
                <v-card
                  flat
                  class="background-default">
                  <v-card-title 
                    class="text-h4 white--text justify-center mb-2 pt-6"
                    style="font-weight: 500;">
                    VISI dan MISI
                  </v-card-title>

                  <v-card-subtitle 
                    class="body-1 white--text text-center mb-4" 
                    style="opacity: 50%; font-weight: 500;">
                    Mengenal VISI dan MISI Kabupaten Klaten.
                  </v-card-subtitle>
                  
                </v-card>
              </v-col>
            </v-row>

          </v-container> -->
          <v-container>
            <v-row>
              <v-col  
                cols="12" class="py-0">
                <div class="font-weight-medium mb-4 mt-2" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                  Visi dan Misi
                </div>
                <v-card
                  flat>
                  <v-card-text
                    class="body-1 black--text mb-4">
                    <div class="font-weight-medium" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                      <span>VISI</span> 
                    </div>
  
                    <div
                      class="mb-6 mt-4">
                      <div class="font-weight-medium" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'title' : 'body-2'">
                        TERWUJUDNYA KABUPATEN KLATEN YANG MAJU, MANDIRI DAN SEJAHTERA
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
                <v-card
                  flat>
                  <v-card-text
                    class="body-1 black--text mb-4">
                    <div class="font-weight-medium" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                      <span>MISI</span> 
                    </div>
  
                    <div
                      class="mb-6 mt-4">
                      <div class="font-weight-medium" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'title' : 'body-2'">
                        <ol>
                          <li>Mewujudkan tatanan Kehidupan Masyarakat yang Berakhlak dan Berkepribadian</li>
                          <li>Mewujudkan Tata Kelola Pemerintahan yang baik, profesional, jujur, bersih, transparan, bertanggungjawab, dan anti korupsi</li>
                          <li>Meningkatkan kemandirian ekonomi daerah berbasis sektor unggulan daerah berdasarkan ekonomi kerakyatan</li>
                          <li>Mewujudkan pemerataan sarana prasarana wilayah yang berkualitas sesuai rencana tata ruang wilayah</li>
                          <li>Mewujudkan kualitas sumber daya manusia yang cerdas, sehat, berbudaya, dan responsif gender</li>
                          <li>Mewujudkan kualitas pengelolaan lingkungan hidup yang berkelanjutan.</li>
                        </ol>
                      </div>
                    </div>
  
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/profil?menu=visi-misi',
          title: 'Visi & Misi',
          description: 'Visi & Misi Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>